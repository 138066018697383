import React, { useEffect, useContext, useState } from "react";
import { useHistory } from "react-router-dom";

//jQuery initialization
import $ from "jquery";

//functions
import {
  _t,
  getCookie,
  managePageHrefLink,
  checkPermission,
  managePageHrefLinkMobile,
} from "../../../../functions/Functions";

import { useTranslation } from "react-i18next";
import { SettingsContext } from "../../../../contexts/Settings";
import { UserContext } from "../../../../contexts/User";


const ManageSidebar = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { showManageStock, flag, setFlag } = useContext(SettingsContext);
  let { authUserInfo } = useContext(UserContext);


  useEffect(() => {
    handleJquery();
    handleJquerySub();
  }, []);


  //jQuery goes here
  const handleJquery = () => {
    var posHasSub = $(".fk-pos-nav__list-has-sub");
    var posSub = $(".fk-pos-nav__sub");
    $(".fk-pos-nav__list-has-sub > a").on("click", function (e) {
      e.preventDefault();
    });
    posHasSub.on("click", function () {
      $(this).find(posSub).slideDown();
      $(this).siblings().find(posSub).slideUp();
      $(this).addClass("active").siblings().removeClass("active");
    });


    //nested dropdown
    var posSubTree = $(".fk-pos-nav__sub__tree")    
    if(flag===false){
      //initially child dropdown closed
      posSubTree.slideUp();
    }

  };

  //nested dropdown
  const handleJquerySub = () => {
    var posHasSubTree = $(".fk-pos-nav__list-has-sub__tree");
    var posSubTree = $(".fk-pos-nav__sub__tree");
    setFlag(true);

    $(".fk-pos-nav__list-has-sub__tree > a").on("click", function (e) {
      e.preventDefault();
      var parent = $(this).parent('.fk-pos-nav__list-has-sub__tree');
      if (parent.hasClass('active')) {
        parent.find(posSubTree).slideUp();
        parent.removeClass('active');
      } else {
        posSubTree.slideUp();
        posHasSubTree.removeClass('active');
        parent.find(posSubTree).slideDown();
        parent.addClass("active");
      }
    });
    if(posHasSubTree.hasClass('active')){
      posHasSubTree.siblings(':not(.active)').find(posSubTree).slideUp();
    }

    if(!posHasSubTree.hasClass('active') && flag===true){
      posHasSubTree.find(posSubTree).slideUp();
    }
  };

  let url = window.location.pathname;

  return (
    <>
      {/* Navigation for Small Screen  */}
      <div className="d-lg-none">
        <div className="row">
          <div className="col-12">
            <div className="fk-sm-nav" data-simplebar>
              <ul className="t-list fk-sm-nav__bar flex-row">
                {/* Foods */}
                {authUserInfo.permissions !== null &&
                  checkPermission(
                    authUserInfo.permissions,
                    "Manage Food"
                  ) ? (
                    <>
                {managePageHrefLinkMobile(
                  "/dashboard/manage/food/add-menu",
                  _t(t("Menu"))
                )}

                {managePageHrefLinkMobile(
                  "/dashboard/manage/food/add-new",
                  _t(t("Add new item"))
                )}

                {managePageHrefLinkMobile(
                  "/dashboard/manage/food/all-items",
                  _t(t("All Items"))
                )}

                {managePageHrefLinkMobile(
                  "/dashboard/manage/food/groups",
                  _t(t("Groups"))
                )}

                {managePageHrefLinkMobile(
                  "/dashboard/manage/food/properties",
                  _t(t("Properties"))
                )}
                {managePageHrefLinkMobile(
                  "/dashboard/manage/food/properties-item",
                  _t(t("Properties Item"))
                )}

                {managePageHrefLinkMobile(
                  "/dashboard/manage/food/variations",
                  _t(t("Variations"))
                )}

                {managePageHrefLinkMobile(
                  "/dashboard/manage/food/add-cards",
                  _t(t("Card Settings"))
                )}

                {managePageHrefLinkMobile(
                  "/dashboard/manage/food/add-schemes",
                  _t(t("Offers"))
                )}
                </>
                ):""}

                {/* Stock */}
                {
                  showManageStock && (
                    <>
                    
                    {authUserInfo.permissions !== null &&
                      checkPermission(
                        authUserInfo.permissions,
                        "MS Food Purchase"
                      ) ? (
                        managePageHrefLinkMobile(
                          "/dashboard/manage/stock/food-purchase",
                          _t(t("Food Purchase"))
                        )
                       ) :""}
    
    
                    {authUserInfo.permissions !== null &&
                      checkPermission(
                        authUserInfo.permissions,
                        "MS Purchase History"
                      ) ? (
                        managePageHrefLinkMobile(
                          "/dashboard/manage/stock/purchase-history-food",
                          _t(t("Purchase History"))
                        )
                      ) : ""}
                      
                      {authUserInfo.permissions !== null &&
                        checkPermission(
                          authUserInfo.permissions,
                          "MS Finished Goods"
                        ) ? (
                          managePageHrefLinkMobile(
                          "/dashboard/manage/stock/finished-goods-history",
                          _t(t("Finished Goods")) 
                        )
                        ) : ""}
    
                        {authUserInfo.permissions !== null &&
                          checkPermission(
                            authUserInfo.permissions,
                            "MS Finished Goods Transfer"
                          ) ? (  
                            managePageHrefLinkMobile(
                            "/dashboard/manage/stock/finished-goods-transfer",
                            _t(t("Finished Goods Transfer"))
                          )
                          ) :""}
    
                        {authUserInfo.permissions !== null &&
                            checkPermission(
                              authUserInfo.permissions,
                              "MS Goods Acceptance"
                          ) ? (    
                            managePageHrefLinkMobile(
                              "/dashboard/manage/stock/finished-goods-acceptance",
                              _t(t("Goods Acceptance"))
                            )
                          ):""}
    
                          {authUserInfo.permissions !== null &&
                            checkPermission(
                              authUserInfo.permissions,
                              "MS Finished Goods Discard"
                            ) ? (
                              managePageHrefLinkMobile(
                              "/dashboard/manage/stock/finished-goods-discard",
                              _t(t("Finished Goods Discard"))
                            )
                            ):""}
    
                          {authUserInfo.permissions !== null &&
                            checkPermission(
                              authUserInfo.permissions,
                              "MS Goods Adjustment"
                            ) ? ( 
                              managePageHrefLinkMobile(
                              "/dashboard/manage/stock/finished-goods-adjustment",
                              _t(t("Goods Adjustment"))
                            )     
                            ):""}     
                                                    
                        {authUserInfo.permissions !== null &&
                          checkPermission(
                            authUserInfo.permissions,
                            "MS Goods Adjustment"
                          ) ? (
                            managePageHrefLinkMobile(
                            "/dashboard/manage/stock/fg-packing",
                            _t(t("FG Packing"))
                          )
                        ) : ""}

                        {authUserInfo.permissions !== null &&
                          checkPermission(
                            authUserInfo.permissions,
                            "MS Goods Adjustment"
                          ) ? (
                            managePageHrefLinkMobile(
                            "/dashboard/manage/stock/fg-packing",
                            _t(t("Gift Hampers"))
                          )
                        ) : ""}
    
                        {authUserInfo.permissions !== null &&
                          checkPermission(
                            authUserInfo.permissions,
                            "MS Ingredient Group"
                          ) ? (
                            managePageHrefLinkMobile(
                              "/dashboard/manage/stock/ingredient-group",
                              _t(t("Ingredient Group"))
                            )
                          ):""}
    
                      {authUserInfo.permissions !== null &&
                        checkPermission(
                          authUserInfo.permissions,
                          "MS Ingredient Unit"
                        ) ? (
                          managePageHrefLinkMobile(
                          "/dashboard/manage/stock/ingredient-unit",
                          _t(t("Ingredient Unit"))
                        )
                        ):""}
                      
                      {authUserInfo.permissions !== null &&
                        checkPermission(
                          authUserInfo.permissions,
                          "MS Ingredient Item"
                        ) ? (
                          managePageHrefLinkMobile(
                            "/dashboard/manage/stock/ingredient-item",
                            _t(t("Ingredient Item"))
                          )
                        ):""}

                        {authUserInfo.permissions !== null &&
                        checkPermission(
                          authUserInfo.permissions,
                          "MS Ingredient Item"
                        ) ? (
                          managePageHrefLinkMobile(
                            "/dashboard/manage/stock/ingredient-limit",
                            _t(t("Ingredient Limit"))
                          )
                        ):""}
    
                      {authUserInfo.permissions !== null &&
                        checkPermission(
                          authUserInfo.permissions,
                          "MS Manage Supplier"
                        ) ? (   
                          managePageHrefLinkMobile(
                            "/dashboard/manage/stock/manage-supplier",
                            _t(t("Manage Supplier"))
                          )
                        ):""}
    
                      {authUserInfo.permissions !== null &&
                        checkPermission(
                          authUserInfo.permissions,
                          "MS Recipe"
                        ) ? (
                          managePageHrefLinkMobile(
                          "/dashboard/manage/stock/view-recipe",
                          _t(t("Recipe"))
                        )                  
                        ):""}

                      {authUserInfo.permissions !== null &&
                        checkPermission(
                          authUserInfo.permissions,
                          "MS Goods Adjustment"
                        ) ? (
                        managePageHrefLinkMobile(
                          "/dashboard/manage/stock/wastage-food",
                          _t(t("Wastage Food"))
                        )
                      ) : ""}
    
                      {authUserInfo.permissions !== null &&
                        checkPermission(
                          authUserInfo.permissions,
                          "MS Production"
                        ) ? (
                          managePageHrefLinkMobile(
                          "/dashboard/manage/stock/production",
                          _t(t("Production"))
                        )                  
                        ):""}
        
                      {authUserInfo.permissions !== null &&
                        checkPermission(
                          authUserInfo.permissions,
                          "MS Purchase Order"
                        ) ? (
                          managePageHrefLinkMobile(
                          "/dashboard/manage/stock/purchase-order",
                          _t(t("Purchase Order"))
                          )
                        ):""}
    
                      {authUserInfo.permissions !== null &&
                        checkPermission(
                          authUserInfo.permissions,
                          "MS Goods Recieved Note"
                        ) ? (
                          managePageHrefLinkMobile(
                          "/dashboard/manage/stock/purchase-goods",
                          _t(t("Goods Recieved Note"))
                          )
                        ):""}
                      {authUserInfo.permissions !== null &&
                        checkPermission(
                          authUserInfo.permissions,
                          "MS Purchase Invoice"
                        ) ? (
                          managePageHrefLinkMobile(
                          "/dashboard/manage/stock/purchase-invoice",
                          _t(t("Purchase Invoice"))
                        )
                        ):""}
                    {authUserInfo.permissions !== null &&
                      checkPermission(
                        authUserInfo.permissions,
                        "MS Store Requisition"
                      ) ? (
                      managePageHrefLinkMobile(
                        "/dashboard/manage/stock/store-requisition",
                        _t(t("Store Requisition"))
                      )
                      ):""}
                    {authUserInfo.permissions !== null &&
                      checkPermission(
                        authUserInfo.permissions,
                        "MS Stock Issuance"
                      ) ? (
                        managePageHrefLinkMobile(
                        "/dashboard/manage/stock/stock-issuance",
                        _t(t("Stock Issuance"))
                        )
                      ):""}
                      
                    {authUserInfo.permissions !== null &&
                      checkPermission(
                        authUserInfo.permissions,
                        "MS Stock Transfer"
                      ) ? (
                        managePageHrefLinkMobile(
                        "/dashboard/manage/stock/stock-transfer",
                        _t(t("Stock Transfer"))
                        )
                      ):""}
    
                      {authUserInfo.permissions !== null &&
                        checkPermission(
                          authUserInfo.permissions,
                          "MS Stock Adjustment"
                        ) ? (
                          managePageHrefLinkMobile(
                          "/dashboard/manage/stock/stock-adjustment",
                          _t(t("Stock Adjustment"))
                          )
                        ):""}
  
                      {authUserInfo.permissions !== null &&
                        checkPermission(
                          authUserInfo.permissions,
                          "MS Opening Stock"
                        ) ? (
                          managePageHrefLinkMobile(
                          "/dashboard/manage/stock/opening-stock",
                          _t(t("Opening Stock"))
                          )
                        ):""}
    
                      {authUserInfo.permissions !== null &&
                        checkPermission(
                          authUserInfo.permissions,
                          "MS Day End Setting"
                        ) ? (
                          managePageHrefLinkMobile(
                          "/dashboard/manage/stock/day-end-setting",
                          _t(t("Day End Setting"))
                          )
                        ):""}

                      {authUserInfo.permissions !== null &&
                        checkPermission(
                          authUserInfo.permissions,
                          "MS Supplier Payment"
                        ) ? (
                          managePageHrefLinkMobile(
                          "/dashboard/manage/stock/supplier-payment",
                          _t(t("Supplier Payment"))
                          )
                      ):""}  
                    </>
                  )
                }

                {/* User Management */}
                {authUserInfo.permissions !== null &&
                  checkPermission(
                    authUserInfo.permissions,
                    "Manage User"
                  ) ? (
                  <>
                    {managePageHrefLinkMobile(
                      "/dashboard/manage/user/admin-staff",
                      _t(t("Admin / Staff"))
                    )}

                    {managePageHrefLinkMobile(
                      "/dashboard/manage/user/till",
                      _t(t("User Till"))
                    )}

                    {managePageHrefLinkMobile(
                      "/dashboard/manage/user/customers",
                      _t(t("Customers"))
                    )}

                    {managePageHrefLinkMobile(
                      "/dashboard/manage/user/delivery-men",
                      _t(t("Delivery"))
                    )}

                    {managePageHrefLinkMobile(
                      "/dashboard/manage/user/waiters",
                      _t(t("Waiters"))
                    )}

                    {managePageHrefLinkMobile(
                      "/dashboard/manage/roles-and-permissions",
                      _t(t("Role Groups"))
                    )}
                  </>
                ):""}

                {/* Restaurant */}
                {authUserInfo.permissions !== null &&
                  checkPermission(
                    authUserInfo.permissions,
                    "Manage Restauant"
                  ) ? (
                    <>
                {managePageHrefLinkMobile(
                  "/dashboard/manage/restaurant/branches",
                  _t(t("Branches"))
                )}

                {managePageHrefLinkMobile(
                  "/dashboard/manage/restaurant/md-integration",
                  _t(t("Md Integration"))
                )}

                {managePageHrefLinkMobile(
                  "/dashboard/manage/restaurant/md-jv",
                  _t(t("Md Jv"))
                )}

                {managePageHrefLinkMobile(
                  "/dashboard/manage/restaurant/zones",
                  _t(t("Zones"))
                )}

                {managePageHrefLinkMobile(
                  "/dashboard/manage/restaurant/order-types",
                  _t(t("Order Types"))
                )}

                {/* {managePageHrefLinkMobile(
                  "/dashboard/manage/restaurant/dept-tags",
                  _t(t("Dept Tags"))
                )} */}

                {managePageHrefLinkMobile(
                  "/dashboard/manage/restaurant/tables",
                  _t(t("Tables"))
                )}

                {managePageHrefLinkMobile(
                  "/dashboard/manage/restaurant/payment-type",
                  _t(t("Payment Types"))
                )}

                {managePageHrefLinkMobile(
                  "/dashboard/manage/restaurant/manage-party",
                  _t(t("Party"))
                )}

                {managePageHrefLinkMobile(
                  "/dashboard/manage/restaurant/manage-expense",
                  _t(t("Expense"))
                )}

                {managePageHrefLinkMobile(
                  "/dashboard/manage/restaurant/party-history",
                  _t(t("Party History"))
                )}
                {managePageHrefLinkMobile(
                  "/dashboard/manage/restaurant/manage-kot",
                  _t(t("Manage KOT"))
                )}

                {managePageHrefLinkMobile(
                  "/dashboard/manage/restaurant/settlements",
                  _t(t("Settlements"))
                )}

                {/* website */}
                {managePageHrefLinkMobile(
                  "/dashboard/manage/website/hero-section",
                  _t(t("Hero Section"))
                )}

                {managePageHrefLinkMobile(
                  "/dashboard/manage/website/promotions",
                  _t(t("Promotions"))
                )}
                </>
                ):""}

                {/* Settings */}
                {authUserInfo.permissions !== null &&
                  checkPermission(
                    authUserInfo.permissions,
                    "Manage Settings"
                  ) ? (
                    <>
                {managePageHrefLinkMobile(
                  "/dashboard/manage/settings/currencies",
                  _t(t("Currencies"))
                )}

                {managePageHrefLinkMobile(
                  "/dashboard/manage/settings/languages",
                  _t(t("Languages"))
                )}

                {/*managePageHrefLinkMobile(
                  "/dashboard/manage/settings/smtp-settings",
                  _t(t("Email / Smtp"))
                )*/}

                {managePageHrefLinkMobile(
                  "/dashboard/manage/settings/email-settings",
                  _t(t("Email Template"))
                )}

                {/*managePageHrefLinkMobile(
                  "/dashboard/manage/settings/pos-screen",
                  _t(t("Pos Screen"))
                )*/}

                {/*managePageHrefLinkMobile(
                  "/dashboard/manage/settings/update-system",
                  _t(t("Update System"))
                )*/}

                {managePageHrefLinkMobile(
                  "/dashboard/manage/settings/general-settings",
                  _t(t("General Settings"))
                )}

                {managePageHrefLinkMobile(
                  "/dashboard/manage/settings/web-mapping",
                  _t(t("Web Mapping"))
                )}
                </>
                ):""}
              </ul>
            </div>
          </div>
        </div>
      </div>
      {/* Navigation for Small Screen  End*/}

      {/* Navigation for large Screen  */}
      <div className="d-none d-lg-block">
        <div className="fk-scroll--pos-menu" data-simplebar>
          <ul className="t-list fk-pos-nav">
            {/* Foods */}
            {authUserInfo.permissions !== null &&
              checkPermission(
                authUserInfo.permissions,
                "Manage Food"
              ) ? (
              <li
                className={`fk-pos-nav__list fk-pos-nav__list-has-sub ${
                  window.location.pathname.includes("/food/") ? "active" : ""
                }`}
              >
                <a
                  className="w-100 t-text-dark t-heading-font btn btn-outline-danger font-weight-bold text-uppercase rounded-0 text-left"
                  rel="noopener noreferrer"
                  href="#"
                >
                  {_t(t("Foods"))}
                </a>
                <ul className="t-list fk-pos-nav__sub t-bg-white list-group">
                  {managePageHrefLink(
                    "/dashboard/manage/food/add-menu",
                    _t(t("Menu"))
                  )}

                  {managePageHrefLink(
                    "/dashboard/manage/food/add-new",
                    _t(t("Add new item"))
                  )}

                  {managePageHrefLink(
                    "/dashboard/manage/food/all-items",
                    _t(t("All Items"))
                  )}

                  {managePageHrefLink(
                    "/dashboard/manage/food/groups",
                    _t(t("Groups"))
                  )}

                  {/* {managePageHrefLink(
                    "/dashboard/manage/food/units",
                    _t(t("Units"))
                  )} */}

                  {managePageHrefLink(
                    "/dashboard/manage/food/properties",
                    _t(t("Properties Group"))
                  )}
                  {managePageHrefLink(
                    "/dashboard/manage/food/properties-item",
                    _t(t("Properties Item"))
                  )}

                  {managePageHrefLink(
                    "/dashboard/manage/food/variations",
                    _t(t("Variations"))
                  )}
                  {managePageHrefLink(
                    "/dashboard/manage/food/add-cards",
                    _t(t("Card Settings"))
                  )}
                  {managePageHrefLink(
                    "/dashboard/manage/food/add-schemes",
                    _t(t("Offers"))
                  )}
                </ul>
              </li>
              )
            : ""}

            {/* stock Management */}
            {showManageStock ? (
              <li
                className={`fk-pos-nav__list fk-pos-nav__list-has-sub ${
                  window.location.pathname.includes("stock") ? "active" : ""
                }`}
              >
                <a
                  className="w-100 t-text-dark t-heading-font btn btn-outline-danger font-weight-bold text-uppercase rounded-0 text-left"
                  rel="noopener noreferrer"
                  href=""
                >
                  {_t(t("Manage Stock"))}
                </a>
                <ul className="t-list fk-pos-nav__sub t-bg-white list-group">
              
                  {/* {authUserInfo.permissions !== null &&
                    checkPermission(
                      authUserInfo.permissions,
                      "MS Food Purchase"
                    ) ? (
                    managePageHrefLink(
                      "/dashboard/manage/stock/food-purchase",
                      _t(t("Food Purchase"))
                    )
                  ) : ""} */}


                  {/* {authUserInfo.permissions !== null &&
                    checkPermission(
                      authUserInfo.permissions,
                      "MS Purchase History"
                    ) ? (
                    managePageHrefLink(
                      "/dashboard/manage/stock/purchase-history-food",
                      _t(t("Purchase History"))
                    )
                  ) : ""} */}

                  {/* finished goods */}
                  <li
                    className={`fk-pos-nav__list fk-pos-nav__list-has-sub__tree ${(url.includes("goods") || url.includes("packing") || url.includes("gift-hampers")) ? "active" : ""
                      }`}
                      style={{marginBottom:"0px"}}
                  >
                    <a
                      className="w-100 d-flex justify-content-between align-items-center t-text-dark btn border-bottom font-weight-bold text-capitalize rounded-0 text-left"
                      rel="noopener noreferrer"
                      href=""
                      style={{fontSize:"14px"}}
                    >
                      <span>
                      - {_t(t("Finished Goods"))} 
                      </span>
                      <i className="fa fa-caret-down fa-lg" aria-hidden="true"></i>
                    </a>
                    <ul className="t-list fk-pos-nav__sub__tree t-bg-white list-group">

                      {authUserInfo.permissions !== null &&
                        checkPermission(
                          authUserInfo.permissions,
                          "MS Finished Goods"
                        ) ? (
                        managePageHrefLink(
                          "/dashboard/manage/stock/finished-goods-history",
                          _t(t("Finished Goods"))
                        )
                      ) : ""}

                      {authUserInfo.permissions !== null &&
                        checkPermission(
                          authUserInfo.permissions,
                          "MS Finished Goods Transfer"
                        ) ? (
                        managePageHrefLink(
                          "/dashboard/manage/stock/finished-goods-transfer",
                          _t(t("Finished Goods Transfer"))
                        )
                      ) : ""}

                      {authUserInfo.permissions !== null &&
                        checkPermission(
                          authUserInfo.permissions,
                          "MS Goods Acceptance"
                        ) ? (
                        managePageHrefLink(
                          "/dashboard/manage/stock/finished-goods-acceptance",
                          _t(t("Goods Acceptance"))
                        )
                      ) : ""}

                      {authUserInfo.permissions !== null &&
                        checkPermission(
                          authUserInfo.permissions,
                          "MS Finished Goods Discard"
                        ) ? (
                        managePageHrefLink(
                          "/dashboard/manage/stock/finished-goods-discard",
                          _t(t("Finished Goods Discard"))
                        )
                      ) : ""}

                      {authUserInfo.permissions !== null &&
                        checkPermission(
                          authUserInfo.permissions,
                          "MS Goods Adjustment"
                        ) ? (
                        managePageHrefLink(
                          "/dashboard/manage/stock/finished-goods-adjustment",
                          _t(t("Goods Adjustment"))
                        )
                      ) : ""}  
                      
                      {authUserInfo.permissions !== null &&
                        checkPermission(
                          authUserInfo.permissions,
                          "MS Goods Adjustment"
                        ) ? (
                        managePageHrefLink(
                          "/dashboard/manage/stock/fg-packing",
                          _t(t("FG Packing"))
                        )
                      ) : ""}

                      {authUserInfo.permissions !== null &&
                        checkPermission(
                          authUserInfo.permissions,
                          "MS Goods Adjustment"
                        ) ? (
                        managePageHrefLink(
                          "/dashboard/manage/stock/gift-hampers",
                          _t(t("Gift Hampers"))
                        )
                      ) : ""}

                    </ul>
                  </li>

                  {/* Recipe  */}

                  <li
                    className={`fk-pos-nav__list fk-pos-nav__list-has-sub__tree ${url.includes("recipe") || url.includes("ingredient")
                      || url.includes("supplier") || url.includes("production") ||
                      url.includes("invoice") || url.includes("stock-adjustment") ||
                      url.includes("stock-transfer") || url.includes("requisition") || url.includes("issuance")
                      || url.includes("opening") || url.includes("wastage")
                      ? "active" : ""
                      }`}
                    style={{ marginBottom: "0px" }}
                  >
                    <a
                      className="w-100 d-flex justify-content-between align-items-center t-text-dark btn border-bottom font-weight-bold text-capitalize rounded-0 text-left"
                      rel="noopener noreferrer"
                      href=""
                      style={{ fontSize: "14px" }}
                    >
                      <span>
                        - {_t(t("Recipe"))}
                      </span>
                      <i className="fa fa-caret-down fa-lg" aria-hidden="true"></i>
                    </a>
                    <ul className="t-list fk-pos-nav__sub__tree t-bg-white list-group">

                      {authUserInfo.permissions !== null &&
                        checkPermission(
                          authUserInfo.permissions,
                          "MS Recipe"
                        ) ? (
                        managePageHrefLink(
                          "/dashboard/manage/stock/view-recipe",
                          _t(t("Recipe"))
                        )
                      ) : ""}

                      {authUserInfo.permissions !== null &&
                        checkPermission(
                          authUserInfo.permissions,
                          "MS Goods Adjustment"
                        ) ? (
                        managePageHrefLink(
                          "/dashboard/manage/stock/wastage-food",
                          _t(t("Wastage Food"))
                        )
                      ) : ""}

                      {authUserInfo.permissions !== null &&
                        checkPermission(
                          authUserInfo.permissions,
                          "MS Ingredient Group"
                        ) ? (
                        managePageHrefLink(
                          "/dashboard/manage/stock/ingredient-group",
                          _t(t("Ingredient Group"))
                        )
                      ) : ""}

                      {authUserInfo.permissions !== null &&
                        checkPermission(
                          authUserInfo.permissions,
                          "MS Ingredient Unit"
                        ) ? (
                        managePageHrefLink(
                          "/dashboard/manage/stock/ingredient-unit",
                          _t(t("Ingredient Unit"))
                        )
                      ) : ""}

                      {authUserInfo.permissions !== null &&
                        checkPermission(
                          authUserInfo.permissions,
                          "MS Ingredient Item"
                        ) ? (
                        managePageHrefLink(
                          "/dashboard/manage/stock/ingredient-item",
                          _t(t("Ingredient Item"))
                        )
                      ) : ""}

                        {authUserInfo.permissions !== null &&
                        checkPermission(
                          authUserInfo.permissions,
                          "MS Ingredient Item"
                        ) ? (
                        managePageHrefLink(
                          "/dashboard/manage/stock/ingredient-limit",
                          _t(t("Ingredient limit"))
                        )
                      ) : ""}

                      {authUserInfo.permissions !== null &&
                        checkPermission(
                          authUserInfo.permissions,
                          "MS Manage Supplier"
                        ) ? (
                        managePageHrefLink(
                          "/dashboard/manage/stock/manage-supplier",
                          _t(t("Manage Supplier"))
                        )
                      ) : ""}

                      {authUserInfo.permissions !== null &&
                        checkPermission(
                          authUserInfo.permissions,
                          "MS Production"
                        ) ? (
                        managePageHrefLink(
                          "/dashboard/manage/stock/production",
                          _t(t("Production"))
                        )
                      ) : ""}

                      {authUserInfo.permissions !== null &&
                        checkPermission(
                          authUserInfo.permissions,
                          "MS Purchase Order"
                        ) ? (
                        managePageHrefLink(
                          "/dashboard/manage/stock/purchase-order",
                          _t(t("Purchase Order"))
                        )
                      ) : ""}

                      {authUserInfo.permissions !== null &&
                        checkPermission(
                          authUserInfo.permissions,
                          "MS Goods Recieved Note"
                        ) ? (
                        managePageHrefLink(
                          "/dashboard/manage/stock/purchase-goods",
                          _t(t("Goods Recieved Note"))
                        )
                      ) : ""}
                      {authUserInfo.permissions !== null &&
                        checkPermission(
                          authUserInfo.permissions,
                          "MS Purchase Invoice"
                        ) ? (
                        managePageHrefLink(
                          "/dashboard/manage/stock/purchase-invoice",
                          _t(t("Purchase Invoice"))
                        )
                      ) : ""}
                      {authUserInfo.permissions !== null &&
                        checkPermission(
                          authUserInfo.permissions,
                          "MS Store Requisition"
                        ) ? (
                        managePageHrefLink(
                          "/dashboard/manage/stock/store-requisition",
                          _t(t("Store Requisition"))
                        )
                      ) : ""}
                      {authUserInfo.permissions !== null &&
                        checkPermission(
                          authUserInfo.permissions,
                          "MS Stock Issuance"
                        ) ? (
                        managePageHrefLink(
                          "/dashboard/manage/stock/stock-issuance",
                          _t(t("Stock Issuance"))
                        )
                      ) : ""}

                      {authUserInfo.permissions !== null &&
                        checkPermission(
                          authUserInfo.permissions,
                          "MS Stock Transfer"
                        ) ? (
                        managePageHrefLink(
                          "/dashboard/manage/stock/stock-transfer",
                          _t(t("Stock Transfer"))
                        )
                      ) : ""}

                      {authUserInfo.permissions !== null &&
                        checkPermission(
                          authUserInfo.permissions,
                          "MS Stock Adjustment"
                        ) ? (
                        managePageHrefLink(
                          "/dashboard/manage/stock/stock-adjustment",
                          _t(t("Stock Adjustment"))
                        )
                      ) : ""}

                      {authUserInfo.permissions !== null &&
                        checkPermission(
                          authUserInfo.permissions,
                          "MS Opening Stock"
                        ) ? (
                        managePageHrefLink(
                          "/dashboard/manage/stock/opening-stock",
                          _t(t("Opening Stock"))
                        )
                      ) : ""}

                    </ul>
                  </li>

                  {authUserInfo.permissions !== null &&
                    checkPermission(
                      authUserInfo.permissions,
                      "MS Day End Setting"
                    ) ? (
                    managePageHrefLink(
                      "/dashboard/manage/stock/day-end-setting",
                      _t(t("Day End Setting"))
                    )
                  ) : ""}

                  {authUserInfo.permissions !== null &&
                    checkPermission(
                      authUserInfo.permissions,
                      "MS Barcode Print"
                    ) ? (
                    managePageHrefLink(
                      "/dashboard/manage/stock/barcode-print-add",
                      _t(t("Barcode Print"))
                    )
                  ) : ""}

                  {authUserInfo.permissions !== null &&
                    checkPermission(
                      authUserInfo.permissions,
                      "MS Supplier Payment"
                    ) ? (
                    managePageHrefLink(
                      "/dashboard/manage/stock/supplier-payment",
                      _t(t("Supplier Payment"))
                    )
                    ):""}
                </ul>
              </li>
            ) : (
              ""
            )}

            
            {/* User Management */}
            {authUserInfo.permissions !== null &&
              checkPermission(
                authUserInfo.permissions,
                "Manage User"
              ) ? (
              <li
                className={`fk-pos-nav__list fk-pos-nav__list-has-sub ${
                  window.location.pathname.includes("/roles") ||
                  window.location.pathname.includes("/user/") ||
                  window.location.pathname.includes("/till/")
                    ? "active"
                    : ""
                }`}
              >
                <a
                  className="w-100 t-text-dark t-heading-font btn btn-outline-danger font-weight-bold text-uppercase rounded-0 text-left"
                  rel="noopener noreferrer"
                  href="#"
                >
                  {_t(t("User Management"))}
                </a>
                <ul className="t-list fk-pos-nav__sub t-bg-white list-group">
                  {managePageHrefLink(
                    "/dashboard/manage/user/admin-staff",
                    _t(t("Admin / Staff"))
                  )}

                  {managePageHrefLink(
                    "/dashboard/manage/user/till",
                    _t(t("User Till"))
                  )}

                  {managePageHrefLink(
                    "/dashboard/manage/user/customers",
                    _t(t("Customers"))
                  )}

                  {managePageHrefLink(
                    "/dashboard/manage/user/waiters",
                    _t(t("Waiters"))
                  )}

                  {managePageHrefLink(
                    "/dashboard/manage/roles-and-permissions",
                    _t(t("Role Groups"))
                  )}

                  {managePageHrefLink(
                    "/dashboard/manage/user/delivery-men",
                    _t(t("Delivery User"))
                  )}

                  {managePageHrefLink(
                    "/dashboard/manage/user/delivery-request",
                    _t(t("New Delivery User"))
                  )}
                </ul>
              </li>
              ):""}

            {/* Restaurant */}
            {authUserInfo.permissions !== null &&
              checkPermission(
                authUserInfo.permissions,
                "Manage Restaurant"
              ) ? (
              <li
                className={`fk-pos-nav__list fk-pos-nav__list-has-sub 
                ${
                  window.location.pathname.includes("/restaurant") ? "active" : ""
                }`}
              >
                <a
                  className="w-100 t-text-dark t-heading-font btn btn-outline-danger font-weight-bold text-uppercase rounded-0 text-left"
                  rel="noopener noreferrer"
                  href="#"
                >
                  {_t(t("Restaurant"))}
                </a>
                <ul className="t-list fk-pos-nav__sub t-bg-white list-group">
                  {managePageHrefLink(
                    "/dashboard/manage/restaurant/branches",
                    _t(t("Branches"))
                  )}

                  {managePageHrefLink(
                    "/dashboard/manage/restaurant/md-integration",
                    _t(t("MD Integration"))
                  )}

                  {managePageHrefLink(
                    "/dashboard/manage/restaurant/md-jv",
                    _t(t("MD Jv"))
                  )}

                  {managePageHrefLink(
                    "/dashboard/manage/restaurant/zones",
                    _t(t("Zones"))
                  )}

                  {managePageHrefLink(
                    "/dashboard/manage/restaurant/order-types",
                    _t(t("Order Types"))
                  )}

                  {managePageHrefLink(
                    "/dashboard/manage/restaurant/table-floor",
                    _t(t("Table Floor"))
                  )} 

                  {managePageHrefLink(
                    "/dashboard/manage/restaurant/tables",
                    _t(t("Tables"))
                  )}

                  {managePageHrefLink(
                    "/dashboard/manage/restaurant/payment-type",
                    _t(t("Payment Types"))
                  )}

                  {managePageHrefLink(
                    "/dashboard/manage/restaurant/manage-party",
                    _t(t("Party"))
                  )}

                  {managePageHrefLink(
                    "/dashboard/manage/restaurant/manage-expense",
                    _t(t("Expense"))
                  )}

                  {managePageHrefLink(
                    "/dashboard/manage/restaurant/party-history",
                    _t(t("Party History"))
                  )}

                  {managePageHrefLink(
                    "/dashboard/manage/restaurant/manage-kot",
                    _t(t("Manage KOT"))
                  )}

                  {managePageHrefLink(
                    "/dashboard/manage/restaurant/settlement",
                    _t(t("Settlements"))
                  )}

                  {managePageHrefLink(
                    "/dashboard/manage/restaurant/kds-setting",
                    _t(t("KDS Setting"))
                  )}
                </ul>
              </li>
             ) : ""}

            {/* website */}
            {/* <li
              className={`fk-pos-nav__list fk-pos-nav__list-has-sub ${window.location.pathname.includes("/website/") ? "active" : ""
                }`}
            >
              <a
                className="w-100 t-text-dark t-heading-font btn btn-outline-danger font-weight-bold text-uppercase rounded-0 text-left"
                rel="noopener noreferrer"
                href="#"
              >
                {_t(t("Website"))}
              </a>
              <ul className="t-list fk-pos-nav__sub t-bg-white list-group">
                {managePageHrefLink(
                  "/dashboard/manage/website/hero-section",
                  _t(t("Hero Section"))
                )}

                {managePageHrefLink(
                  "/dashboard/manage/website/promotions",
                  _t(t("Promotions"))
                )}
              </ul>
            </li> */}

            {/* Settings */}
            {authUserInfo.permissions !== null &&
              checkPermission(
                authUserInfo.permissions,
                "Manage Settings"
              ) ? (
              <li
                className={`fk-pos-nav__list fk-pos-nav__list-has-sub ${
                  window.location.pathname.includes("/settings/") ? "active" : ""
                }`}
              >
                <a
                  className="w-100 t-text-dark t-heading-font btn btn-outline-danger font-weight-bold text-uppercase rounded-0 text-left"
                  rel="noopener noreferrer"
                  href="#"
                >
                  {_t(t("Settings"))}
                </a>
                <ul className="t-list fk-pos-nav__sub t-bg-white list-group">
                  {managePageHrefLink(
                    "/dashboard/manage/settings/currencies",
                    _t(t("Currencies"))
                  )}

                  {managePageHrefLink(
                    "/dashboard/manage/settings/languages",
                    _t(t("Languages"))
                  )}

                  {/*managePageHrefLink(
                    "/dashboard/manage/settings/smtp-settings",
                    _t(t("Email / Smtp"))
                  )*/}

                  {managePageHrefLink(
                    "/dashboard/manage/settings/email-settings",
                    _t(t("Email Template"))
                  )}

                  {/*managePageHrefLink(
                    "/dashboard/manage/settings/pos-screen",
                    _t(t("Pos Screen"))
                  )*/}

                  {managePageHrefLink(
                    "/dashboard/manage/settings/setup-payment",
                    _t(t("Payment Setup"))
                  )}

                  {/*managePageHrefLink(
                    "/dashboard/manage/settings/update-system",
                    _t(t("Update System"))
                  )*/}

                  {managePageHrefLink(
                    "/dashboard/manage/settings/general-settings",
                    _t(t("General Settings"))
                  )}

                  {managePageHrefLink(
                    "/dashboard/manage/settings/web-mapping",
                    _t(t("Web Mapping"))
                  )}

                  {managePageHrefLink(
                    "/dashboard/manage/settings/show-delivery-menu",
                    _t(t("Show Deliverymen"))
                  )}

                  {managePageHrefLink(
                    "/dashboard/manage/settings/show-manage-stock-menu",
                    _t(t("Show Manage Stock"))
                  )}
                  {managePageHrefLink(
                    "/dashboard/manage/settings/update-settings",
                    _t(t("Update Settings"))
                  )}
                </ul>
              </li>
             ) : ""}
          </ul>
        </div>
      </div>
      {/* Navigation for large Screen End */}
    </>
  );
};

export default ManageSidebar;
