import React, { useEffect, useContext, useState, useRef } from "react";
import { NavLink } from "react-router-dom";
//axios and base url
import axios from "axios";
import { BASE_URL } from "../../../../BaseUrl";
import Moment from "react-moment";
//functions
import {
  _t,
  getCookie,
  modalLoading,
  pageLoading,
  paginationLoading,
  pagination,
  showingData,
  searchedShowingData,
  getSystemSettings
} from "../../../../functions/Functions";
import { useTranslation } from "react-i18next";

//3rd party packages
import { Helmet } from "react-helmet";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { MdDelete } from 'react-icons/md';
import ReactHTMLTableToExcel from "react-html-table-to-excel";

//importing context consumer here
import { UserContext } from "../../../../contexts/User";
import { SettingsContext } from "../../../../contexts/Settings";
import { RestaurantContext } from "../../../../contexts/Restaurant";

const Customers = () => {
  //getting context values here
  const {
    //common
    loading,
    setLoading,
    generalSettings
  } = useContext(SettingsContext);

  const {
    authUserInfo,

    //customer
    customerList,
    setCustomerList,
    setPaginatedCustomer,
    customerForSearch,
    setCustomerForSearch,

    //pagination
    dataPaginating,
  } = useContext(UserContext);

  const {
    //branch
    branchForSearch,
  } = useContext(RestaurantContext);

  const { t } = useTranslation();

  // States hook here
  //new customer
  let [newCustomer, setNewCustomer] = useState({
    name: "",
    email: "",
    phn_no: "",
    address: "",
    branch: null,
    selectedBranch: null,
    edit: false,
    editSlug: null,
    uploading: false,
  });

  //search result
  let [searchedCustomer, setSearchedCustomer] = useState({
    list: null,
    searched: false,
  });
  const [checkPass, setCheckPass] = useState("");
  const passModal = useRef("");
  const componentRef = useRef();
  const password = "customer#280@";

  //useEffect == componentDidMount
  useEffect(() => {}, []);

  //set name, phn no hook
  const handleSetNewCustomer = (e) => {
    setNewCustomer({ ...newCustomer, [e.target.name]: e.target.value });
  };

  //set branch hook
  const handleSetBranch = (branch) => {
    setNewCustomer({ ...newCustomer, branch });
  };

  //Save New customer
  const handleSaveNewCustomer = (e) => {
    e.preventDefault();
    let checkBranch = false;
    if (
      authUserInfo.details !== null &&
      authUserInfo.details.user_type !== "staff" &&  authUserInfo.details.user_type !== "manager"
    ) {
      checkBranch = true;
    }
    if (checkBranch) {
      if (newCustomer.branch !== null && newCustomer.phn_no && newCustomer.name) {
        setNewCustomer({
          ...newCustomer,
          uploading: true,
        });
        const customerUrl = BASE_URL + `/settings/new-customer`;
        let formData = new FormData();
        formData.append("name", newCustomer.name);
        formData.append("phn_no", newCustomer.phn_no);
        formData.append("email", newCustomer.email);
        formData.append("address", newCustomer.address);
        formData.append("branch_id", newCustomer.branch.id);
        return axios
          .post(customerUrl, formData, {
            headers: { Authorization: `Bearer ${getCookie()}` },
          })
          .then((res) => {
            setNewCustomer({
              name: "",
              email: "",
              phn_no: "",
              address: "",
              branch: null,
              selectedBranch: null,
              edit: false,
              editSlug: null,
              uploading: false,
            });
            setCustomerList(res.data[0]);
            setCustomerForSearch(res.data[1]);
            setLoading(false);
            toast.success(`${_t(t("Customer has been added"))}`, {
              position: "bottom-center",
              autoClose: 10000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              className: "text-center toast-notification",
            });
            document.getElementById('close-btn').click();
          })
          .catch((error) => {
            setLoading(false);
            setNewCustomer({
              ...newCustomer,
              uploading: false,
            });
            if (error && error.response.data.errors) {
              if (error.response.data.errors.phn_no) {
                error.response.data.errors.phn_no.forEach((item) => {
                  if (item === "A customer exists with this phone number") {
                    toast.error(
                      `${_t(t("A customer exists with this phone number"))}`,
                      {
                        position: "bottom-center",
                        autoClose: 10000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        className: "text-center toast-notification",
                      }
                    );
                  }
                });
              }
            }
          });
      } else {
        toast.error(`${_t(t("Please select a branch, fill name and phone no"))}`, {
          position: "bottom-center",
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          className: "text-center toast-notification",
        });
      }
    } else {
      setNewCustomer({
        ...newCustomer,
        uploading: true,
      });
      const customerUrl = BASE_URL + `/settings/new-customer`;
      let formData = new FormData();
      formData.append("name", newCustomer.name);
      formData.append("phn_no", newCustomer.phn_no);
      formData.append("email", newCustomer.email);
      formData.append("address", newCustomer.address);
      if (authUserInfo.details !== null) {
        formData.append("branch_id", authUserInfo.details.branch_id);
      }
      return axios
        .post(customerUrl, formData, {
          headers: { Authorization: `Bearer ${getCookie()}` },
        })
        .then((res) => {
          setNewCustomer({
            name: "",
            email: "",
            phn_no: "",
            address: "",
            branch: null,
            selectedBranch: null,
            edit: false,
            editSlug: null,
            uploading: false,
          });
          setCustomerList(res.data[0]);
          setCustomerForSearch(res.data[1]);
          setLoading(false);
          toast.success(`${_t(t("Customer has been added"))}`, {
            position: "bottom-center",
            autoClose: 10000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            className: "text-center toast-notification",
          });
          document.getElementById('close-btn').click();
        })
        .catch((error) => {
          setLoading(false);
          setNewCustomer({
            ...newCustomer,
            uploading: false,
          });
          if (error && error.response.data.errors) {
            if (error.response.data.errors.phn_no) {
              error.response.data.errors.phn_no.forEach((item) => {
                if (item === "A customer exists with this phone number") {
                  toast.error(
                    `${_t(t("A customer exists with this phone number"))}`,
                    {
                      position: "bottom-center",
                      autoClose: 10000,
                      hideProgressBar: false,
                      closeOnClick: true,
                      pauseOnHover: true,
                      className: "text-center toast-notification",
                    }
                  );
                }
              });
            }
          }
        });
    }
  };

  //set edit true & values
  const handleSetEdit = (slug) => {
    let customer = customerForSearch.filter((item) => {
      return item.slug === slug;
    });

    let selectedOptionForBranch = null;
    if (
      authUserInfo.details !== null &&
      authUserInfo.details.user_type !== "staff" &&  authUserInfo.details.user_type !== "manager"
    ) {
      if (customer[0].branch_id) {
        selectedOptionForBranch = branchForSearch.filter((branchItem) => {
          return branchItem.id === parseInt(customer[0].branch_id);
        });
      }
    }
    setNewCustomer({
      ...newCustomer,
      name: customer[0].name,
      email: customer[0].email,
      phn_no: customer[0].phn_no,
      address: customer[0].address,
      selectedBranch: selectedOptionForBranch
        ? selectedOptionForBranch[0]
        : null,
      editSlug: customer[0].slug,
      edit: true,
    });
  };

  //update customer
  const handleUpdateCustomer = (e) => {
    e.preventDefault();
    setNewCustomer({
      ...newCustomer,
      uploading: true,
    });
    const customerUrl = BASE_URL + `/settings/update-customer`;
    let formData = new FormData();
    formData.append("name", newCustomer.name);
    formData.append("phn_no", newCustomer.phn_no);
    formData.append("email", newCustomer.email);
    formData.append("address", newCustomer.address);
    if (newCustomer.branch !== null) {
      formData.append("branch_id", newCustomer.branch.id);
    }
    formData.append("editSlug", newCustomer.editSlug);
    return axios
      .post(customerUrl, formData, {
        headers: { Authorization: `Bearer ${getCookie()}` },
      })
      .then((res) => {
        setNewCustomer({
          name: "",
          email: "",
          phn_no: "",
          address: "",
          branch: null,
          selectedBranch: null,
          edit: false,
          editSlug: null,
          uploading: false,
        });
        setCustomerList(res.data[0]);
        setCustomerForSearch(res.data[1]);
        setSearchedCustomer({
          ...searchedCustomer,
          list: res.data[1],
        });
        setLoading(false);
        toast.success(`${_t(t("Customer has been updated"))}`, {
          position: "bottom-center",
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          className: "text-center toast-notification",
        });
        document.getElementById('close-btn').click();
      })
      .catch((error) => {
        setLoading(false);
        setNewCustomer({
          ...newCustomer,
          uploading: false,
        });
        if (error && error.response.data.errors) {
          if (error.response.data.errors.phn_no) {
            error.response.data.errors.phn_no.forEach((item) => {
              if (item === "A customer exists with this phone number") {
                toast.error(
                  `${_t(t("A customer exists with this phone number"))}`,
                  {
                    position: "bottom-center",
                    autoClose: 10000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    className: "text-center toast-notification",
                  }
                );
              }
            });
          }
        }
      });
  };

  //search customers here
  const handleSearch = (e) => {
    let searchInput = e.target.value.toLowerCase();
    if (searchInput.length === 0) {
      setSearchedCustomer({ ...searchedCustomer, searched: false });
    } else {
      let searchedList = customerForSearch.filter((item) => {
        //name
        let lowerCaseItemName = item.name && item.name.toLowerCase();

        //email
        let lowerCaseItemEmail =
          item.email !== null && item.email.toLowerCase();

        //phn no
        let lowerCaseItemPhnNo =
          item.phn_no !== null && item.phn_no.toLowerCase();

        //address
        let lowerCaseItemAddress =
          item.address !== null && item.address.toLowerCase();

        //branch
        let lowerCaseItemBranch =
          item.branch_name !== null && item.branch_name.toLowerCase();
        return (
          (lowerCaseItemName && lowerCaseItemName.includes(searchInput)) ||
          (lowerCaseItemEmail && lowerCaseItemEmail.includes(searchInput)) ||
          (lowerCaseItemPhnNo && lowerCaseItemPhnNo.includes(searchInput)) ||
          (lowerCaseItemAddress &&
            lowerCaseItemAddress.includes(searchInput)) ||
          (lowerCaseItemBranch && lowerCaseItemBranch.includes(searchInput))
        );
      });
      setSearchedCustomer({
        ...searchedCustomer,
        list: searchedList,
        searched: true,
      });
    }
  };

  //delete confirmation modal of waiter
  const handleDeleteConfirmation = (slug) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="card card-body">
            <h1>{_t(t("Are you sure?"))}</h1>
            <p className="text-center">{_t(t("You want to delete this?"))}</p>
            <div className="d-flex justify-content-center">
              <button
                className="btn btn-primary rounded"
                onClick={() => {
                  handleDeleteCustomer(slug);
                  onClose();
                }}
              >
                {_t(t("Yes, delete it!"))}
              </button>
              <button className="btn btn-success ml-2 px-3 rounded" onClick={onClose}>
                {_t(t("No"))}
              </button>
            </div>
          </div>
        );
      },
    });
  };

  const handleCustomerSubmit = () =>{
    if(checkPass !== password){
      passModal.current.classList += " showing";
      return false;
    }  
  }

  const handleCustomerDownload = (e) =>{
    e.preventDefault();
    if(checkPass !== password){
      toast.error(`${_t(t("Incorrect Password"))}`,);
      return false;
    }else{
        passModal.current.classList.remove("showing");
        componentRef.current.handleDownload();
    }
  }

  //delete customer here
  const handleDeleteCustomer = (slug) => {
    setLoading(true);
    const customerUrl = BASE_URL + `/settings/delete-customer/${slug}`;
    return axios
      .get(customerUrl, {
        headers: { Authorization: `Bearer ${getCookie()}` },
      })
      .then((res) => {
        setCustomerList(res.data[0]);
        setCustomerForSearch(res.data[1]);
        setSearchedCustomer({
          ...searchedCustomer,
          list: res.data[1],
        });
        setLoading(false);
        toast.success(`${_t(t("Customer has been deleted successfully"))}`, {
          position: "bottom-center",
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          className: "text-center toast-notification",
        });
      })
      .catch(() => {
        setLoading(false);
        toast.error(`${_t(t("Please try again"))}`, {
          position: "bottom-center",
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          className: "text-center toast-notification",
        });
      });
  };

  return (
    <>
      <Helmet>
        <title>{_t(t("Customers"))}</title>
      </Helmet>
      <ReactHTMLTableToExcel
      id="test-table-xls-button"
      className="download-table-xls-button btn btn-success float-right mr-3 invisible"
      table="table-to-xls"
      filename="customer report"
      sheet="sheet 1"
      buttonText="customer details"
      ref={componentRef}
     />

      {/* Add modal */}
      <div className="modal fade" id="addCustomer" aria-hidden="true">
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header align-items-center">
              <div className="fk-sm-card__content">
                <h5 className="text-capitalize fk-sm-card__title">
                  {!newCustomer.edit
                    ? _t(t("Add new customer"))
                    : _t(t("Update customer"))}
                </h5>
              </div>
              <button
                type="button"
                id="close-btn"
                className="btn-close"
                data-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              {/* show form or show saving loading */}
              {newCustomer.uploading === false ? (
                <div key="fragment-customer-1">
                  <form
                    onSubmit={
                      !newCustomer.edit
                        ? handleSaveNewCustomer
                        : handleUpdateCustomer
                    }
                  >
                    <div>
                      <label htmlFor="name" className="form-label">
                        {_t(t("Name"))}{" "}
                        <small className="text-danger">*</small>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="name"
                        name="name"
                        placeholder="e.g. Mr. John"
                        value={newCustomer.name || ""}
                        required
                        onChange={handleSetNewCustomer}
                      />
                    </div>

                    {authUserInfo.details !== null &&
                      authUserInfo.details.user_type !== "staff" &&  authUserInfo.details.user_type !== "manager" && (
                        <div className="mt-3">
                          <label className="form-label mb-0">
                            {_t(t("Select a branch"))}{" "}
                            {newCustomer.edit ? (
                              <small className="text-primary">
                                {"( "}
                                {_t(
                                  t(
                                    "Leave empty if you do not want to change branch"
                                  )
                                )}
                                {" )"}
                              </small>
                            ) : (
                              <small className="text-danger">*</small>
                            )}
                          </label>
                          {newCustomer.edit &&
                            newCustomer.selectedBranch !== null && (
                              <ul className="list-group list-group-horizontal-sm row col-12 mb-2 ml-md-1">
                                <li className="list-group-item col-12 col-md-3 bg-success rounded-sm py-1 px-2 my-1 text-center">
                                  {newCustomer.selectedBranch.name}
                                </li>
                              </ul>
                            )}
                          <Select
                            options={branchForSearch}
                            components={makeAnimated()}
                            getOptionLabel={(option) => option.name}
                            getOptionValue={(option) => option.name}
                            className="basic-multi-select mt-2"
                            classNamePrefix="select"
                            onChange={handleSetBranch}
                            placeholder={_t(t("Please select a branch"))}
                          />
                        </div>
                      )}

                    <div className="mt-3">
                      <label htmlFor="email" className="form-label">
                        {_t(t("Email"))}
                      </label>
                      <input
                        type="email"
                        className="form-control"
                        id="email"
                        name="email"
                        placeholder="e.g. customer@example.com"
                        value={newCustomer.email || ""}
                        onChange={handleSetNewCustomer}
                      />
                    </div>

                    <div className="mt-3">
                      <label htmlFor="phn_no" className="form-label">
                        {_t(t("Phone number"))}  <small className="text-primary">*</small>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="phn_no"
                        name="phn_no"
                        placeholder="e.g. 01xxx xxx xxx"
                        value={newCustomer.phn_no || ""}
                        onChange={handleSetNewCustomer}
                      />
                    </div>

                    <div className="mt-3">
                      <label htmlFor="address" className="form-label">
                        {_t(t("Address"))}
                      </label>
                      <textarea
                        type="text"
                        className="form-control"
                        id="address"
                        name="address"
                        placeholder="Type customer address"
                        value={newCustomer.address || ""}
                        onChange={handleSetNewCustomer}
                      />
                    </div>

                    <div className="mt-4">
                      <div className="row d-flex justify-content-end">
                        <div className="col-3">
                          <button
                            type="submit"
                            className="btn btn-success w-100 xsm-text text-uppercase t-width-max rounded"
                          >
                            {!newCustomer.edit
                              ? _t(t("Save"))
                              : _t(t("Update"))}
                          </button>
                        </div>
                        <div className="col-3">
                          <button
                            type="button"
                            className="btn btn-primary w-100 xsm-text text-uppercase t-width-max rounded"
                            data-dismiss="modal"
                          >
                            {_t(t("Close"))}
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              ) : (
                <div key="fragment2">
                  <div className="text-center text-primary font-weight-bold text-uppercase">
                    {_t(t("Please wait"))}
                  </div>
                  {modalLoading(3)}
                  <div className="mt-4">
                    <div className="row d-flex justify-content-end">
                      <div className="col-3">
                        <button
                          type="button"
                          className="btn btn-success w-100 xsm-text text-uppercase t-width-max rounded"
                          onClick={(e) => {
                            e.preventDefault();
                          }}
                        >
                          {!newCustomer.edit ? _t(t("Save")) : _t(t("Update"))}
                        </button>
                      </div>
                      <div className="col-3">
                        <button
                          type="button"
                          className="btn btn-primary w-100 xsm-text text-uppercase t-width-max rounded"
                          data-dismiss="modal"
                        >
                          {_t(t("Close"))}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      {/* Add modal Ends*/}

      {/* main body */}
      <main id="main" data-simplebar>
        <div className="container-fluid">
          <div className="row t-mt-10 gx-2">
            <div className="col-12 t-mb-30 mb-lg-0">
              {newCustomer.uploading === true || loading === true ? (
                pageLoading()
              ) : (
                <div className="t-bg-white ">
                  {/* next page data spin loading */}
                  <div className={`${(dataPaginating || loading) && "loadingBlur"}`}></div>
                    <div className={`${(dataPaginating || loading) && "lds-roller"}`}><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                  {/* spin loading ends */}
                  <div className="row gx-2 align-items-center t-pt-15 t-pb-15 t-pl-15 t-pr-15 t-shadow">
                    <div className="col-12 t-mb-15">
                      <ul className="t-list fk-breadcrumb">
                        <li className="fk-breadcrumb__list">
                          <span className="t-link fk-breadcrumb__link text-capitalize">
                            {!searchedCustomer.searched
                              ? _t(t("Customers"))
                              : _t(t("Search Result"))}
                          </span>
                        </li>
                      </ul>
                    </div>
                    <div className="col-5 col-lg-4">
                      <div className="input-group">
                        <button className="btn btn-primary rounded-left" type="button">
                          <i className="fa fa-search" aria-hidden="true"></i>
                        </button>
                        <div className="form-file">
                          <input
                            type="text"
                            className="form-control  form-control--light-1 rounded-right"
                            placeholder={_t(t("Search")) + ".."}
                            onChange={handleSearch}
                          />
                        </div>
                        {/*
                        <NavLink
                          to="/dashboard/online-customers"
                          className="btn d-flex align-items-center btn-primary ml-2 sm-text text-uppercase rounded"
                        >
                          {_t(t("Online Customers"))}
                        </NavLink>*/}
                      </div>
                    </div>
                    <div className="col-7 col-lg-8 t-mb-15 mb-md-0">
                      <div className="row gx-2 align-items-center">
                      <div className="col-2">
                      </div>
                        <div className="col-3 col-lg-2 ml-auto mt-2 mt-md-0">
                          <ul className="t-list fk-sort align-items-center justify-content-center">
                            <li className="fk-sort__list mb-0 flex-grow-1">
                              <button
                                className="w-100 btn btn-secondary sm-text text-uppercase rounded"
                                data-toggle="modal"
                                data-target="#addCustomer"
                                onClick={() => {
                                  setNewCustomer({
                                    ...newCustomer,
                                    branch: null,
                                    edit: false,
                                    uploading: false,
                                  });
                                }}
                              >
                                {_t(t("add new"))}
                              </button>
                            </li>
                          </ul>
                        </div>
                        <div className="col-3 col-lg-2 mt-2 mt-md-0">
                       
                            <button
                              className="w-100 btn btn-primary sm-text text-uppercase rounded"
                              onClick={handleCustomerSubmit}
                            >
                            <i class="fa fa-table mr-1" aria-hidden="true"></i>
                              {_t(t("Download"))}
                            </button>
                        
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="fk-scroll--order-history" data-simplebar>
                    <div className="t-pl-15 t-pr-15">
                        <table className="table table-bordered table-hover min-table-height mt-4">
                          <thead className="align-middle">
                            <tr>
                              <th
                                scope="col"
                                className="sm-text text-capitalize align-middle text-center border-1 border"
                              >
                                {_t(t("S/L"))}
                              </th>

                              <th
                                scope="col"
                                className="sm-text text-capitalize align-middle text-center border-1 border"
                              >
                                {_t(t("Name"))}
                              </th>
                              <th
                                scope="col"
                                className="sm-text text-capitalize align-middle text-center border-1 border"
                              >
                                {_t(t("email"))}
                              </th>
                              <th
                                scope="col"
                                className="sm-text text-capitalize align-middle text-center border-1 border"
                              >
                                {_t(t("Phn no"))}
                              </th>
                              <th
                                scope="col"
                                className="sm-text text-capitalize align-middle text-center border-1 border"
                              >
                                {_t(t("Address"))}
                              </th>

                              <th
                                scope="col"
                                className="sm-text text-capitalize align-middle text-center border-1 border"
                              >
                                {_t(t("Branch"))}
                              </th>

                              <th
                                scope="col"
                                className="sm-text text-capitalize align-middle text-center border-1 border"
                              >
                                {_t(t("First Order"))}
                              </th>

                              <th
                                scope="col"
                                className="sm-text text-capitalize align-middle text-center border-1 border"
                              >
                                {_t(t("Last Order"))}
                              </th>

                              <th
                                scope="col"
                                className="sm-text text-capitalize align-middle text-center border-1 border"
                              >
                                {_t(t("Total Order"))}
                              </th>

                              <th
                                scope="col"
                                className="sm-text text-capitalize align-middle text-center border-1 border"
                              >
                                {_t(t("Total Spending"))}
                              </th>

                              <th
                                scope="col"
                                className="sm-text text-capitalize align-middle text-center border-1 border"
                              >
                                {_t(t("Action"))}
                              </th>
                            </tr>
                          </thead>
                          <tbody className="align-middle">
                            {/* loop here, logic === !search && haveData && haveDataLegnth > 0*/}
                            {!searchedCustomer.searched
                              ? [
                                  customerList && [
                                    customerList.data.length === 0 ? (
                                      <tr className="align-middle">
                                        <td
                                          scope="row"
                                          colSpan="7"
                                          className="xsm-text align-middle text-center"
                                        >
                                          {_t(t("No data available"))}
                                        </td>
                                      </tr>
                                    ) : (
                                      customerList.data.map((item, index) => {
                                        return (
                                          <tr
                                            className="align-middle"
                                            key={index}
                                          >
                                            <th
                                              scope="row"
                                              className="xsm-text text-capitalize align-middle text-center"
                                            >
                                              {index +
                                                1 +
                                                (customerList.current_page -
                                                  1) *
                                                  customerList.per_page}
                                            </th>

                                            <td className="xsm-text text-capitalize align-middle text-center">
                                              {item.name}
                                            </td>

                                            <td className="xsm-text align-middle text-center">
                                              {item.email || "-"}
                                            </td>

                                            <td className="xsm-text text-capitalize align-middle text-center">
                                              {item.phn_no ? (
                                                <a
                                                  href={`tel:${item.phn_no}`}
                                                  rel="noopener noreferrer"
                                                >
                                                  {item.phn_no}
                                                </a>
                                              ) : (
                                                "-"
                                              )}
                                            </td>
                                            <td className="xsm-text text-capitalize align-middle text-center">
                                              {item.address || "-"}
                                            </td>

                                            <td className="xsm-text align-middle text-center">
                                              {item.branch_name || "-"}
                                            </td>
                                            <td className="xsm-text align-middle text-center">
                                              {
                                                item.first_order_date ? (
                                                  <Moment format="DD-MM-YYYY hh:mm A">
                                                    {item.first_order_date}
                                                  </Moment>
                                                ) : (
                                                  "-"
                                                )
                                              }
                                            </td>
                                            <td className="xsm-text align-middle text-center">
                                              {
                                                item.last_order_date ? (
                                                  <Moment format="DD-MM-YYYY hh:mm A">
                                                    {item.last_order_date}
                                                  </Moment>
                                                ) : (
                                                  "-"
                                                )
                                              }
                                            </td>
                                            <td className="xsm-text align-middle text-center">
                                              {item.total_orders || "-"}
                                            </td>
                                            <td className="xsm-text align-middle text-center">
                                              {item.total_payable || "-"}
                                            </td>

                                            <td className="xsm-text text-capitalize align-middle text-center">
                                              {/* <div className="dropdown">
                                                <button
                                                  className="btn t-bg-clear t-text-dark--light-40"
                                                  type="button"
                                                  data-toggle="dropdown"
                                                >
                                                  <i className="fa fa-ellipsis-h"></i>
                                                </button>
                                                <div className="dropdown-menu">
                                                  <button
                                                    className="dropdown-item sm-text text-capitalize"
                                                    onClick={() => {
                                                      setNewCustomer({
                                                        ...newCustomer,
                                                        branch: null,
                                                      });
                                                      handleSetEdit(item.slug);
                                                    }}
                                                    data-toggle="modal"
                                                    data-target="#addCustomer"
                                                  >
                                                    <span className="t-mr-8">
                                                      <i className="fa fa-pencil"></i>
                                                    </span>
                                                    {_t(t("Edit"))}
                                                  </button>

                                                  <button
                                                    className="dropdown-item sm-text text-capitalize"
                                                    onClick={() => {
                                                      handleDeleteConfirmation(
                                                        item.slug
                                                      );
                                                    }}
                                                  >
                                                    <span className="t-mr-8">
                                                      <i className="fa fa-trash"></i>
                                                    </span>
                                                    {_t(t("Delete"))}
                                                  </button>
                                                </div>
                                              </div> */}
                                              <button
                                                  className="btn btn-primary ml-2 rounded-sm del-btn"
                                                  onClick={() => {
                                                    setNewCustomer({
                                                      ...newCustomer,
                                                      branch: null,
                                                    });
                                                    handleSetEdit(item.slug);
                                                  }}
                                                  data-toggle="modal"
                                                  data-target="#addCustomer"                                               
                                                  >                                                    
                                                    <i className="fa fa-pencil edit-icon" aria-hidden="true"></i>                                                    
                                                 </button>
                                                  <button
                                                  className="btn btn-danger ml-2 rounded-sm del-btn"
                                                  onClick={()=>handleDeleteConfirmation(item.slug)}                                                    
                                                  >                                                    
                                                    <MdDelete className="del-icon" />                                                    
                                                 </button>
                                            </td>
                                          </tr>
                                        );
                                      })
                                    ),
                                  ],
                                ]
                              : [
                                  /* searched data, logic === haveData*/
                                  searchedCustomer && [
                                    searchedCustomer.list.length === 0 ? (
                                      <tr className="align-middle">
                                        <td
                                          scope="row"
                                          colSpan="7"
                                          className="xsm-text align-middle text-center"
                                        >
                                          {_t(t("No data available"))}
                                        </td>
                                      </tr>
                                    ) : (
                                      searchedCustomer.list.map(
                                        (item, index) => {
                                          return (
                                            <tr
                                              className="align-middle"
                                              key={index}
                                            >
                                              <th
                                                scope="row"
                                                className="xsm-text text-capitalize align-middle text-center"
                                              >
                                                {index +
                                                  1 +
                                                  (customerList.current_page -
                                                    1) *
                                                    customerList.per_page}
                                              </th>

                                              <td className="xsm-text text-capitalize align-middle text-center">
                                                {item.name}
                                              </td>

                                              <td className="xsm-text align-middle text-center">
                                                {item.email || "-"}
                                              </td>

                                              <td className="xsm-text text-capitalize align-middle text-center">
                                                {item.phn_no ? (
                                                  <a
                                                    href={`tel:${item.phn_no}`}
                                                    rel="noopener noreferrer"
                                                  >
                                                    {item.phn_no}
                                                  </a>
                                                ) : (
                                                  "-"
                                                )}
                                              </td>
                                              <td className="xsm-text align-middle text-center">
                                                {item.address || "-"}
                                              </td>

                                              <td className="xsm-text align-middle text-center">
                                                {item.branch_name || "-"}
                                              </td>
                                              <td className="xsm-text align-middle text-center">
                                                {
                                                  item.first_order_date ? (
                                                    <Moment format="DD-MM-YYYY hh:mm A">
                                                      {item.first_order_date}
                                                    </Moment>
                                                  ) : (
                                                    "-"
                                                  )
                                                }
                                              </td>
                                              <td className="xsm-text align-middle text-center">
                                                {
                                                  item.last_order_date ? (
                                                    <Moment format="DD-MM-YYYY hh:mm A">
                                                      {item.last_order_date}
                                                    </Moment>
                                                  ) : (
                                                    "-"
                                                  )
                                                }
                                              </td>
                                              <td className="xsm-text align-middle text-center">
                                                {item.total_orders || "-"}
                                              </td>
                                              <td className="xsm-text align-middle text-center">
                                                {item.total_payable || "-"}
                                              </td>

                                              <td className="xsm-text text-capitalize align-middle text-center">
                                                <div className="dropdown">
                                                  <button
                                                    className="btn t-bg-clear t-text-dark--light-40"
                                                    type="button"
                                                    data-toggle="dropdown"
                                                  >
                                                    <i className="fa fa-ellipsis-h"></i>
                                                  </button>
                                                  <div className="dropdown-menu">
                                                    <button
                                                      className="dropdown-item sm-text text-capitalize"
                                                      onClick={() => {
                                                        setNewCustomer({
                                                          ...newCustomer,
                                                          branch: null,
                                                        });
                                                        handleSetEdit(
                                                          item.slug
                                                        );
                                                      }}
                                                      data-toggle="modal"
                                                      data-target="#addCustomer"
                                                    >
                                                      <span className="t-mr-8">
                                                        <i className="fa fa-pencil"></i>
                                                      </span>
                                                      {_t(t("Edit"))}
                                                    </button>

                                                    <button
                                                      className="dropdown-item sm-text text-capitalize"
                                                      onClick={() => {
                                                        handleDeleteConfirmation(
                                                          item.slug
                                                        );
                                                      }}
                                                    >
                                                      <span className="t-mr-8">
                                                        <i className="fa fa-trash"></i>
                                                      </span>
                                                      {_t(t("Delete"))}
                                                    </button>
                                                  </div>
                                                </div>
                                              </td>
                                            </tr>
                                          );
                                        }
                                      )
                                    ),
                                  ],
                                ]}
                          </tbody>
                        </table>
                    </div>
                  </div>
                </div>
              )}
              {/* pagination loading effect */}
              {newCustomer.uploading === true || loading === true
                ? paginationLoading()
                : [
                    // logic === !searched
                    !searchedCustomer.searched ? (
                      <div key="fragment4">
                        <div className="t-bg-white mt-1 t-pt-5 t-pb-5">
                          <div className="row align-items-center t-pl-15 t-pr-15">
                            <div className="col-md-7 t-mb-15 mb-md-0">
                              {/* pagination function */}
                              {pagination(customerList, setPaginatedCustomer)}
                            </div>
                            <div className="col-md-5">
                              <ul className="t-list d-flex justify-content-md-end align-items-center">
                                <li className="t-list__item">
                                  <span className="d-inline-block sm-text">
                                    {showingData(customerList)}
                                  </span>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      // if searched
                      <div className="t-bg-white mt-1 t-pt-5 t-pb-5">
                        <div className="row align-items-center t-pl-15 t-pr-15">
                          <div className="col-md-7 t-mb-15 mb-md-0">
                            <ul className="t-list d-flex">
                              <li className="t-list__item no-pagination-style">
                                <button
                                  className="btn btn-primary btn-sm rounded"
                                  onClick={() =>
                                    setSearchedCustomer({
                                      ...searchedCustomer,
                                      searched: false,
                                    })
                                  }
                                >
                                  {_t(t("Clear Search"))}
                                </button>
                              </li>
                            </ul>
                          </div>
                          <div className="col-md-5">
                            <ul className="t-list d-flex justify-content-md-end align-items-center">
                              <li className="t-list__item">
                                <span className="d-inline-block sm-text">
                                  {searchedShowingData(
                                    searchedCustomer,
                                    customerForSearch
                                  )}
                                </span>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    ),
                  ]}
            </div>
          </div>
        </div>
      </main>
      <div className="d-none">
        <table className="table table-bordered"
          id="table-to-xls"
        >
          <thead className="align-middle">
            <tr style={{ border: "0px" }}>
              <th colspan="10" style={{ border: "0px" }}>
                <div className="row">
                  <div className="col-12">
                    <h3 className="text-center">
                      {getSystemSettings(generalSettings, "siteName")}
                    </h3>
                    <h3 className="text-center">
                      Customers
                    </h3>
                    <p className="text-center">
                      Date&nbsp;
                      <Moment format="LL">
                        {new Date()}
                      </Moment>
                    </p>
                  </div>
                </div>
              </th>
            </tr>
            <tr>
              <th
                scope="col"
              >
                {_t(t("S/L"))}
              </th>

              <th
                scope="col"
              >
                {_t(t("Name"))}
              </th>
              <th
                scope="col"
              >
                {_t(t("Email"))}
              </th>
              <th
                scope="col"
              >
                {_t(t("Phone No"))}
              </th>
              <th
                scope="col"
              >
                {_t(t("Address"))}
              </th>

              <th
                scope="col"
              >
                {_t(t("Branch"))}
              </th>

              <th
                scope="col"
              >
                {_t(t("First Order"))}
              </th>

              <th
                scope="col"
              >
                {_t(t("Second Order"))}
              </th>

              <th
                scope="col"
              >
                {_t(t("Total Order"))}
              </th>

              <th
                scope="col"
              >
                {_t(t("Total Spending"))}
              </th>
            </tr>
          </thead>
          <tbody className="align-middle">
            {customerForSearch?.length && customerForSearch.map(
                (item, index) => {
                  return (
                    <tr
                      className="align-middle"
                      key={index}
                    >
                      <th scope="row">
                        {index + 1}
                          
                      </th>

                      <td>
                        {item.name}
                      </td>

                      <td>
                        {item.email || "-"}
                      </td>

                      <td>
                        {item.phn_no ? (item.phn_no) : (
                          "-"
                        )}
                      </td>
                      <td>
                        {item.address || "-"}
                      </td>

                      <td>
                        {item.branch_name || "-"}
                      </td>

                      <td>
                        {
                          item.first_order_date ? (
                            <Moment format="DD-MM-YYYY hh:mm A">
                              {item.first_order_date}
                            </Moment>
                          ) : (
                            "-"
                          )
                        }
                      </td>
                      <td>
                        {
                          item.last_order_date ? (
                            <Moment format="DD-MM-YYYY hh:mm A">
                              {item.last_order_date}
                            </Moment>
                          ) : (
                            "-"
                          )
                        }
                      </td>
                      <td>
                        {item.total_orders || "-"}
                      </td>
                      <td>
                        {item.total_payable || "-"}
                      </td>
                    </tr>
                  );
                }
              )
            }
          </tbody>
        </table>
      </div>
       {/* password Modal */}
       <div
        className="modal fade"
        ref={passModal}
        id="passModal"
        aria-hidden="true"
       >
        <div className="modal-dialog modal-md">
          <div className="modal-content" style={{ borderRadius: "1rem" }}>
           <div className="modal-body py-3">
             {/* show form or show saving loading */}
              <div key="fragment-food-group-1">
                <form autoComplete="off" onSubmit={handleCustomerDownload}>
                    <div>
                    <div className="addons-list__item mt-1 mx-1 row">
                      <label
                        htmlFor="name"
                        className="form-label p-0 d-flex justify-content-between font-weight-md xslg-text mb-3"
                      >
                        <span className="mt-2">Enter Your Password</span>
                        <span
                        onClick={(e) => {
                            passModal.current.classList.remove("showing");
                        }}
                        >
                        <i className="fa fa-times-circle close-1 pointer-cursor"></i>
                        </span>
                      </label>
                        
                        <input
                            type="password"
                            onKeyDown={(evt) =>
                            ["e", "E", "+", "-","ArrowUp","ArrowDown","ArrowLeft", "ArrowRight"].includes(evt.key) &&
                            evt.preventDefault()
                            }
                            onWheel={(e) => e.target.blur()}  
                            className="form-control"
                            id="password"
                            name="password"
                            placeholder="Enter Password"
                            autoComplete="off"
                            value={checkPass}
                            required
                            onChange={(e) => setCheckPass(e.target.value)}
                        />
                    </div>
                    <div className="mt-4">
                        <div className="row mt-1">
                         <div className="col-8"></div>
                         <div className="col-4">
                            <button
                            type="submit"
                            className="btn btn-success font-weight-bold sm-text text-uppercase t-width-max w-100"
                            >
                            {_t(t("Submit"))}
                            </button>
                         </div>
                        </div>
                    </div>
                    </div>

                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Customers;
