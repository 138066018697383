 
const msalConfig = {
    auth: {
      clientId: '',
      authority: `https://login.microsoftonline.com/`,
      redirectUri: window.location.origin || '',
      prompt: "select_account",
      scopes: [
        'user.read'
      ],
    },
    cache: {
      cacheLocation: "localStorage",
      storeAuthStateInCookie: true,
    },
};

export default msalConfig;