import React, { useState, useContext, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";

//pages & includes
import ManageSidebar from "../ManageSidebar";
import Switch from "react-switch";

//functions
import {
  _t,
  getCookie,
  modalLoading,
  tableLoading,
  customStyle,
} from "../../../../../functions/Functions";
import { useTranslation } from "react-i18next";

//axios and base url
import axios from "axios";
import {
  BASE_URL,
} from "../../../../../BaseUrl";

//3rd party packages
import { Helmet } from "react-helmet";
import "react-confirm-alert/src/react-confirm-alert.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

//context consumer
import { SettingsContext } from "../../../../../contexts/Settings";
import { RestaurantContext } from "../../../../../contexts/Restaurant";
import { UserContext } from "../../../../../contexts/User";

import Select from "react-select";
const UserTillAction = () => {
    const { t } = useTranslation();
    const history = useHistory();
    const {id} = useParams();
    //getting context values here
    let {
      //common
      loading,
      setLoading,
    } = useContext(SettingsContext);

    let {
        tillUserForSearch,
    } = useContext(UserContext)
  
    let {
      branchForSearch,
    } = useContext(RestaurantContext);
  
    // States hook here
    //new group
    let [tillUser, setTillUser] = useState({
      name: "",
      branch:null,
      fbr_integration: false,
      sbr_integration:false,
      sbrNtn: "",
      sbrPos: "",
      sbrUserId: "",
      sbrUserPassword: "",
      status: true,
      uploading: false,
      edit: false,
    });
  
    //search result
    let [searchedTill, setSearchedTill] = useState({
      list: null,
      searched: false,
    });

    useEffect(()=>{
      if(id && tillUserForSearch){
        handleSetEdit();
      }
    },[tillUserForSearch])
  
     // on change send req
     const handleChangeStatus = () => {
      setTillUser({
        ...tillUser, 
        status: !tillUser.status,
      })
    };
  
    //set name, phn no, address hook
    const handlesetTillUser = (e) => {
      setTillUser({ ...tillUser, [e.target.name]: e.target.value });
    };

    const handleSetBranch = (branch) =>{
        setTillUser({ ...tillUser, branch });
    }

      //set Fbr Integration
      const handleFbrIntegration = () => {
        setTillUser({
          ...tillUser,
          fbr_integration: !tillUser.fbr_integration,
          sbrNtn: "",
          sbrPos: "",
          sbrUserId: "",
          sbrUserPassword: "",
          sbr_integration: false,
        });
      };
    
      //set srb Integration
      const handleSbrIntegration = () => {
        setTillUser({
          ...tillUser,
          fbr_integration: false,
          sbrNtn: "",
          sbrPos: "",
          sbrUserId: "",
          sbrUserPassword: "",
          sbr_integration: !tillUser.sbr_integration,
        });
      };
    
    //Save New branch
    const handleSaveTillUser = (e) => {
        e.preventDefault();
        if(tillUser.branch === null){
          toast.error(_t(t("Please select branch")), {
              position: "bottom-center",
              autoClose: 10000,
              hideProgressBar: false,
              pauseOnHover: true,
              className: "text-center toast-notification",
            });
            return false;
        }
        setTillUser({
          ...tillUser,
          uploading: true,
        });
        const branchUrl = BASE_URL + `/settings/till-user-storeUpdate`;
        let formData = new FormData();
        if(id){
          formData.append("id", parseInt(id)); 
        }
        formData.append("name", tillUser.name);
        formData.append("branch_id", tillUser.branch.id);
        formData.append("status", tillUser.status ? 1 : 0);
        formData.append("integrate_type", tillUser.sbr_integration ? "SRB" : (tillUser.fbr_integration ? "FBR" : ""));
        formData.append("sbr_ntn", tillUser.sbrNtn);
        formData.append("sbr_pos_id", tillUser.sbrPos);
        formData.append("sbr_user_id", tillUser.sbrUserId);
        formData.append("sbr_user_password", tillUser.sbrUserPassword);

       
        return axios
          .post(branchUrl, formData, {
            headers: { Authorization: `Bearer ${getCookie()}` },
          })
          .then((res) => {
            
            setTillUser({
                name: "",
                branch:null,
                fbr_integration: false,
                sbr_integration: false,
                sbrNtn: "",
                sbrPos: "",
                sbrUserId: "",
                sbrUserPassword: "",
                status: true,
                uploading: false,
                edit: false,
  
            });
            setSearchedTill({
              ...searchedTill,
              list: res.data[1],
            });
            setLoading(false);
            toast.success(`${_t(t(id ? "Till user has been updated" : "Till user has been added"))}`, {
              position: "bottom-center",
              autoClose: 10000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              className: "text-center toast-notification",
            });
            history.push("/dashboard/manage/user/till");       
          })
          .catch((error) => {
            console.log("check error==>",error.response)
            if(error.response.status === 422){
              toast.error(<p style={{whiteSpace:"pre-line"}}>{error.response.data.message}</p>, {
                position: "bottom-center",
                autoClose: 10000,
                hideProgressBar: false,
                pauseOnHover: true,
                className: "text-center toast-notification",
              });
            }else{
              toast.error(`${_t(t("Please try again"))}`, {
                position: "bottom-center",
                autoClose: 10000,
                hideProgressBar: false,
                pauseOnHover: true,
                className: "text-center toast-notification",
              });
            }
            setLoading(false);
            setTillUser({
              ...tillUser,
              uploading: false,
            });
          });
    };
    
    //set edit true & values
    const handleSetEdit = () => {
      const userTill = tillUserForSearch && tillUserForSearch.find((item) => item.id === parseInt(id));
      setTillUser({
        ...tillUser,
        name: userTill.name,
        branch: userTill.branch,
        fbr_integration: userTill.integrate_type === "FBR",
        sbr_integration: userTill.integrate_type === "SRB",
        sbrNtn: userTill.sbr_ntn ? userTill.sbr_ntn :"",
        sbrPos: userTill.sbr_pos_id ? userTill.sbr_pos_id : "",
        sbrUserId: userTill.sbr_user_id ? userTill.sbr_user_id :"",
        sbrUserPassword: userTill.sbr_user_password ? userTill.sbr_user_password : "",
        status: userTill.status == 1 ? true : false,
        edit: true,
      });
  
    };
    
    return (
        <>
          <Helmet>
            <title>{_t(t("User Till"))}</title>
          </Helmet>
    
          {/* Add modal */}          
          {/* Add modal Ends*/}
    
          {/* main body */}
          <main id="main" data-simplebar>
            <div className="container-fluid">
              <div className="row t-mt-10 gx-2">
                {/* left Sidebar */}
                <div className="col-lg-2 col-xxl-2 t-mb-30 mb-lg-0">
                  <ManageSidebar />
                </div>
                {/* left Sidebar ends */}
    
                {/* Rightbar contents */}
                <div className="col-lg-10 col-xxl-10 t-mb-30 mb-lg-0">
                  <div className="t-bg-white">
                    <div className="fk-scroll--pos-menu" data-simplebar>
                      <div className="t-pl-15 t-pr-15">
                        {/* Loading effect */}
                        {tillUser.uploading === true || loading === true ? (
                          tableLoading()
                        ) : (
                          <div key="fragment3">
                            {/* next page data spin loading */}
                            {/* <div className={`${dataPaginating && "loading"}`}></div> */}
                            {/* spin loading ends */}
    
                            <div className="row gx-2 align-items-center t-pt-15 t-pb-15">
                              <div className="col-md-6 col-lg-5 t-mb-15 mb-md-0">
                                <ul className="t-list fk-breadcrumb">
                                  <li className="fk-breadcrumb__list">
                                    <span className="t-link fk-breadcrumb__link text-capitalize">
                                    {!tillUser.edit
                                    ? _t(t("Add New Till"))
                                    : _t(t("Update Till"))}
                                    </span>
                                  </li>
                                </ul>
                              </div>
                              <div className="col-md-6 col-lg-7">
                                <div className="row gx-3 align-items-center">                                  
                                </div>
                              </div>
                            </div>
                            {/* form start here */}
                            <div key="fragment-branch-1">
                                <form
                                  onSubmit={handleSaveTillUser}
                                  className="row card p-2 mx-3 mb-5 new-text-style"
                                  autoComplete="off"
                                >
                                <div className="row">
                                    <div className="form-group col-6 mt-3">
                                        <label htmlFor="name" className="form-label">
                                          {_t(t("Name"))}{" "}
                                          <span className="text-danger">*</span>
                                        </label>
                                        <input
                                          type="text"
                                          className="form-control "
                                          id="name"
                                          name="name"
                                          placeholder="e.g. till"
                                          value={tillUser.name || ""}
                                          required
                                          onChange={handlesetTillUser}
                                        />
                                    </div>                            
                                    <div className="form-group mt-3 col-6">
                                        <label htmlFor="address" className="form-label">
                                          {_t(t("Branch"))}
                                          <span className="text-danger">*</span>
                                        </label>
                                        <Select
                                          options={branchForSearch !== null ? branchForSearch : []}
                                          getOptionLabel={(option) => option.name}
                                          getOptionValue={(option) => option.id}
                                          classNamePrefix="select"
                                          onChange={(e)=>handleSetBranch(e)}
                                          value={tillUser.branch}
                                          styles={customStyle}
                                          isMenuOpen={true}
                                          menuPosition="fixed"
                                          placeholder={
                                          _t(t("Please select branch")) + ".."
                                          }                                                
                                        />
                                    </div>
                                </div>

                                <div className="mt-3 d-flex align-items-center">

                                  {/* FBR Integration Checkbox */}
                                  <input
                                    type="checkbox"
                                    className="form-check-input mr-1 mt-0"
                                    id="fbrIntegration"
                                    checked={tillUser.fbr_integration}
                                    onChange={handleFbrIntegration}
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="fbrIntegration"
                                  >
                                    {_t(t("FBR Integration"))}
                                  </label>
            
                                  {/* SBR Integration Checkbox */}
                                  <input
                                    type="checkbox"
                                    className="form-check-input mr-1 ml-3 mt-0"
                                    id="sbrIntegration"
                                    checked={tillUser.sbr_integration}
                                    onChange={handleSbrIntegration}
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="sbrIntegration"
                                  >
                                    {_t(t("SBR Integration"))}
                                  </label>

                                  <Switch
                                    onChange={handleChangeStatus}
                                    checked={tillUser.status}
                                    className="react-switch mr-1 ml-3 mt-0"
                                    name="status"
                                    height={22}
                                    width={44}
                                    offColor="#ee5253"                        
                                   />
                                   <label className="pl-3">Status</label>
                                  
                                </div>


                                {/* {tillUser.fbr_integration && (
                                  <div className="row">
                                    <div className="mt-3 col-4">
                                      <label
                                        htmlFor="fbr_pos_registration_no"
                                        className="form-label"
                                      >
                                        {_t(t("POS Registration No"))}{" "}
                                        <small className="text-danger">*</small>
                                      </label>
                                      <input
                                        type="text"
                                        className="form-control "
                                        id="fbr_pos_registration_no"
                                        name="fbr_pos_registration_no"
                                        fbr_pos_registration_no="fbr_pos_registration_no"
                                        placeholder="e.g. 123456"
                                        value={tillUser.fbr_pos_registration_no || ""}
                                        required
                                        onChange={handlesetTillUser}
                                      />
                                    </div>
          
                                    <div className="mt-3 col-4">
                                      <label htmlFor="fbr_pos_token" className="form-label">
                                        {_t(t("Pos Token"))}{" "}
                                        <small className="text-danger">*</small>
                                      </label>
                                      <input
                                        type="text"
                                        className="form-control "
                                        id="fbr_pos_token"
                                        name="fbr_pos_token"
                                        fbr_pos_token="fbr_pos_token"
                                        placeholder="e.g. 6baffg121"
                                        value={tillUser.fbr_pos_token || ""}
                                        required
                                        onChange={handlesetTillUser}
                                      />
                                    </div>
          
                                    <div className="mt-3 col-4">
                                      <label htmlFor="fbr_pct_code" className="form-label">
                                        {_t(t("PCT Code"))}{" "}
                                        <small className="text-danger">*</small>
                                      </label>
                                      <input
                                        type="text"
                                        className="form-control "
                                        id="fbr_pct_code"
                                        name="fbr_pct_code"
                                        fbr_pct_code="fbr_pct_code"
                                        placeholder="e.g. 232329"
                                        value={tillUser.fbr_pct_code || ""}
                                        required
                                        onChange={handlesetTillUser}
                                      />
                                    </div>
                                  </div>
                                )} */}

                                {
                                  (tillUser.sbr_integration || tillUser.fbr_integration) && (
                                    <div className="row">
                                      <div className="mt-3 col-3">
                                          <label htmlFor="sbrNtn" className="form-label">
                                          {_t(t(tillUser.sbr_integration ? "NTN" : "POS Registration No"))}{" "}
                                          </label>
                                          <input
                                          type="text"
                                          className="form-control "
                                          id="sbrNtn"
                                          name="sbrNtn"
                                          sbrUserId="sbrNtn"
                                          value={tillUser.sbrNtn || ""}
                                          onChange={handlesetTillUser}
                                          />
                                      </div>
                                      <div className="mt-3 col-3">
                                          <label htmlFor="sbrPos" className="form-label">
                                          {_t(t(tillUser.sbr_integration ? "Pos ID" : "Pos Token"))}{" "}
                                          </label>
                                          <input
                                          type="text"
                                          className="form-control "
                                          id="sbrPos"
                                          name="sbrPos"
                                          value={tillUser.sbrPos || ""}
                                          onChange={handlesetTillUser}
                                          />
                                      </div>  
                                      <div className="mt-3 col-3">
                                          <label htmlFor="sbrUserId" className="form-label">
                                          {_t(t(tillUser.sbr_integration ? "User Id" : "PCT Code"))}{" "}
                                          </label>
                                          <input
                                          type="text"
                                          className="form-control "
                                          id="sbrUserId"
                                          name="sbrUserId"
                                          sbrUserId="sbrUserId"
                                          // placeholder="e.g. 6baffg110-386a-3f83-a8re-ff84f548351"
                                          value={tillUser.sbrUserId || ""}
                                          onChange={handlesetTillUser}
                                          />
                                      </div>
                                      {
                                        tillUser.sbr_integration && (
                                        <div className="mt-3 col-3">
                                            <label
                                            htmlFor="sbrUserPassword"
                                            className="form-label"
                                            >
                                            {_t(t("Password"))}{" "}
                                            </label>
                                            <input
                                            type="password"
                                            className="form-control "
                                            id="sbrUserPassword"
                                            name="sbrUserPassword"
                                            sbrUserPassword="sbrUserPassword"
                                            autoComplete="new-password"
                                            value={tillUser.sbrUserPassword || ""}
                                            onChange={handlesetTillUser}
                                            />
                                        </div>                          
                                        )
                                      }
                                    </div>   
                                  )
                                }
                            

                                <div className="mt-3 d-flex align-items-center">
                                  
                                </div>
        
                                <div className="row mt-4">
                                    <div className="d-flex justify-content-end">
                                        <div className="col-3 d-flex justify-content-end">
                                        <button
                                            type="button"
                                            className="btn rounded hov-btn mr-3"
                                            onClick={()=>(history.push("/dashboard/manage/user/till"))}
                                        >
                                            <i className="fa fa-arrow-left" aria-hidden="true"></i> {_t(t("Back"))}
                                        </button>
                                        <button
                                            type="submit"
                                            className="btn btn-primary rounded"
                                        >
                                        <i className="fa fa-floppy-o" aria-hidden="true"></i> {!tillUser.edit ? _t(t("Save")) : _t(t("Update"))}
                                        </button>
                                        </div>
                                        
                                    </div>
                                </div>
                                </form>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                {/* Rightbar contents end*/}
              </div>
            </div>
          </main>
          {/* main body ends */}
        </>
      );
}

export default UserTillAction;