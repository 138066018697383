import React, { useState, useContext, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import Switch from "react-switch";

//pages & includes
import ManageSidebar from "../ManageSidebar";

//functions
import {
  _t,
  getCookie,
  tableLoading,
} from "../../../../../functions/Functions";
import { useTranslation } from "react-i18next";

//axios and base url
import axios from "axios";
import { BASE_URL } from "../../../../../BaseUrl";

//3rd party packages
import { Helmet } from "react-helmet";
import "react-confirm-alert/src/react-confirm-alert.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Select from "react-select";
import "react-datepicker/dist/react-datepicker.css";
import DatetimeRangePicker from "react-datetime-range-picker";
import Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';



//context consumer
import { RestaurantContext } from "../../../../../contexts/Restaurant";
import { FoodContext } from "../../../../../contexts/Food";
import moment from "moment";


const SchemeCrudAction = () => {
    const { t } = useTranslation();
    const history = useHistory();
    const params = useParams();
    //getting context values here
  
    let {
      //common
      loading,
      setLoading,
      //dept-tag
      deptTagList,
      setDeptTagList,
      deptTagForSearch,
  
      //Scheme
      setSchemeList,
      setSchemeForSearch,
      setSchemeForSearchObj,
      schemeForSearchObj,
      //md accounts
      MdIntegrationForSearch,
      getMdIntegrationGroup,

      branchForSearch,
      orderTypeForSearch,
      // card
      cardForSearch,
  
    } = useContext(RestaurantContext);
  
    const {
      //food
      foodForPOS,
      //food group
      foodGroupForSearch,
      availableFoodGroups,
    } = useContext(FoodContext);
  
  
    // States hook here
    //new dept-tag
    let [newScheme, setNewScheme] = useState({
      id: null,
      name: "",
      fp_offer_name: "",
      how_much_discount: "0",
      party_sharing_discount: "0",
      menu_id: null,
      start_date: new Date(),
      end_date: new Date(),
      open_discount: 0,
      cap_amount: 0,
      account_map_id: null,
      status: true,
      schedule: false,
      time: 0,
      card_required: false,
      edit: false,
      uploading: false,
      item_type: "Item",
      discount_type: "",
      scheme_type: "manual_apply",
      discount_addon: "discount_with_addon",
      discount_tax: 0,
      groups: null,
      items: null,
      fromTime : null,
      toTime : null
    });
    //search result
    let [searchedScheme, setSearchedScheme] = useState({
      list: null,
      searched: false,
    });
  
    let [branch, setBranch] = useState(null);
    let [orderType, setOrderType] = useState(null);
    let [ibansList, setIbansList] = useState(null);
    let [weekDays, setWeekDays] = useState([]);
    let [item, setItem] = useState(null);
    let [group, setGroup] = useState(null);
    let [defaultMenu, setDefaultMenu] = useState(null);
    let [menu, setMenu] = useState(null);
    let [foodGroup, setFoodGroup] = useState([]);
    let [foodItem, setfoodItem] = useState([]);
    let [branches, setBranches] = useState([])
    let [orderTypes, setOrderTypes] = useState([])
  
    //useEffect == componentDidMount
    useEffect(() => {
      let getMenuId = deptTagList.data.filter((item) => {
        return parseInt(item.is_default_menu) === 1;
      });
      setDefaultMenu(getMenuId[0]?.id);

      if(!MdIntegrationForSearch){
        getMdIntegrationGroup();
      }
     
    }, []);

    useEffect(()=>{
      if(params.id && MdIntegrationForSearch){
        const id = parseInt(params.id)
        handleSetEdit(id)
      }
    },[MdIntegrationForSearch])
  
    useEffect(() => {
     
      setfoodItem(foodForPOS)
      setFoodGroup(foodGroupForSearch)
      setBranches(branchForSearch)
      setOrderTypes(orderTypeForSearch)
  
    }, [foodForPOS,foodGroupForSearch,branchForSearch,orderTypeForSearch]);
  
  
    //set name hook
    const handleSetScheme = (e) => {
      setNewScheme({ ...newScheme, [e.target.name]: e.target.value });
    };
  
    const handleSchemeType = (e) => {
      setNewScheme({
        ...newScheme,
        scheme_type: e.target.value,
        cap_amount : 0,
      });
    };
  
    // on change send req
    const handleChangeStatus = (newChecked) => {
      setNewScheme({
        ...newScheme, 
        status: !newScheme.status
      })
    };
    const handleChangeRequiredCard = (newChecked) => {
      setNewScheme({
        ...newScheme, 
        card_required: !newScheme.card_required,
        discount_type: (!newScheme.card_required ? "percentage" : newScheme.discount_type),
        how_much_discount:"0",
        scheme_type: "manual_apply",
      })
    };
    const handleSetItems = (items) => {
      setNewScheme({ ...newScheme, items });
      setItem(items);
    };
  
    const handleSetGroups = (groups) => {
      setNewScheme({ ...newScheme, groups });
      setGroup(groups);
    };
  
    const handleSetBranch = (branch) => {
      setNewScheme({ ...newScheme, branch });
      setBranch(branch);
    };
  
    const handleEmptyMenu = (e) =>{
      setMenu(null);
      setNewScheme({ ...newScheme, menu_id : null });
    }

    const handleSetMenu = (menu) => {
      setMenu(menu);
      setNewScheme({ ...newScheme, menu_id : menu });
      
      let foodItems = foodForPOS;
      let foodGroups = foodGroupForSearch;
      let Branches = branchForSearch;
      let OrderType = orderTypeForSearch;
      if(menu){
        setItem(null)
        setGroup(null)
        setBranch(null);
        setOrderType(null);
         foodItems = foodForPOS.filter((food) => JSON.parse(food.menu_ids).includes(menu.id))
         foodGroups = availableFoodGroups[menu.id];
         Branches = branchForSearch.filter((branch) => JSON.parse(menu.branch_id).includes(branch.id));
         OrderType = orderTypeForSearch.filter((ordertype) => JSON.parse(menu.associate_with).includes(ordertype.id))
      }

        setBranches(Branches)
        setOrderTypes(OrderType)
        setfoodItem(foodItems)
        setFoodGroup(foodGroups)
      
    };
  
    const handleSetOrderType = (orderType) => {
      setNewScheme({ ...newScheme, orderType });
      setOrderType(orderType);
    };
  
    const handleSetCards = (ibansList) => {
      setNewScheme({...newScheme, ibansList})
      setIbansList(ibansList)
    }
  
    const handleChangeRequiredschedule = (newChecked) => {
      setNewScheme({
        ...newScheme, 
        schedule: !newScheme.schedule,
        // discount_type: (!newScheme.schedule ? "percentage" : newScheme.discount_type),
        // how_much_discount:"0"
      })
    };
  
    const handleChangeRequiredTime = () =>{
      setNewScheme({
        ...newScheme, 
        time: newScheme.time == 1 ? 0 : 1,
      })
    }
   
    const handleCheckboxChange = (event) => {
      const day = event.target.value;
      if (event.target.checked) {
        setWeekDays([...weekDays, day]);
      } else {
        setWeekDays(weekDays.filter(selectedDay => selectedDay !== day));
      }
    };
  
    const handleTimeToChange  = (moment) =>{
      setNewScheme({
        ...newScheme,
        toTime: moment
      })
    }
  
    const handleTimeFromChange = (moment) => {
      setNewScheme({
        ...newScheme,
        fromTime: moment
      })
    }

    //set payment type
    const handleSetPayment = (payment) =>{
      setNewScheme({ ...newScheme, account_map_id: payment });
    }
  
    //Save New paymentType
    const handleSaveNewScheme = (e) => {
      e.preventDefault();
      if(newScheme.discount_type === null || newScheme.discount_type === ""){
        toast.error(
          `${_t(t("Please select discount type"))}`,
          {
            position: "bottom-center",
            autoClose: 10000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            className: "text-center toast-notification",
          }
        );
        return false
      }
  
      if(newScheme.item_type==="Item" && (item === null || item?.length === 0)){
        toast.error(
          `${_t(t("Please select items"))}`,
          {
            position: "bottom-center",
            autoClose: 10000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            className: "text-center toast-notification",
          }
        );
        return false
      }

      if(newScheme.item_type==="Group" && (group === null || group?.length === 0)){
        toast.error(
          `${_t(t("Please select group"))}`,
          {
            position: "bottom-center",
            autoClose: 10000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            className: "text-center toast-notification",
          }
        );
        return false
      }
  
      if(newScheme.discount_addon === null || newScheme.discount_addon === ""){
        toast.error(
          `${_t(t("Please select item with discount/ without discount"))}`,
          {
            position: "bottom-center",
            autoClose: 10000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            className: "text-center toast-notification",
          }
        );
        return false
      }
  
      if(branch === null || (branch && branch?.length === 0)){
        toast.error(
          `${_t(t("Please select Branch"))}`,
          {
            position: "bottom-center",
            autoClose: 10000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            className: "text-center toast-notification",
          }
        );
        return false
      }
  
      if(newScheme.time && (newScheme.fromTime === null || newScheme.toTime === null)){
        toast.error(
          `${_t(t("Please select from/to time"))}`,
          {
            position: "bottom-center",
            autoClose: 10000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            className: "text-center toast-notification",
          }
        );
        return false
      }
  
      if(newScheme.time && (newScheme.fromTime === newScheme.toTime)){
        toast.error(
          `${_t(t("Please form to time can't be equal"))}`,
          {
            position: "bottom-center",
            autoClose: 10000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            className: "text-center toast-notification",
          }
        );
        return false
      }
  
      if(orderType === null || (orderType && orderType?.length === 0)){
        toast.error(
          `${_t(t("Please select order type"))}`,
          {
            position: "bottom-center",
            autoClose: 10000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            className: "text-center toast-notification",
          }
        );
        return false
      }
  
      // setLoading(true)
      console.log("Inert ", newScheme, group, item, orderType, branch);
      let tempbranch = [];
      let tempOrderType = [];
      let tempIbansList = [];
      let tempGroups = [];
      let tempItems = [];
     
  
      branch.map((Item) => {
        tempbranch.push(Item.id);
      });
  
      orderType.map((Item) => {
        tempOrderType.push(Item.id);
      });
  
      if (newScheme.card_required) {
        if( ibansList !== null && ibansList.length > 0){
          ibansList.forEach(iban => {
            tempIbansList.push(iban.id)
          })
        }else{
          setLoading(false)
          toast.error(
            `${_t(t("Please select IBAN numbers"))}`,
            {
              position: "bottom-center",
              autoClose: 10000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              className: "text-center toast-notification",
            }
          );
          return false;
        }
      }
  
      if (newScheme.item_type === "Group") {
        group.map((Item) => {
          tempGroups.push(Item.id);
        });
      } else {
        item.map((Item) => {
          tempItems.push(Item.id);
        });
      }
      const accountId = newScheme.account_map_id ? newScheme.account_map_id.id : "";
      setLoading(true)
      const foodGroupUrl = BASE_URL + `/settings/new-schemes`;
      let formData = new FormData();
      formData.append("name", newScheme.name);
      formData.append("discount_type", newScheme.discount_type);
      formData.append("fp_offer_name", newScheme.fp_offer_name);
      formData.append("how_much_discount", newScheme.how_much_discount);
      formData.append("party_sharing_discount", newScheme.party_sharing_discount);
      formData.append("start_date", newScheme.start_date);
      formData.append("end_date", newScheme.end_date);
      formData.append("item_type", newScheme.item_type);
      formData.append("scheme_type", newScheme.scheme_type);
      formData.append("discount_addon", newScheme.discount_addon);
      formData.append("discount_tax", newScheme.discount_tax);
      formData.append("account_map_id", accountId);
      //formData.append("menu_id", newScheme.menu_id);
      //formData.append("menu_name", newScheme.menu_name);
      
      let fromTime = null;
      let toTime = null;
      if(newScheme.time){
        fromTime = newScheme.fromTime.format('HH:mm');
        toTime = newScheme.toTime.format('HH:mm');
      }
      formData.append("time_schedule",newScheme.time);
      formData.append("from_time",fromTime);
      formData.append("to_time",toTime);
      formData.append("groups_id", tempGroups);
      formData.append("items_id", tempItems);
      formData.append("branch_id", tempbranch);
      formData.append("menu_id", newScheme.menu_id ? newScheme.menu_id.id : 0);
      formData.append("order_type_id", tempOrderType);
      formData.append("cap_amount", newScheme.cap_amount);
      if (newScheme.card_required) {
        formData.append("card_info_ids", tempIbansList);
      }
      formData.append("status", newScheme.status);
      formData.append("card_required", newScheme.card_required === true ? 1 : 0);
      formData.append("open_discount", newScheme.open_discount);
      let weekDay = JSON.stringify(weekDays);
      formData.append("schedule", weekDay);
  
      return axios
        .post(foodGroupUrl, formData, {
          headers: { Authorization: `Bearer ${getCookie()}` },
        })
        .then((res) => {
          setNewScheme({
            name: "",
            fp_offer_name: "",
            how_much_discount: "0",
            party_sharing_discount: "0",
            menu_id: null,
            open_discount:0,
            cap_amount: 0,
            account_map_id: null,
            start_date: new Date(),
            end_date: new Date(),
            status: true,
            schedule: false,
            time: 0,
            card_required: false,
            edit: false,
            uploading: false,
            discount_type: false,
            fromTime : null,
            toTime : null,
            item_type: "Item",
            scheme_type: "manual_apply",
            discount_addon: "discount_with_addon",
            discount_tax: 0,
          });
          //setAssociateWith([]);
          //setMenu(null);
          setSchemeList(res.data[0]);
          setSchemeForSearch(res.data[1]);
          const obj = {};
          res.data[1]?.length && res.data[1].map((scheme) => {
            obj[parseInt(scheme.id)] = scheme;
          });
          setSchemeForSearchObj(obj);
          setWeekDays([]);
          setSearchedScheme({
            ...searchedScheme,
            list: res.data[1],
          });
          setBranch([]);
          setGroup([]);
          setItem([]);
          setOrderType([]);
          setIbansList([]);
          setLoading(false);
  
          toast.success(`${_t(t("Scheme has been added"))}`, {
            position: "bottom-center",
            autoClose: 10000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            className: "text-center toast-notification",
          });
          history.push(`/dashboard/manage/food/add-schemes`)      
        })
        .catch((error) => {
          setLoading(false);
          setNewScheme({
            ...newScheme,
            uploading: false,
          });
          if (error.response.data.errors) {
            if (error.response.data.errors.name) {
              error.response.data.errors.name.forEach((item) => {
                if (item === "A menu already exists with this name") {
                  toast.error(
                    `${_t(t("A menu already exists with this name"))}`,
                    {
                      position: "bottom-center",
                      autoClose: 10000,
                      hideProgressBar: false,
                      closeOnClick: true,
                      pauseOnHover: true,
                      className: "text-center toast-notification",
                    }
                  );
                }
              });
            } else {
              toast.error(`${_t(t("Please try again"))}`, {
                position: "bottom-center",
                autoClose: 10000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                className: "text-center toast-notification",
              });
            }
          }
        });
    };
  
    const handleDiscountType = (e) => {
      setNewScheme({
        ...newScheme,
        discount_type: e.target.value,
        cap_amount: e.target.value === "bogo" ? 0 : newScheme.cap_amount,
        card_required: e.target.value === "bogo" ? false : newScheme.card_required,
        scheme_type : "manual_apply",
        fp_offer_name: "",
        how_much_discount:"0",
        party_sharing_discount:"0",
      });
    };
    
    const handleOpenDiscount=(e)=>{
      setNewScheme({
        ...newScheme,
        open_discount: parseInt(newScheme.open_discount) === 1 ? 0 : 1,
        discount_type: parseInt(newScheme.open_discount) === 1 ? "" : "fixed",
        cap_amount: 0,
        scheme_type : "manual_apply",
        fp_offer_name: "",
        how_much_discount:"0",
        party_sharing_discount:"0",
      })
    }
  
    //set edit true & values
    const handleSetEdit = (id) => {
      const schemes = schemeForSearchObj[id];
  
      let selectOptionsBranch = [];
      const arrBranch = JSON.parse(schemes.branch_id);
      if (arrBranch.length !== 0) {
        for (var property of arrBranch) {
          for (var i = 0; i < branchForSearch.length; i++) {
            if (branchForSearch[i].id == property) {
              selectOptionsBranch.push(branchForSearch[i]);
            }
          }
          setBranch(selectOptionsBranch);
        }
      } else {
        setBranch([]);
      }
  
      let selectOptionsOrderType = [];
      const arrOrderType = JSON.parse(schemes.order_type_id);
      if (arrOrderType.length !== 0) {
        for (var property of arrOrderType) {
          for (var i = 0; i < orderTypeForSearch.length; i++) {
            if (orderTypeForSearch[i].id == property) {
              selectOptionsOrderType.push(orderTypeForSearch[i]);
            }
          }
          setOrderType(selectOptionsOrderType);
        }
      } else {
        setOrderType([]);
      }

      const account_map_id = schemes?.account_map_id ? MdIntegrationForSearch.find((item) => item.id === schemes.account_map_id) : null; 
      // let selectOptionsMenu = [];
      // const arrMenu = JSON.parse(schemes[0].menu_id);
      // if (arrMenu.length !== 0) {
      //   for (var menu of arrMenu) {
      //     for (var i = 0; i < deptTagForSearch.length; i++) {
      //       if (deptTagForSearch[i].id == menu) {
      //         selectOptionsMenu.push(deptTagForSearch[i]);
      //       }
      //     }
      //     setMenu(selectOptionsMenu);
      //   }
      // } else {
      //   setMenu([]);
      // }
  
      const selectOptionsIbans = [];
      const ibansArray = JSON.parse(schemes.card_info_ids)
      if (ibansArray && ibansArray.length) {
        for (const cardInfo of cardForSearch) {
          if (ibansArray.includes(cardInfo.id)) selectOptionsIbans.push(cardInfo)
        }
      }
      setIbansList(selectOptionsIbans)
  
      if (schemes.item_type === "Group") {
        let selectOptionsGroup = [];
        const arrGroups = JSON.parse(schemes.groups_id);
        if (arrGroups.length !== 0) {
          for (var property of arrGroups) {
            for (var i = 0; i < foodGroupForSearch.length; i++) {
              if (foodGroupForSearch[i].id == property) {
                selectOptionsGroup.push(foodGroupForSearch[i]);
              }
            }
            setGroup(selectOptionsGroup);
          }
        } else {
          setGroup([]);
        }
      } else {
        let selectOptionsItems = [];
        const arrItems = JSON.parse(schemes.items_id);
        if (arrItems.length !== 0) {
          for (var property of arrItems) {
            for (var i = 0; i < foodForPOS.length; i++) {
              if (foodForPOS[i].id == property) {
                selectOptionsItems.push(foodForPOS[i]);
              }
            }
            setItem(selectOptionsItems);
          }
        } else {
          setItem([]);
        }
      }
      let selectedDept;
      if (schemes.menu_id) {
        const menu_id = parseInt(schemes.menu_id);
         selectedDept = deptTagForSearch.find((item) => {
          return item.id === menu_id
        });
        setMenu(selectedDept);
      }
      setWeekDays(JSON.parse(schemes.schedule));
      // Combine the time with a date (e.g., today's date) and parse it into a moment object
  
      // Specify the time format when parsing
      let fromTime = null;
      let toTime = null;
      if(schemes.time_schedule){
        fromTime =  moment(schemes.from_time, 'HH:mm');
        toTime = moment(schemes.to_time, 'HH:mm');     
      }
      setNewScheme({
        ...newScheme,
        id: schemes.id,
        name: schemes.name,
        discount_type: schemes.discount_type,
        item_type: schemes.item_type,
        how_much_discount: schemes.how_much_discount,
        fp_offer_name: schemes.fp_offer_name,
        open_discount: parseInt(schemes.open_discount),
        party_sharing_discount: schemes.party_sharing_discount,
        menu_id: selectedDept,
        start_date: schemes.start_date,
        end_date: schemes.end_date,
        time : parseInt(schemes.time_schedule),
        status: schemes.status == '1' ? true : false,
        schedule: schemes.schedule && JSON.parse(schemes.schedule)?.length > 0 ? true : false,
        card_required: schemes.card_required === 1 ? true : false,
        discount_addon: schemes.discount_addon,
        discount_tax: parseInt(schemes.discount_tax),
        cap_amount: parseFloat(schemes.cap_amount),
        account_map_id: account_map_id,
        scheme_type: schemes.scheme_type,
        edit: true,
        fromTime : fromTime,
        toTime : toTime
      });
  
    };
  
    //update food group
    const handleUpdateScheme = (e) => {
      e.preventDefault();
  
      if(newScheme.discount_type === null || newScheme.discount_type === ""){
        toast.error(
          `${_t(t("Please select discount type"))}`,
          {
            position: "bottom-center",
            autoClose: 10000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            className: "text-center toast-notification",
          }
        );
        return false
      }

      if(newScheme.item_type==="Item" && (item === null || item?.length === 0)){
        toast.error(
          `${_t(t("Please select items"))}`,
          {
            position: "bottom-center",
            autoClose: 10000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            className: "text-center toast-notification",
          }
        );
        return false
      }

      if(newScheme.item_type==="Group" && (group === null || group?.length === 0)){
        toast.error(
          `${_t(t("Please select group"))}`,
          {
            position: "bottom-center",
            autoClose: 10000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            className: "text-center toast-notification",
          }
        );
        return false
      }
  
      if(newScheme.discount_addon === null || newScheme.discount_addon === ""){
        toast.error(
          `${_t(t("Please select item with discount/ without discount"))}`,
          {
            position: "bottom-center",
            autoClose: 10000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            className: "text-center toast-notification",
          }
        );
        return false
      }
      
      if(branch === null || (branch && branch?.length === 0)){
        toast.error(
          `${_t(t("Please select Branch"))}`,
          {
            position: "bottom-center",
            autoClose: 10000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            className: "text-center toast-notification",
          }
        );
        return false
      }
  
      if(newScheme.time && (newScheme.fromTime === null || newScheme.toTime === null)){
        toast.error(
          `${_t(t("Please select from/to time"))}`,
          {
            position: "bottom-center",
            autoClose: 10000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            className: "text-center toast-notification",
          }
        );
        return false
      }
  
      if(newScheme.time && (newScheme.fromTime === newScheme.toTime)){
        toast.error(
          `${_t(t("Please form to time can't be equal"))}`,
          {
            position: "bottom-center",
            autoClose: 10000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            className: "text-center toast-notification",
          }
        );
        return false
      }
  
      if(orderType === null || (orderType && orderType?.length === 0)){
        toast.error(
          `${_t(t("Please select order type"))}`,
          {
            position: "bottom-center",
            autoClose: 10000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            className: "text-center toast-notification",
          }
        );
        return false
      }
  
      // setNewScheme({
      //   ...newScheme,
      //   uploading: true,
      // });
    //  setLoading(true);
      console.log("update ", newScheme, group, item, orderType, branch);
      let tempbranch = [];
      let tempOrderType = [];
      let tempIbansList = [];
      let tempGroups = [];
      let tempItems = [];
  
      branch.map((Item) => {
        tempbranch.push(Item.id);
      });
  
      orderType.map((Item) => {
        tempOrderType.push(Item.id);
      });
  
      if (newScheme.card_required) {
        if( ibansList !== null && ibansList.length > 0){
          ibansList.forEach(iban => {
            tempIbansList.push(iban.id)
          })
        }else{
          setLoading(false)
          toast.error(
            `${_t(t("Please select IBAN numbers"))}`,
            {
              position: "bottom-center",
              autoClose: 10000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              className: "text-center toast-notification",
            }
          );
          return false
        }
      }
  
      if (newScheme.item_type === "Group") {
        group.map((Item) => {
          tempGroups.push(Item.id);
        });
      } else {
        item.map((Item) => {
          tempItems.push(Item.id);
        });
      }
      setNewScheme({
        ...newScheme,
        uploading: true,
      });
      const accountId = newScheme.account_map_id ? newScheme?.account_map_id.id : "";
      setLoading(true);
      const foodGroupUrl = BASE_URL + `/settings/update-schemes`;
      let formData = new FormData();
  
      formData.append("id", newScheme.id);
      formData.append("name", newScheme.name);
      formData.append("discount_type", newScheme.discount_type);
      formData.append("fp_offer_name", newScheme.fp_offer_name);
      formData.append("how_much_discount", newScheme.how_much_discount);
      formData.append("party_sharing_discount", newScheme.party_sharing_discount);
      formData.append("start_date", newScheme.start_date);
      formData.append("end_date", newScheme.end_date);
      formData.append("item_type", newScheme.item_type);
      formData.append("scheme_type", newScheme.scheme_type);
      formData.append("menu_id", newScheme.menu_id ? newScheme.menu_id.id : 0);
      formData.append("discount_addon", newScheme.discount_addon);
      formData.append("discount_tax", newScheme.discount_tax);
      formData.append("account_map_id", accountId);
      //formData.append("menu_name", newScheme.menu_name);
      formData.append("groups_id", tempGroups);
      formData.append("items_id", tempItems);
      formData.append("branch_id", tempbranch);
      formData.append("order_type_id", tempOrderType);
      let fromTime = null;
      let toTime = null;
      if(newScheme.time){
        fromTime = newScheme.fromTime.format('HH:mm');
        toTime = newScheme.toTime.format('HH:mm');
      }
      formData.append("time_schedule",newScheme.time)
      formData.append("from_time",fromTime);
      formData.append("to_time",toTime);
      formData.append("cap_amount", newScheme.cap_amount);
      if (newScheme.card_required) {
        formData.append("card_info_ids", tempIbansList);
      }
      formData.append("status", newScheme.status);
      formData.append("card_required", newScheme.card_required === true ? 1 : 0);
      formData.append("open_discount", newScheme.open_discount);
      let weekDay = JSON.stringify(weekDays);
      formData.append("schedule", weekDay);
  
      return axios
        .post(foodGroupUrl, formData, {
          headers: { Authorization: `Bearer ${getCookie()}` },
        })
        .then((res) => {
          setNewScheme({
            name: "",
            how_much_discount: "0",
            fp_offer_name: "",
            party_sharing_discount: "0",
            menu_id: null,
            open_discount:0,
            cap_amount: 0,
            account_map_id: null,
            start_date: new Date(),
            end_date: new Date(),
            time: 0,
            schedule: false,
            card_required: false,
            scheme_type: "manual_apply",
            item_type: "Item",
            discount_addon: "discount_with_addon",
            discount_tax: 0,
            status: true,
            edit: false,
            uploading: false,
            fromTime : null,
            toTime : null
          });
          
          // setAssociateWith([]);
          setMenu(null);
          setDeptTagList(res.data[0]);
          setWeekDays([]);
          setSchemeForSearch(res.data[1]);
          const obj = {};
          res.data[1]?.length && res.data[1].map((scheme) => {
            obj[parseInt(scheme.id)] = scheme;
          });
          setSchemeForSearchObj(obj);
          setSearchedScheme({
            ...searchedScheme,
            list: res.data[1],
          });
          setBranch([]);
          setGroup([]);
          setItem([]);
          setOrderType([]);
          setIbansList([]);
          setLoading(false);
          toast.success(`${_t(t("Scheme has been updated"))}`, {
            position: "bottom-center",
            autoClose: 10000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            className: "text-center toast-notification",
          });
          history.push(`/dashboard/manage/food/add-schemes`);
        })
        .catch((error) => {
          setLoading(false);
          setNewScheme({
            ...newScheme,
            uploading: false,
          });
          if (error.response.data.errors) {
            if (error.response.data.errors.name) {
              error.response.data.errors.name.forEach((item) => {
                if (item === "A Menu already exists with this name") {
                  toast.error(
                    `${_t(t("A Menu already exists with this name"))}`,
                    {
                      position: "bottom-center",
                      autoClose: 10000,
                      hideProgressBar: false,
                      closeOnClick: true,
                      pauseOnHover: true,
                      className: "text-center toast-notification",
                    }
                  );
                }
              });
            } else {
              toast.error(`${_t(t("Please try again"))}`, {
                position: "bottom-center",
                autoClose: 10000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                className: "text-center toast-notification",
              });
            }
          }
        });
    };
  
  
    const handleDefaultMenu = (menu_id) => {
      setDefaultMenu(menu_id);
  
      const foodGroupUrl = BASE_URL + `/settings/update-default-menu`;
  
      let formData = new FormData();
  
      formData.append("id", menu_id);
  
      return axios
        .post(foodGroupUrl, formData, {
          headers: { Authorization: `Bearer ${getCookie()}` },
        })
        .then((res) => {
          setLoading(false);
          toast.success(
            `${_t(t("The menu has been set successfully on by default"))}`,
            {
              position: "bottom-center",
              autoClose: 10000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              className: "text-center toast-notification",
            }
          );
        });
    };
  
    return (
      <>
        <Helmet>
          <title>{_t(t("Offers"))}</title>
        </Helmet>
  
        {/* main body */}
            <main id="main" data-simplebar>
                <div className="container-fluid">
                    <div className="row t-mt-10 gx-2">
                        {/* left Sidebar */}
                        <div className="col-lg-2 col-xxl-2 t-mb-30 mb-lg-0">
                            <ManageSidebar />
                        </div>
                        {/* left Sidebar ends */}

                        {/* Rightbar contents */}
                        <div className="col-lg-10 col-xxl-10 t-mb-30 mb-lg-0">
                            <div className="t-bg-white">
                                <div className="fk-scroll--pos-menu" data-simplebar>
                                    <div className="t-pl-15 t-pr-15">
                                        {/* Loading effect */}
                                        {newScheme.uploading === true || loading === true ? (
                                            tableLoading()
                                        ) : (
                                            <div key="fragment3">
                                                {/* next page data spin loading */}
                                                {/* <div className={`${dataPaginating && "loading"}`}></div> */}
                                                {/* spin loading ends */}

                                                <div className="row gx-2 align-items-center t-pt-15 t-pb-15">
                                                    <div className="col-md-6 col-lg-5 t-mb-15 mb-md-0">
                                                        <ul className="t-list fk-breadcrumb">
                                                            <li className="fk-breadcrumb__list">
                                                                <span className="t-link fk-breadcrumb__link text-capitalize">
                                                                    {!newScheme.edit
                                                                        ? _t(t("Add new offer"))
                                                                        : _t(t("Update offer"))}
                                                                </span>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                    <div className="col-md-6 col-lg-7">
                                                        <div className="row gx-3 align-items-center">
                                                        </div>
                                                    </div>
                                                </div>

                                                <div key="fragment-dept-tag-1">
                                                    <form
                                                        onSubmit={
                                                            !newScheme.edit
                                                                ? handleSaveNewScheme
                                                                : handleUpdateScheme
                                                        }
                                                        autoComplete="off"
                                                        className="row card p-2 mx-3 mb-5 new-text-style"
                                                    >
                                                        <div className="row">
                                                            <div className="col-6 mt-1">
                                                                <label htmlFor="name" className="form-label">
                                                                    {_t(t("Offer Name"))}{" "}
                                                                    <small className="text-danger">*</small>
                                                                </label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control "
                                                                    id="name"
                                                                    name="name"
                                                                    placeholder="e.g. Cricket Mania, Ramadan Deals etc"
                                                                    value={newScheme.name || ""}
                                                                    required
                                                                    onChange={handleSetScheme}
                                                                />
                                                            </div>
                                                            <div className="mt-1 col-md-6">
                                                                <label
                                                                    htmlFor="fp_offer_name"
                                                                    className="form-label"
                                                                >
                                                                    {_t(t("Foodpanda offer name"))}
                                                                </label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control "
                                                                    id="fp_offer_name"
                                                                    name="fp_offer_name"
                                                                    placeholder=""
                                                                    value={newScheme.fp_offer_name || ""}
                                                                    onChange={handleSetScheme}
                                                                />
                                                            </div>
                                                        </div>

                                                        <div className="row">
                                                            <div className="col-12 mt-1">
                                                            <div className="addons-list__item mt-3 mx-1 mb-1 fk-addons-table__body">
                                                            <div className="mb-2">
                                                                <label className="control-label">
                                                                    {_t(t("Discount Type"))}
                                                                </label>
                                                            </div>
                                                                <div className="d-flex align-items-center">
                                                                    <div className="mx-checkbox mr-4">
                                                                        <input
                                                                            type="radio"
                                                                            className="mx-checkbox__input mx-checkbox__input-solid mx-checkbox__input-solid--danger mx-checkbox__input-sm"
                                                                            id="discount_type_fixed"
                                                                            name="discount_type"
                                                                            value="fixed"
                                                                            onChange={(e) => {
                                                                                handleDiscountType(e);
                                                                            }}
                                                                            checked={newScheme.discount_type === "fixed" ? 1 : 0}
                                                                            disabled={newScheme.card_required}
                                                                        />

                                                                        <label htmlFor="discount_type_fixed" className="mx-checkbox__text text-capitalize t-text-heading t-ml-8 fk-addons-table__body-text" >
                                                                            {_t(t("Fixed Amount"))}
                                                                        </label>
                                                                    </div>

                                                                    <div className="mx-checkbox mr-4">
                                                                        <input
                                                                            disabled={newScheme.open_discount}
                                                                            type="radio"
                                                                            className="mx-checkbox__input mx-checkbox__input-solid mx-checkbox__input-solid--danger mx-checkbox__input-sm"
                                                                            id="discount_type_percentage"
                                                                            name="discount_type"
                                                                            value="percentage"
                                                                            onChange={(e) => {
                                                                                handleDiscountType(e);
                                                                            }}
                                                                            checked={
                                                                                newScheme.discount_type === "percentage" ? 1 : 0
                                                                            }
                                                                        />

                                                                        <label htmlFor="discount_type_percentage" className="mx-checkbox__text text-capitalize t-text-heading t-ml-8 fk-addons-table__body-text " >
                                                                            {_t(t("Percentage"))}
                                                                        </label>
                                                                    </div>
                                                                    <div className="mx-checkbox mr-4">
                                                                        <input
                                                                            disabled={newScheme.open_discount}
                                                                            type="radio"
                                                                            className="mx-checkbox__input mx-checkbox__input-solid mx-checkbox__input-solid--danger mx-checkbox__input-sm"
                                                                            id="discount_type_bogo"
                                                                            name="discount_type"
                                                                            value="bogo"
                                                                            onChange={(e) => {
                                                                                handleDiscountType(e);
                                                                            }}
                                                                            checked={
                                                                                newScheme.discount_type === "bogo" ? 1 : 0
                                                                            }
                                                                        />
                                                                        <label htmlFor="discount_type_bogo" className="mx-checkbox__text text-capitalize t-text-heading t-ml-8 fk-addons-table__body-text" >
                                                                            {_t(t("Bogo Scheme"))}
                                                                        </label>
                                                                    </div>

                                                                    <div className="mx-checkbox mr-4" >
                                                                        <input
                                                                            type="checkbox"
                                                                            className="mx-checkbox__input mx-checkbox__input-solid mx-checkbox__input-solid--danger mx-checkbox__input-sm"
                                                                            id="discount_type_open"
                                                                            name="open_discount"
                                                                            value="open_discount"                                                                            
                                                                            onChange={(e) => handleOpenDiscount(e)}
                                                                            checked={parseInt(newScheme.open_discount) === 1}
                                                                            disabled={newScheme.card_required}
                                                                        />

                                                                        <label htmlFor="discount_type_open" className="mx-checkbox__text text-capitalize t-text-heading t-ml-8 fk-addons-table__body-text" >
                                                                            {_t(t("Open Discount"))}
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                        </div>
                                                            </div>                                                            
                                                        </div>

                                                        {/*customer comission option: before tax after tax */}
                                                        {/* <div className="addons-list__item mt-3 mx-1 mb-1 fk-addons-table__body">
                                                            <div className="mb-2">
                                                                <label className="control-label">
                                                                    {_t(t("Discount Type"))}
                                                                </label>
                                                            </div>
                                                                <div className="d-flex align-items-center">
                                                                    <div className="mx-checkbox mr-4">
                                                                        <input
                                                                            type="radio"
                                                                            className="mx-checkbox__input mx-checkbox__input-solid mx-checkbox__input-solid--danger mx-checkbox__input-sm"
                                                                            id="discount_type_fixed"
                                                                            name="discount_type"
                                                                            value="fixed"
                                                                            onChange={(e) => {
                                                                                handleDiscountType(e);
                                                                            }}
                                                                            checked={newScheme.discount_type === "fixed" ? 1 : 0}
                                                                            disabled={newScheme.card_required}
                                                                        />

                                                                        <label htmlFor="discount_type_fixed" className="mx-checkbox__text text-capitalize t-text-heading t-ml-8 fk-addons-table__body-text" >
                                                                            {_t(t("Fixed Amount"))}
                                                                        </label>
                                                                    </div>

                                                                    <div className="mx-checkbox mr-4">
                                                                        <input
                                                                            disabled={newScheme.open_discount}
                                                                            type="radio"
                                                                            className="mx-checkbox__input mx-checkbox__input-solid mx-checkbox__input-solid--danger mx-checkbox__input-sm"
                                                                            id="discount_type_percentage"
                                                                            name="discount_type"
                                                                            value="percentage"
                                                                            onChange={(e) => {
                                                                                handleDiscountType(e);
                                                                            }}
                                                                            checked={
                                                                                newScheme.discount_type === "percentage" ? 1 : 0
                                                                            }
                                                                        />

                                                                        <label htmlFor="discount_type_percentage" className="mx-checkbox__text text-capitalize t-text-heading t-ml-8 fk-addons-table__body-text " >
                                                                            {_t(t("Percentage"))}
                                                                        </label>
                                                                    </div>
                                                                    <div className="mx-checkbox mr-4">
                                                                        <input
                                                                            disabled={newScheme.open_discount}
                                                                            type="radio"
                                                                            className="mx-checkbox__input mx-checkbox__input-solid mx-checkbox__input-solid--danger mx-checkbox__input-sm"
                                                                            id="discount_type_bogo"
                                                                            name="discount_type"
                                                                            value="bogo"
                                                                            onChange={(e) => {
                                                                                handleDiscountType(e);
                                                                            }}
                                                                            checked={
                                                                                newScheme.discount_type === "bogo" ? 1 : 0
                                                                            }
                                                                        />
                                                                        <label htmlFor="discount_type_bogo" className="mx-checkbox__text text-capitalize t-text-heading t-ml-8 fk-addons-table__body-text" >
                                                                            {_t(t("Bogo Scheme"))}
                                                                        </label>
                                                                    </div>

                                                                    <div className="mx-checkbox mr-4" >
                                                                        <input
                                                                            type="checkbox"
                                                                            className="mx-checkbox__input mx-checkbox__input-solid mx-checkbox__input-solid--danger mx-checkbox__input-sm"
                                                                            id="discount_type_open"
                                                                            name="open_discount"
                                                                            value="open_discount"                                                                            
                                                                            onChange={(e) => handleOpenDiscount(e)}
                                                                            checked={parseInt(newScheme.open_discount) === 1}
                                                                            disabled={newScheme.card_required}
                                                                        />

                                                                        <label htmlFor="discount_type_open" className="mx-checkbox__text text-capitalize t-text-heading t-ml-8 fk-addons-table__body-text" >
                                                                            {_t(t("Open Discount"))}
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                        </div> */}
                                                        <div className="row d-flex justify-content-between" style={{width:"100%"}}>
                                                            <div className="" style={{width:"50%"}}>
                                                                <div className="row">
                                                                    <div className="mt-3 col-md-6">
                                                                        <label
                                                                            htmlFor="from_date"
                                                                            className="form-label"
                                                                        >
                                                                            {_t(t("From Date"))}
                                                                            <small className="text-danger">*</small>
                                                                        </label>                                                                
                                                                    </div>

                                                                    <div className="mt-3 col-md-6">
                                                                        <label
                                                                            htmlFor="to_date"
                                                                            className="form-label"
                                                                        >
                                                                            {_t(t("To date"))}
                                                                            <small className="text-danger">*</small>
                                                                        </label>
                                                                        
                                                                    </div>
                                                                </div>
                                                                <div className="row">
                                                                    <div className="col-12">
                                                                        <DatetimeRangePicker
                                                                            className="d-flex custom-2-field row react-datetime-range-picker"
                                                                            // style={datePickerStyle}                                                    
                                                                            closeOnSelect={true}
                                                                            startDate={newScheme.start_date || new Date()}
                                                                            endDate={newScheme.end_date || new Date()}
                                                                            onChange={(e) => {
                                                                                setNewScheme({
                                                                                    ...newScheme,
                                                                                    start_date: e.start,
                                                                                    end_date: e.end,
                                                                                });
                                                                            }}                                                            
                                                                        />
                                                                </div>
                                                                </div>
                                                            </div>
                                                            <div style={{width:"50%"}}>
                                                                <div className="row">
                                                                <div className="mt-3 col-md-6">
                                                                    <label
                                                                        htmlFor="how_much_discount"
                                                                        className="form-label"
                                                                    >
                                                                        {_t(t("How much discount"))}
                                                                        <small className="text-danger">*</small>
                                                                    </label>
                                                                    <input
                                                                        type="number"
                                                                        step="0.01"
                                                                        className="form-control "
                                                                        id="how_much_discount"
                                                                        name="how_much_discount"
                                                                        min="0"
                                                                        onKeyDown={(evt) =>
                                                                          ["e", "E", "+", "-", "ArrowUp", "ArrowDown"].includes(evt.key) &&
                                                                          evt.preventDefault()
                                                                        }
                                                                        onWheel={(e) => e.target.blur()}
                                                                        placeholder=""
                                                                        required
                                                                        value={newScheme.how_much_discount || ""}
                                                                        onChange={handleSetScheme}
                                                                        disabled={newScheme.card_required || newScheme.discount_type === "bogo" || newScheme.open_discount}
                                                                    />
                                                                </div>
                                                                <div className="mt-3 col-md-6">
                                                                    <label
                                                                        htmlFor="party_sharing_discount"
                                                                        className="form-label"
                                                                    >
                                                                        {_t(t("Sharing with party on discount"))}
                                                                        <small className="text-danger">*</small>
                                                                    </label>
                                                                    <input
                                                                        disabled={newScheme.discount_type === "bogo" || newScheme.open_discount}
                                                                        type="number"
                                                                        step="0.01"
                                                                        onKeyDown={(evt) =>
                                                                          ["e", "E", "+", "-", "ArrowUp", "ArrowDown"].includes(evt.key) &&
                                                                          evt.preventDefault()
                                                                        }
                                                                        onWheel={(e) => e.target.blur()}
                                                                        className="form-control "
                                                                        id="party_sharing_discount"
                                                                        name="party_sharing_discount"
                                                                        min="0"
                                                                        placeholder=""
                                                                        value={newScheme.party_sharing_discount || ""}
                                                                        required
                                                                        onChange={handleSetScheme}
                                                                    />
                                                                </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        
                                                        {/* <div className="row">
                                                            <div className="mt-3 col-md-6">
                                                                <label
                                                                    htmlFor="how_much_discount"
                                                                    className="form-label"
                                                                >
                                                                    {_t(t("How much discount"))}
                                                                    <small className="text-danger">*</small>
                                                                </label>
                                                                <input
                                                                    type="number"
                                                                    step="0.01"
                                                                    className="form-control "
                                                                    id="how_much_discount"
                                                                    name="how_much_discount"
                                                                    min="0"
                                                                    placeholder=""
                                                                    required
                                                                    value={newScheme.how_much_discount || ""}
                                                                    onChange={handleSetScheme}
                                                                    disabled={newScheme.card_required || newScheme.discount_type === "bogo" || newScheme.open_discount}
                                                                />
                                                            </div>
                                                            <div className="mt-3 col-md-6">
                                                                <label
                                                                    htmlFor="party_sharing_discount"
                                                                    className="form-label"
                                                                >
                                                                    {_t(t("Sharing with party on discount"))}
                                                                    <small className="text-danger">*</small>
                                                                </label>
                                                                <input
                                                                    disabled={newScheme.discount_type === "bogo" || newScheme.open_discount}
                                                                    type="number"
                                                                    step="0.01"
                                                                    className="form-control "
                                                                    id="party_sharing_discount"
                                                                    name="party_sharing_discount"
                                                                    min="0"
                                                                    placeholder=""
                                                                    value={newScheme.party_sharing_discount || ""}
                                                                    required
                                                                    onChange={handleSetScheme}
                                                                />
                                                            </div>
                                                        </div> */}
                                                        <div className="row mt-3">
                                                          <div className="col-6">
                                                            <label htmlFor="Groups" className="form-label">
                                                                {_t(t("Menu"))}
                                                            </label>
                                                            <Select
                                                              value={menu}
                                                              options={deptTagForSearch}
                                                              getOptionLabel={(option) => option.name}
                                                              getOptionValue={(option) => option.id}
                                                              isClearable
                                                              classNamePrefix="select"
                                                              maxMenuHeight="200px"
                                                              closeMenuOnSelect={true}
                                                              onChange={handleSetMenu}
                                                              placeholder={_t(t("Please select menu")) + ".."}
                                                                
                                                            />
                                                          </div>

                                                          <div className="addons-list__item mt-3 mb-1 fk-addons-table__body col-6">
                                                            <label className="form-label d-block mb-0">
                                                              {_t(t("Please Select"))}{" "}
                                                              <small className="text-danger">*</small>
                                                            </label>
                                                            <label className="mx-checkbox d-inline-block mr-3">
                                                              <input
                                                                type="radio"
                                                                value="0"
                                                                className="mx-checkbox__input mx-checkbox__input-solid mx-checkbox__input-solid--danger mx-checkbox__input-sm"
                                                                name="discount_tax"
                                                                onChange={(e) => {
                                                                  setNewScheme({
                                                                    ...newScheme,
                                                                    discount_tax: parseInt(e.target.value),
                                                                  });
                                                                }}
                                                                checked={!newScheme.discount_tax}
                                                              />
                                                              <span className="mx-checkbox__text text-capitalize t-text-heading t-ml-8 fk-addons-table__body-text sm-text">
                                                                Discount Before Tax
                                                              </span>
                                                            </label>
                                                            <label className="mx-checkbox d-inline-block mr-3">
                                                              <input
                                                                type="radio"
                                                                value="1"
                                                                className="mx-checkbox__input mx-checkbox__input-solid mx-checkbox__input-solid--danger mx-checkbox__input-sm"
                                                                name="discount_tax"
                                                                onChange={(e) => {
                                                                  setNewScheme({
                                                                    ...newScheme,
                                                                    discount_tax: parseInt(e.target.value),
                                                                  });
                                                                }}
                                                                checked={newScheme.discount_tax}
                                                              />
                                                              <span className="mx-checkbox__text text-capitalize t-text-heading t-ml-8 fk-addons-table__body-text sm-text">
                                                                Discount After Tax
                                                              </span>
                                                            </label>
                                                          </div>
                                                        </div>
                                                       

                                                        <div className="row">
                                                          <div className="addons-list__item mt-3 mb-1 fk-addons-table__body col-6">
                                                            <label className="form-label d-block mb-0">
                                                                {_t(t("Select Groups / Items"))}{" "}
                                                                <small className="text-danger">*</small>
                                                            </label>
                                                            <div className="d-flex">
                                                                <label className="mx-checkbox mr-3">
                                                                    <input
                                                                        type="radio"
                                                                        value="Group"
                                                                        id="groups"
                                                                        className="mx-checkbox__input mx-checkbox__input-solid mx-checkbox__input-solid--danger mx-checkbox__input-sm "
                                                                        name="payment_type"
                                                                        //checked={1}
                                                                        onChange={() => {
                                                                            setNewScheme({
                                                                                ...newScheme,
                                                                                item_type: "Group",
                                                                            });
                                                                        }}
                                                                        checked={newScheme.item_type === "Group" ? 1 : 0}
                                                                    />
                                                                    <span className="mx-checkbox__text text-capitalize t-text-heading t-ml-8 fk-addons-table__body-text" >
                                                                        Groups / Category
                                                                    </span>
                                                                </label>
                                                                <label className="mx-checkbox mr-3">
                                                                    <input
                                                                        type="radio"
                                                                        value="Item"
                                                                        className="mx-checkbox__input mx-checkbox__input-solid mx-checkbox__input-solid--danger mx-checkbox__input-sm"
                                                                        name="payment_type"
                                                                        onChange={() => {
                                                                            setNewScheme({
                                                                                ...newScheme,
                                                                                item_type: "Item",
                                                                            });
                                                                        }}
                                                                        checked={newScheme.item_type === "Item" ? 1 : 0}
                                                                    />
                                                                    <span className="mx-checkbox__text text-capitalize t-text-heading t-ml-8 fk-addons-table__body-text" >
                                                                        Items
                                                                    </span>
                                                                </label>
                                                            </div>
                                                          </div>
                                                          <div className="addons-list__item mt-3 mb-1 fk-addons-table__body col-6">
                                                            <label className="form-label d-block mb-0">
                                                              {_t(t("Please Select"))}{" "}
                                                              <small className="text-danger">*</small>
                                                            </label>
                                                            <label className="mx-checkbox d-inline-block mr-3">
                                                              <input
                                                                type="radio"
                                                                value="discount_with_addon"
                                                                id="discount_with_addon"
                                                                className="mx-checkbox__input mx-checkbox__input-solid mx-checkbox__input-solid--danger mx-checkbox__input-sm"
                                                                name="discount_with_addon"
                                                                //checked={1}
                                                                onChange={() => {
                                                                  setNewScheme({
                                                                    ...newScheme,
                                                                    discount_addon: "discount_with_addon",
                                                                  });
                                                                }}
                                                                checked={newScheme.discount_addon === "discount_with_addon" ? 1 : 0}
                                                              />
                                                              <span className="mx-checkbox__text text-capitalize t-text-heading t-ml-8 fk-addons-table__body-text sm-text">
                                                                Discount With Addon
                                                              </span>
                                                            </label>
                                                            <label className="mx-checkbox d-inline-block mr-3">
                                                              <input
                                                                type="radio"
                                                                value="discount_without_addon"
                                                                className="mx-checkbox__input mx-checkbox__input-solid mx-checkbox__input-solid--danger mx-checkbox__input-sm"
                                                                name="discount_without_addon"
                                                                onChange={() => {
                                                                  setNewScheme({
                                                                    ...newScheme,
                                                                    discount_addon: "discount_without_addon",
                                                                  });
                                                                }}
                                                                checked={newScheme.discount_addon === "discount_without_addon" ? 1 : 0}
                                                              />
                                                              <span className="mx-checkbox__text text-capitalize t-text-heading t-ml-8 fk-addons-table__body-text sm-text">
                                                                Discount Without Addon
                                                              </span>
                                                            </label>
                                                          </div>
                                                        </div> 
                                                        {newScheme.item_type === "Group" ? (
                                                            <div className="row mt-3">
                                                                <label htmlFor="Groups" className="form-label">
                                                                  {_t(t("Groups"))}
                                                                  <small className="text-danger">*</small>
                                                                </label>
                                                                <Select
                                                                  value={group}
                                                                  options={foodGroup && foodGroup}
                                                                  getOptionLabel={(option) => option.name}
                                                                  getOptionValue={(option) => option.id}
                                                                  classNamePrefix="select"
                                                                  maxMenuHeight="200px"
                                                                  isMulti={true}
                                                                  closeMenuOnSelect={false}
                                                                  onChange={handleSetGroups}
                                                                  placeholder={_t(t("Please select Group")) + ".."}
                                                                  
                                                                />
                                                            </div>
                                                        ) : (
                                                            <div className="row mt-3">
                                                                <label htmlFor="Items" className="form-label">
                                                                    {_t(t("Items"))}
                                                                    <small className="text-danger">*</small>
                                                                </label>
                                                                <Select
                                                                  value={item}
                                                                  options={foodItem && foodItem}
                                                                  getOptionLabel={(option) => option.name}
                                                                  getOptionValue={(option) => option.id}
                                                                  classNamePrefix="select"
                                                                  maxMenuHeight="200px"
                                                                  isMulti={true}
                                                                  closeMenuOnSelect={false}
                                                                  onChange={handleSetItems}
                                                                  placeholder={_t(t("Please select Item")) + ".."}
                                                                />
                                                            </div>
                                                        )}
                                                        <div className="row mt-3">
                                                            <div className="col-6">
                                                                <label htmlFor="Groups" className="form-label">
                                                                  {_t(t("Branch"))}
                                                                  <small className="text-danger">*</small>
                                                                </label>
                                                                <Select
                                                                  value={branch}
                                                                  options={branches && branches}
                                                                  getOptionLabel={(option) => option.name}
                                                                  getOptionValue={(option) => option.id}
                                                                  classNamePrefix="select"
                                                                  maxMenuHeight="200px"
                                                                  isMulti={true}
                                                                  closeMenuOnSelect={false}
                                                                  onChange={handleSetBranch}
                                                                  placeholder={_t(t("Please select Branch")) + ".."}
                                                                />
                                                            </div>
                                                            <div className="col-6">
                                                                <label htmlFor="Groups" className="form-label">
                                                                  {_t(t("Order Types"))}
                                                                  <small className="text-danger">*</small>
                                                                </label>
                                                                <Select
                                                                  value={orderType}
                                                                  options={orderTypes && orderTypes}
                                                                  getOptionLabel={(option) => option.name}
                                                                  getOptionValue={(option) => option.id}
                                                                  classNamePrefix="select"
                                                                  maxMenuHeight="200px"
                                                                  isMulti={true}
                                                                  closeMenuOnSelect={false}
                                                                  onChange={handleSetOrderType}
                                                                  placeholder={_t(t("Please select Order Types")) + ".."}
                                                                />
                                                            </div>
                                                           
                                                        </div>
                                                        {/* <div className="addons-list__item mt-3 mx-1 mb-1 fk-addons-table__body">
                                                            <div className="mb-2">
                                                                <label className="control-label">
                                                                    {_t(t("Discount Type"))}
                                                                </label>
                                                            </div>
                                                                <div className="d-flex align-items-center">
                                                                    <div className="mx-checkbox mr-4">
                                                                        <input
                                                                            type="radio"
                                                                            className="mx-checkbox__input mx-checkbox__input-solid mx-checkbox__input-solid--danger mx-checkbox__input-sm"
                                                                            id="discount_type_fixed"
                                                                            name="discount_type"
                                                                            value="fixed"
                                                                            onChange={(e) => {
                                                                                handleDiscountType(e);
                                                                            }}
                                                                            checked={newScheme.discount_type === "fixed" ? 1 : 0}
                                                                            disabled={newScheme.card_required}
                                                                        />

                                                                        <label htmlFor="discount_type_fixed" className="mx-checkbox__text text-capitalize t-text-heading t-ml-8 fk-addons-table__body-text" >
                                                                            {_t(t("Fixed Amount"))}
                                                                        </label>
                                                                    </div>

                                                                    <div className="mx-checkbox mr-4">
                                                                        <input
                                                                            disabled={newScheme.open_discount}
                                                                            type="radio"
                                                                            className="mx-checkbox__input mx-checkbox__input-solid mx-checkbox__input-solid--danger mx-checkbox__input-sm"
                                                                            id="discount_type_percentage"
                                                                            name="discount_type"
                                                                            value="percentage"
                                                                            onChange={(e) => {
                                                                                handleDiscountType(e);
                                                                            }}
                                                                            checked={
                                                                                newScheme.discount_type === "percentage" ? 1 : 0
                                                                            }
                                                                        />

                                                                        <label htmlFor="discount_type_percentage" className="mx-checkbox__text text-capitalize t-text-heading t-ml-8 fk-addons-table__body-text " >
                                                                            {_t(t("Percentage"))}
                                                                        </label>
                                                                    </div>
                                                                    <div className="mx-checkbox mr-4">
                                                                        <input
                                                                            disabled={newScheme.open_discount}
                                                                            type="radio"
                                                                            className="mx-checkbox__input mx-checkbox__input-solid mx-checkbox__input-solid--danger mx-checkbox__input-sm"
                                                                            id="discount_type_bogo"
                                                                            name="discount_type"
                                                                            value="bogo"
                                                                            onChange={(e) => {
                                                                                handleDiscountType(e);
                                                                            }}
                                                                            checked={
                                                                                newScheme.discount_type === "bogo" ? 1 : 0
                                                                            }
                                                                        />
                                                                        <label htmlFor="discount_type_bogo" className="mx-checkbox__text text-capitalize t-text-heading t-ml-8 fk-addons-table__body-text" >
                                                                            {_t(t("Bogo Scheme"))}
                                                                        </label>
                                                                    </div>

                                                                    <div className="mx-checkbox mr-4" >
                                                                        <input
                                                                            type="checkbox"
                                                                            className="mx-checkbox__input mx-checkbox__input-solid mx-checkbox__input-solid--danger mx-checkbox__input-sm"
                                                                            id="discount_type_open"
                                                                            name="open_discount"
                                                                            value="open_discount"                                                                            
                                                                            onChange={(e) => handleOpenDiscount(e)}
                                                                            checked={parseInt(newScheme.open_discount) === 1}
                                                                            disabled={newScheme.card_required}
                                                                        />

                                                                        <label htmlFor="discount_type_open" className="mx-checkbox__text text-capitalize t-text-heading t-ml-8 fk-addons-table__body-text" >
                                                                            {_t(t("Open Discount"))}
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                        </div> */}
                                                        {/*payment type link */}
                                                        <div className="row mt-4">
                                                          <div className="col-6">
                                                            <label htmlFor="cap_amount" className="form-label">
                                                              {_t(t("Cap Amount"))}
                                                            </label>
                                                            <input
                                                              disabled={newScheme.scheme_type === "enable_by_default" || newScheme.discount_type === "bogo" || newScheme.open_discount}
                                                              type="number"
                                                              step="0.1"
                                                              onKeyDown={(evt) =>
                                                                ["e", "E", "+", "-","ArrowUp", "ArrowDown"].includes(evt.key) &&
                                                                evt.preventDefault()
                                                              }
                                                              onWheel={(e) => e.target.blur()}
                                                              className="form-control "
                                                              min="0"
                                                              id="cap_amount"
                                                              name="cap_amount"
                                                              placeholder=""
                                                              value={newScheme.cap_amount}
                                                              onChange={handleSetScheme}
                                                            />
                                                          </div>
                                                          <div className="col-6">
                                                            <label htmlFor="input_key" className="form-label">
                                                              {_t(t("Payment Account Link"))}{" "}
                                                            </label>
                                                            <Select
                                                              options={MdIntegrationForSearch ? MdIntegrationForSearch : []}
                                                              getOptionLabel={(option) => option.account_name}
                                                              getOptionValue={(option) => option.id}
                                                              classNamePrefix="select"
                                                              onChange={(e)=>handleSetPayment(e)}
                                                              maxMenuHeight="13rem"
                                                              value={newScheme.account_map_id}
                                                              isClearable
                                                              placeholder={
                                                              _t(t("Please select type")) + ".."
                                                              }                                                
                                                            />
                                                          </div>

                                                        </div>
                                                        {/*enable on off offer*/}
                                                        <div className="row mt-4">
                                                            <div className="form-radio col-6">
                                                                <div className="d-inline mr-4">
                                                                    <input
                                                                        disabled={newScheme.discount_type === "bogo" || newScheme.open_discount || newScheme.card_required}
                                                                        type="radio"
                                                                        className="form-check-input pointer-cursor"
                                                                        id="scheme_type_enable"
                                                                        name="scheme_type_setting"
                                                                        value="enable_by_default"
                                                                        onChange={(e) => {
                                                                            handleSchemeType(e);
                                                                        }}
                                                                        checked={
                                                                            newScheme.scheme_type === "enable_by_default"
                                                                                ? 1
                                                                                : 0
                                                                        }
                                                                    />
                                                                    <label
                                                                        className="form-radio-label pointer-cursor ml-2"
                                                                        htmlFor="scheme_type_enable"                                                                     
                                                                    >
                                                                        {_t(t("Enable By Default"))}
                                                                    </label>
                                                                </div>

                                                                <div className="d-inline mr-4">
                                                                    <input
                                                                        type="radio"
                                                                        className="form-check-input pointer-cursor"
                                                                        id="scheme_type_manual"
                                                                        name="scheme_type_setting"
                                                                        value="manual_apply"
                                                                        onChange={(e) => {
                                                                            handleSchemeType(e);
                                                                        }}
                                                                        checked={
                                                                            newScheme.scheme_type === "manual_apply" ? 1 : 0
                                                                        }
                                                                    />
                                                                    <label
                                                                        className="form-radio-label pointer-cursor ml-2"
                                                                        htmlFor="scheme_type_manual"
                                                                    >
                                                                        {_t(t("Manual Apply"))}
                                                                    </label>
                                                                </div>
                                                            </div>
                                                            <div className="col-6 row">
                                                                <div className="form-check pl-0 col-6">
                                                                    <Switch
                                                                        onChange={handleChangeStatus}
                                                                        checked={newScheme.status}
                                                                        className="react-switch"
                                                                        name="status"
                                                                        height={22}
                                                                        width={44}
                                                                        offColor="#ee5253"
                                                                    />
                                                                    
                                                                    <label className="pl-2 align-top">Status</label>
                                                                </div>

                                                                <div className="form-check pl-0 col-6">
                                                                    <Switch
                                                                        disabled={newScheme.discount_type === "bogo" || newScheme.open_discount}
                                                                        onChange={handleChangeRequiredCard}
                                                                        checked={newScheme.card_required}
                                                                        className="react-switch"
                                                                        name="card_required"
                                                                        height={22}
                                                                        width={44}
                                                                        offColor="#ee5253"
                                                                    />
                                                                    <label className="pl-2 align-top">Card required</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row mt-4">
                                                          <div className="col-6">
                                                              <div className="form-check pl-0 col-6 d-flex align-items-center">
                                                                  <Switch
                                                                      onChange={handleChangeRequiredschedule}
                                                                      checked={newScheme.schedule}
                                                                      className="react-switch"
                                                                      name="schedule"
                                                                      height={22}
                                                                      width={44}
                                                                      offColor="#ee5253"
                                                                  />
                                                                  <label className="pl-2 align-top">Schedule</label>
                                                              </div>
                                                          </div>
                                                          <div className="col-6">
                                                              <div className="d-flex align-items-center">
                                                                  <Switch
                                                                      onChange={handleChangeRequiredTime}
                                                                      checked={newScheme.time}
                                                                      className="react-switch"
                                                                      name="time"
                                                                      height={22}
                                                                      width={44}
                                                                      offColor="#ee5253"
                                                                  />
                                                                  <label className="pl-2 align-top">Time</label>
                                                              </div>
                                                          </div>
                                                        </div>

                                                        <div className="row mt-3 mx-0">
                                                            <div className="col-6">
                                                                {newScheme.schedule ?
                                                                    <span>
                                                                        <div className="col-12 mb-1">
                                                                            <div className="form-check">
                                                                                <input
                                                                                    type="checkbox"
                                                                                    className="form-check-input pointer-cursor"
                                                                                    value="Mon"
                                                                                    name="mon"
                                                                                    id="mon"
                                                                                    onChange={handleCheckboxChange}
                                                                                    checked={weekDays.includes("Mon")}
                                                                                />
                                                                                <label
                                                                                    className="form-check-label  pointer-cursor"
                                                                                    htmlFor="mon"
                                                                                >
                                                                                    {_t(t("Monday"))}
                                                                                </label>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-12 mb-1">
                                                                            <div className="form-check">
                                                                                <input
                                                                                    type="checkbox"
                                                                                    className="form-check-input pointer-cursor"
                                                                                    value="Tue"
                                                                                    name="tue"
                                                                                    id="tue"
                                                                                    onChange={handleCheckboxChange}
                                                                                    checked={weekDays.includes("Tue")}
                                                                                />
                                                                                <label
                                                                                    className="form-check-label  pointer-cursor"
                                                                                    htmlFor="tue"
                                                                                >
                                                                                    {_t(t("Tuesday"))}
                                                                                </label>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-12 mb-1">
                                                                            <div className="form-check">
                                                                                <input
                                                                                    type="checkbox"
                                                                                    className="form-check-input pointer-cursor"
                                                                                    value="Wed"
                                                                                    name="wed"
                                                                                    id="wed"
                                                                                    onChange={handleCheckboxChange}
                                                                                    checked={weekDays.includes("Wed")}
                                                                                />
                                                                                <label
                                                                                    className="form-check-label  pointer-cursor"
                                                                                    htmlFor="wed"
                                                                                >
                                                                                    {_t(t("Wednesday"))}
                                                                                </label>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-12 mb-1">
                                                                            <div className="form-check">
                                                                                <input
                                                                                    type="checkbox"
                                                                                    className="form-check-input pointer-cursor"
                                                                                    value="Thu"
                                                                                    name="thu"
                                                                                    id="thu"
                                                                                    onChange={handleCheckboxChange}
                                                                                    checked={weekDays.includes("Thu")}
                                                                                />
                                                                                <label
                                                                                    className="form-check-label  pointer-cursor"
                                                                                    htmlFor="thu"
                                                                                >
                                                                                    {_t(t("Thursday"))}
                                                                                </label>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-12 mb-1">
                                                                            <div className="form-check">
                                                                                <input
                                                                                    type="checkbox"
                                                                                    className="form-check-input pointer-cursor"
                                                                                    value="Fri"
                                                                                    name="fri"
                                                                                    id="fri"
                                                                                    onChange={handleCheckboxChange}
                                                                                    checked={weekDays.includes("Fri")}
                                                                                />
                                                                                <label
                                                                                    className="form-check-label  pointer-cursor"
                                                                                    htmlFor="fri"
                                                                                >
                                                                                    {_t(t("Friday"))}
                                                                                </label>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-12 mb-1">
                                                                            <div className="form-check">
                                                                                <input
                                                                                    type="checkbox"
                                                                                    className="form-check-input pointer-cursor"
                                                                                    value="Sat"
                                                                                    name="sat"
                                                                                    id="sat"
                                                                                    onChange={handleCheckboxChange}
                                                                                    checked={weekDays.includes("Sat")}
                                                                                />
                                                                                <label
                                                                                    className="form-check-label  pointer-cursor"
                                                                                    htmlFor="sat"
                                                                                >
                                                                                    {_t(t("Saturday"))}
                                                                                </label>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-12 mb-1">
                                                                            <div className="form-check">
                                                                                <input
                                                                                    type="checkbox"
                                                                                    className="form-check-input pointer-cursor"
                                                                                    value="Sun"
                                                                                    name="sun"
                                                                                    id="sun"
                                                                                    onChange={handleCheckboxChange}
                                                                                    checked={weekDays.includes("Sun")}
                                                                                />
                                                                                <label
                                                                                    className="form-check-label  pointer-cursor"
                                                                                    htmlFor="sun"
                                                                                >
                                                                                    {_t(t("Sunday"))}
                                                                                </label>
                                                                            </div>
                                                                        </div>
                                                                    </span>
                                                                    : null
                                                                }
                                                            </div>
                                                            {
                                                                newScheme.time ?
                                                                    <>
                                                                        <div className="mt-3 col-md-3">
                                                                            <label
                                                                                htmlFor="from_date"
                                                                                className="form-label"
                                                                            >
                                                                                {_t(t("From Time"))}
                                                                                <small className="text-danger">*</small>
                                                                            </label>
                                                                            <Datetime
                                                                                value={newScheme.fromTime}
                                                                                onChange={handleTimeFromChange}
                                                                                dateFormat={false} // Hide the date picker
                                                                                className="col "
                                                                                timeConstraints={{
                                                                                    minutes: { step: 5 }, // Customize time selection steps
                                                                                }}
                                                                            />
                                                                        </div>
                                                                        <div className="mt-3 col-md-3">
                                                                            <label
                                                                                htmlFor="from_date"
                                                                                className="form-label"
                                                                            >
                                                                                {_t(t("To Time"))}
                                                                                <small className="text-danger">*</small>
                                                                            </label>
                                                                            <Datetime
                                                                                //input={false} // Hide the input field for time selection only
                                                                                value={newScheme.toTime}
                                                                                onChange={handleTimeToChange}
                                                                                dateFormat={false} // Hide the date picker
                                                                                className="col "
                                                                                dropdownMode="select"
                                                                                timeConstraints={{
                                                                                    minutes: { step: 5 }, // Customize time selection steps
                                                                                }}                                                                                
                                                                            />
                                                                        </div>
                                                                    </>
                                                                    :
                                                                    null
                                                            }
                                                        </div>

                                                        {newScheme.card_required ?
                                                            <div className="row mt-4">
                                                              <div className="col-6">
                                                                <label htmlFor="Groups" className="form-label">
                                                                    {_t(t("IBAN Numbers"))}
                                                                    <small className="text-danger">*</small>
                                                                </label>
                                                                <Select
                                                                  value={ibansList}
                                                                  options={cardForSearch}
                                                                  getOptionLabel={(option) => option.iban_number}
                                                                  getOptionValue={(option) => option.iban_number}
                                                                  classNamePrefix="select"
                                                                  maxMenuHeight="200px"
                                                                  isMulti={true}
                                                                  required
                                                                  
                                                                  closeMenuOnSelect={false}
                                                                  onChange={handleSetCards}
                                                                  placeholder={_t(t("Please select IBAN numbers")) + ".."}
                                                                />
                                                              </div>
                                                              {
                                                              /*
                                                            <div className="col-6">
                                                              <label htmlFor="Groups" className="form-label">
                                                                {_t(t("Cap Amount"))}
                                                                <small className="text-danger">*</small>
                                                              </label>
                                                              <input
                                                                type="number"
                                                                step="0.01"
                                                                className="form-control"
                                                                id="cap_amount"
                                                                name="cap_amount"
                                                                min="0"
                                                                placeholder=""
                                                                value={newScheme.cap_amount || ""}
                                                                required
                                                                onChange={handleSetScheme}
                                                              />
                                                            </div>
                                                            */}
                                                            </div>
                                                            : null
                                                        }

                                                        <div className="row mt-4">
                                                            <div className="d-flex justify-content-end">
                                                                <div className="col-3 d-flex justify-content-end">
                                                                    <button
                                                                        type="button"
                                                                        className="btn rounded hov-btn mr-3"
                                                                        onClick={()=>(history.push("/dashboard/manage/food/add-schemes"))}
                                                                    //   data-dismiss="modal"
                                                                    >
                                                                        <i className="fa fa-arrow-left" aria-hidden="true"></i> {_t(t("Back"))}
                                                                    </button>
                                                                    <button
                                                                        disabled={loading}
                                                                        type="submit"
                                                                        className="btn btn-primary rounded"
                                                                    >
                                                                        <i className="fa fa-floppy-o" aria-hidden="true"></i> {!newScheme.edit ? _t(t("Save")) : _t(t("Update"))}
                                                                    </button>                                                    
                                                                </div>
                                                                {/* <div className="col-2">
                                                                    <button
                                                                        type="button"
                                                                        className="btn btn-primary rounded"
                                                                        onClick={()=>(history.push("/dashboard/manage/food/add-schemes"))}
                                                                    //   data-dismiss="modal"
                                                                    >
                                                                        {_t(t("Back"))}
                                                                    </button>
                                                                </div> */}
                                                            </div>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* Rightbar contents end*/}
                    </div>
                </div>
            </main>
        {/* main body ends */}
      </>
    );
}

export default SchemeCrudAction;    