import React, { useContext, useState, useEffect, useRef, useMemo } from "react";
import { Route, Redirect } from "react-router-dom";

//importing context consumer here
import { UserContext } from "../contexts/User";
import { getSystemSettings, useCustomHooks } from "../functions/Functions"
//context consumer
import { SettingsContext } from "../contexts/Settings";
import { RestaurantContext } from "../contexts/Restaurant";
//functions
import { _t, getCookie } from "../functions/Functions";
import { BASE_URL } from "../BaseUrl";
import { off } from "firebase/database";
import BellRing from "../resources/restaurant/auth/modal/BellRing";

const PosOrderRoute = ({ children, ...rest }) => {

  //getting context values here
  let {
    generalSettings,
  } = useContext(SettingsContext);
 
  let { orderTypeList, bellRing, setBellRing, audioInstance } = useContext(RestaurantContext);

  //getting context values here
  const { authUserInfo } = useContext(UserContext);
  const isInitialRender = useRef(true);

  const { checkOnlineOrders, getFirbaseRunningOrder, intervalRef, onlineOrderQuery } = useCustomHooks()
 
  const [url, setUrl] = useState(window.location.pathname)
  const offline = (user) => {
    return !navigator.onLine && localStorage.getItem(user) != null;
  }
  const user = BASE_URL + "/auth/user";

  const isOnlineOrderEnabled = useMemo(() => {
    return getSystemSettings(generalSettings, "online_order_status") === "1";
  }, [generalSettings]);

  const determineOrderType = () => {
    if (window.location.pathname === "/dashboard/pos/online-orders") {
      return "online";
    }
    if (window.location.pathname === "/dashboard/pos/foodpanda") {
      return "fp";
    }
    return "";
  };

  //close bell ring
  const handleBellRingClose = () =>{
    setBellRing(false);
    if(audioInstance.current){
      audioInstance.current.pause();
      audioInstance.current.currentTime = 0;
    }
  }

  const canTakeOnlineOrder = useMemo(() => {
    if (!orderTypeList?.data || !authUserInfo?.details) return false;
    const userOrderTypes =
      authUserInfo.details.order_type_id &&
      JSON.parse(authUserInfo.details.order_type_id || "[]");
    return orderTypeList.data.some((orderType) => {
      const isDeliveryOrTakeaway =
        orderType.name.toLowerCase() === "delivery" ||
        orderType.name.toLowerCase() === "takeaway";
  
      const isOrderTypeActive = orderType.status === "On";
  
      if (userOrderTypes?.length > 0) {
        return (
          isOrderTypeActive &&
          isDeliveryOrTakeaway &&
          userOrderTypes.includes(orderType.id)
        );
      }
  
      return isOrderTypeActive && isDeliveryOrTakeaway;
    });
  }, [orderTypeList?.data, authUserInfo?.details]);

  useEffect(()=>{
    return () => {
      setTimeout(() => {
        handleBellRingClose();
        clearInterval(audioInstance.current)
      }, 5000);
    };
  },[])

  useEffect(()=>{
    setUrl(window.location.pathname)
    if (isInitialRender.current) {
        isInitialRender.current = false;
        return;
    }

    if(generalSettings && authUserInfo?.details && authUserInfo?.details?.user_type !== "call_center"){
      if(window.location.pathname === "/dashboard/pos/submitted"){
        getFirbaseRunningOrder()
      }
      if (isOnlineOrderEnabled && (window.location.pathname === "/dashboard/pos/online-orders" || window.location.pathname === "/dashboard/pos/foodpanda" || url === "/dashboard/pos/online-orders" || url === "/dashboard/pos/foodpanda")
      ) {
        const type = determineOrderType();
        checkOnlineOrders(type)
      }
    } 
    
    return () => {
      if(onlineOrderQuery.current){
        off(onlineOrderQuery.current);
      }
    };
   
  },[window.location.pathname])

  useEffect(()=>{
    if(generalSettings && orderTypeList?.data && authUserInfo?.details &&  authUserInfo?.details?.user_type !== "call_center"){
      if(window.location.pathname === "/dashboard/pos/submitted"){
        getFirbaseRunningOrder()
      }
      if (isOnlineOrderEnabled && canTakeOnlineOrder) {
        const type = determineOrderType();
        checkOnlineOrders(type);

      }
    }  

    return () => {
      if(onlineOrderQuery.current){
        off(onlineOrderQuery.current);
      }
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    };
  },[canTakeOnlineOrder, isOnlineOrderEnabled])

  //redirect if customer
  if (authUserInfo.details && authUserInfo.details.user_type === "customer") {
    return (
      <Route
        render={() => {
          return (
            <Redirect
              to={{
                pathname: "/",
              }}
            />
          );
        }}
      ></Route>
    );
  }

  return (
    <Route
      {...rest}
      render={() => {
        return getCookie() !== undefined || offline(user) ? (
          <>
           <BellRing  bellRing={bellRing} handleBellRingClose={handleBellRingClose} type="order"/>
           {children}
          </>
        ) : (
          <Redirect
            to={{
              pathname: "/",
              state: { alert: "You need to login first!" },
            }}
          />
        );
      }}
    ></Route>
  );
};
export default PosOrderRoute;
