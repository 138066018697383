import React from 'react'

const BellRing = ({bellRing, handleBellRingClose, type}) => {
  return (
    <>
        {
            bellRing && (
                <>
                    <div className={`bell ${type === "order" ? "bg-primary" : ""}`} onClick={()=>{
                        handleBellRingClose()
                    }}>
                        <div className={`bell-border ${type === "order" ? "bell-border-primary" : "bell-border-danger"}`}></div>
                        <i className="fa fa-bell btn-bell"></i>
                    </div>
                </>    
            )
        }
    </>
  )
}

export default BellRing
