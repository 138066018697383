import React, { useEffect, useState } from 'react'

const TopBarOffline = ({online}) => {
    const [showTopbar, setShowTopbar] = useState(false)
    const bgColor = online ? "#0a8d6e" : "#f5364a";
    const message = online ? "You are now online" : "You are now offline";

    useEffect(() => {
        if (online) {
            setTimeout(() => {
                setShowTopbar(false)
            }, 5000);

        } else {
            setShowTopbar(true)
        }
    }, [online])
    
if (showTopbar) {

    return (
        <div
            className={" text-white text-center py-2"}
            style={{
                backgroundColor: bgColor,
                position: "fixed",
                bottom: "0",
                zIndex: 100000,
                width: "100vw",
                overflowX: "hidden",
            }}
        >
            <div>
                <span className="mx-2" style={{
                    fontSize:"1.2rem",
                    fontWeight: "500"
                }}>
                    {
                        message
                    }
                </span>
            </div>
        </div>
    )
} else {
    return <></>
 }
}


export default TopBarOffline


