import React, { useEffect, useContext, useState, useRef } from "react";
import { NavLink } from "react-router-dom";

//axios and base url
import axios from "axios";
import { BASE_URL } from "../../../../BaseUrl";
import moment from "moment";
//functions
import {
  _t,
  currencySymbolLeft,
  formatPrice,
  currencySymbolRight,
  getCookie,
  paginationLoading,
  paginationOrderHistory,
  showingDataOrderHistory,
  getSystemSettings,
  useCustomHooks,
  checkPermission,
  customStyle
} from "../../../../functions/Functions";
import { useTranslation } from "react-i18next";

//3rd party packages
import { Helmet } from "react-helmet";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Moment from "react-moment";
import { useReactToPrint } from "react-to-print";

//importing context consumer here
import { SettingsContext } from "../../../../contexts/Settings";
import { UserContext } from "../../../../contexts/User";
import { RestaurantContext } from "../../../../contexts/Restaurant";
import { FoodContext } from "../../../../contexts/Food";
import OrderPrintView from "../pos/modal/OrderPrintView";
import OrderDetailsView from "../pos/modal/OrderDetailsView";

const OrderHistories = () => {
  //getting context values here
  const {
    //common
    generalSettings,
  } = useContext(SettingsContext);
  const { authUserInfo, getCustomer, customerForSearch } = useContext(UserContext);

  const {
    //branch
    branchForSearch,

    //order histories
    getAllOrders,
    allOrders,
    setPaginatedAllOrders,
    setPaginatedFilteredOrders,
    setPaginatedSearchOrders,
    allOrdersForSearch,
    setAllOrdersForSearch,
    //pagination
    dataPaginating,
    setDataPaginating,
  } = useContext(RestaurantContext);

  const {
    //common
    loading,
    setLoading,
  } = useContext(FoodContext);

  const { t } = useTranslation();
  const { taxPercent } = useCustomHooks();
  //print bills
  const componentRef = useRef();

  // States hook here
  //settle order
  const [checkOrderDetails, setCheckOrderDetails] = useState({
    item: null,
    settle: false,
    uploading: false,
    payment_type: null,
    payment_amount: null,
  });
  const [taxType, setTaxType] = useState("");
  //search result
  let [searchedOrders, setSearchedOrders] = useState({
    list: null,
    searched: false,
    branch: null,
  });

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [filterBranch, setFilterBranch] = useState(null);
  const [filterCustomer, setFilterCustomer] = useState(null);
  const [filtered, setFiltered] = useState(false);
  const [searched, setSearched] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const isSettle = useRef(0);

  //useEffect == componentDidMount
  useEffect(() => {
    getCustomer();
  }, []);
 
  // useEffect(() => {
  //   if (!searched) setSearchInput("");
  // }, [searched]);

  useEffect(() => {
    if (!generalSettings || !generalSettings?.length) return;
    setTaxType(getSystemSettings(generalSettings, "tax_type"));
  }, [generalSettings]);

  
  //cancel order confirmation modal
  const handleDeleteOrderConfirmation = (orderGroup) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="card card-body">
            <h1>{_t(t("Are you sure?"))}</h1>
            <p className="text-center">
              {_t(t("You want to delete this order?"))}
            </p>
            <div className="d-flex justify-content-center">
              <button
                className="btn btn-primary"
                onClick={() => {
                  handleDeleteOrder(orderGroup);
                  onClose();
                }}
              >
                {_t(t("Yes, delete it!"))}
              </button>
              <button className="btn btn-success ml-2 px-3" onClick={onClose}>
                {_t(t("No"))}
              </button>
            </div>
          </div>
        );
      },
    });
  };

  //cancel order here
  const handleDeleteOrder = (orderGroup) => {
    let url = BASE_URL + "/settings/delete-order-from-history";
    let formData = {
      id: orderGroup.id,
    };
    setLoading(true);
    axios
      .post(url, formData, {
        headers: { Authorization: `Bearer ${getCookie()}` },
      })
      .then(() => {
        setLoading(false);
        setFiltered(false);
        setSearched(false);
        toast.success(`${_t(t("Deleted successfully"))}`, {
          position: "bottom-center",
          closeButton: false,
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          className: "text-center toast-notification",
        });
      })
      .catch(() => {
        setLoading(false);
        toast.error(`${_t(t("Please try again"))}`, {
          position: "bottom-center",
          closeButton: false,
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          className: "text-center toast-notification",
        });
      });
  };

  //search submitted orders here
  const handleSearch = (e) => {
    e.preventDefault();
    // if (searchInput) {
    //   setPaginatedSearchedOrders(createSearchObj(1));
    //   setSearched(true);
    //   if (filtered) setFiltered(false);
    // } else {
    //   setSearched(false);
    // }
    let inputSearch = searchInput.toLowerCase();
    if (inputSearch.length === 0) {
    setSearchedOrders({ ...searchedOrders, searched: false });
    setSearched(false);
    } else {
    setSearched(true);
    let searchedList = allOrders?.data.filter((item) => {
      //token
      let lowerCaseItemToken = item.token?.id && JSON.stringify(item.token.id)?.toLowerCase();
      let orderNo = item?.order_no && item.order_no.toString();

      //customer
      let lowerCaseItemCustomer = item?.customer_name?.toLowerCase();
    
      //branch
      let lowerCaseItemBranch = item?.branch_name?.toLowerCase();
      return (
        (lowerCaseItemToken && lowerCaseItemToken.includes(inputSearch)) ||
        (lowerCaseItemCustomer && lowerCaseItemCustomer.includes(inputSearch)) ||
        (lowerCaseItemBranch && lowerCaseItemBranch.includes(inputSearch)) ||
        (orderNo && orderNo.includes(inputSearch))
      );
    });
    
    setAllOrdersForSearch({
      data:searchedList,
      meta: {
          "current_page": 1,
          "from": 1,
          "last_page": Math.ceil(searchedList?.length/100),
          "per_page": 100,
        }
    })
    // setSearchedOrders({
    //   ...searchedOrders,
    //   list: searchedList,
    //   searched: true,
    // });
    }
  };
 
  const createFilterObj = (page) => {
    const fromDate = moment(startDate).format("YYYY-MM-DD");
    const toDate = moment(endDate).format("YYYY-MM-DD");
    return {
      fromDate: fromDate,
      toDate: toDate,
      branch_id: filterBranch ? filterBranch.id : "",
      customer_id: filterCustomer? filterCustomer.id : "",
      page: page,
      type: "pos",
      search_term: searchInput,
    };
  };
  const createSearchObj = (page) => {
    return {
      search_term: searchInput.toLowerCase(),
      type: "pos",
      page: page,
    };
  };

  const handleFilterSubmit = () => {
    if (startDate === null || endDate === null) {
      toast.error(`${_t(t("Please select the dates to filter"))}`, {
        position: "bottom-center",
        closeButton: false,
        autoClose: 10000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        className: "text-center toast-notification",
      });
      return;
    }
    setPaginatedFilteredOrders(createFilterObj(1));
    setFiltered(true);
    if (searched) setSearched(false);
  };

  //customer wise filter
  const handleCustomerFilter = (customer) => {
    setFilterCustomer(customer);
  };

  //branch wise filter
  const handleBranchFilter = (branch) => {
    setFilterBranch(branch);
  };

  //print here
  const handleOnlyPrint = useReactToPrint({
    content: () => componentRef.current,
  });

  return (
    <>
      <Helmet>
        <title>{_t(t("Order history"))}</title>
      </Helmet>

      {/* main body */}
      <main id="main" data-simplebar>
        <div className="container-fluid">
          <div className="row t-mt-10 gx-2">
            <div className="col-12 t-mb-30 mb-lg-0">
              {/* {loading === true ? (
                pageLoading()
              ) : ( */}
                <div className="t-bg-white ">
                  {/* next page data spin loading */}
                  <div className={`${(dataPaginating || loading) && "loadingBlur"}`}></div>
                    <div className={`${(dataPaginating || loading) && "lds-roller"}`}><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                  {/* spin loading ends */}
                  <div className="row gx-2 align-items-center t-pt-15 t-pb-15 t-pl-15 t-pr-15 t-shadow">
                    {/*<div className="col-12 t-mb-15">
                      <ul className="t-list fk-breadcrumb">
                        <li className="fk-breadcrumb__list">
                          <span className="t-link fk-breadcrumb__link text-uppercase">
                            {filtered === false && searched === false
                              ? _t(t("Order history"))
                              : _t(t("Filtered order history"))}
                          </span>
                        </li>
                      </ul>
                            </div>*/}
                    <div className="col-md-2 col-sm-6">
                      <ul className="t-list fk-breadcrumb d-flex align-items-center mr-3">
                        <li className="fk-breadcrumb__list">
                          <span className="t-link fk-breadcrumb__link text-uppercase">
                            {filtered === false && searched === false
                              ? _t(t("Order history"))
                              : _t(t("Filtered order history"))}
                          </span>
                        </li>
                      </ul>
                    </div>
                    <div className="col-md-3 col-sm-6 row d-flex align-items-center">
                      <div className="fk-sort__list mr-1 col-5">
                          <DatePicker
                            selected={startDate}
                            onChange={(date) => setStartDate(date)}
                            peekNextMonth
                            showMonthDropdown
                            showYearDropdown
                            dropdownMode="select"
                            className="form-control xsm-text py-2 rounded"
                            placeholderText={_t(t("From date"))}
                            shouldCloseOnSelect={false}
                          />
                      </div>
                      <div className="fk-sort__list mr-1 col-1">
                        <span className="fk-sort__icon">
                          <span className="fa fa-long-arrow-right"></span>
                        </span>
                      </div>
                      <div className="fk-sort__list mr-1 col-5">
                        <DatePicker
                          selected={endDate}
                          onChange={(date) => setEndDate(date)}
                          peekNextMonth
                          showMonthDropdown
                          showYearDropdown
                          dropdownMode="select"
                          styles={customStyle}
                          className="form-control xsm-text py-2 rounded"
                          placeholderText={_t(t("To date"))}
                          shouldCloseOnSelect={false}
                        />
                      </div>
                    </div>
                    {/* large screen  */}
                    <div className="col-md-7 col-sm-12 mt-2 mt-mb-0 mb-md-0">
                      <ul className="t-list fk-sort justify-content-end align-items-center">
                        <li className="fk-sort__list col-2">
                            <input
                            type="text"
                            className="form-control border-0 form-control--light-1 rounded"
                            name="search"
                            placeholder={
                              _t(t("Search by token, #B-12")) + ".."
                            }
                            value={searchInput}
                            onChange={(e) => setSearchInput(e.target.value)}
                          />
                        </li>
                       
                        <li className="fk-sort__list col-3">
                          <Select
                            options={customerForSearch ? customerForSearch : []}
                            components={makeAnimated()}
                            getOptionLabel={(option) => option.name}
                            getOptionValue={(option) => option.id}
                            className="xsm-text"
                            value={filterCustomer}
                            isClearable
                            onChange={handleCustomerFilter}
                            maxMenuHeight="200px"
                            styles={customStyle}
                            placeholder={_t(t("Customer")) + ".."}
                          />
                        </li>
                        {authUserInfo.details !== null &&
                          authUserInfo.details.user_type !== "staff" && authUserInfo.details.user_type !== "manager" && authUserInfo.details.user_type !== "waiter" && (
                            <li
                              className="fk-sort__list col-3"
                            >
                              <Select
                                options={branchForSearch && branchForSearch}
                                components={makeAnimated()}
                                getOptionLabel={(option) => option.name}
                                getOptionValue={(option) => option.id}
                                className="xsm-text"
                                value={filterBranch}
                                isClearable
                                onChange={handleBranchFilter}
                                maxMenuHeight="200px"
                                styles={customStyle}
                                placeholder={_t(t("branch")) + ".."}
                              />
                            </li>
                          )}
                        <li className="fk-sort__list col-1">
                          <button
                            className="btn btn-transparent btn-danger xsm-text text-uppercase py-2 rounded"
                            // onClick={handleDateFilter}
                            onClick={handleFilterSubmit}
                          >
                            {_t(t("Filter"))}
                          </button>
                        </li>
                        <li className="fk-sort__list col-2">
                        <NavLink
                            to="/dashboard/online-orders"
                            className="btn btn-transparent btn-secondary xsm-text text-uppercase py-2 rounded"
                          >
                            {_t(t("Online Orders"))}
                          </NavLink>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="fk-scroll--order-settle" data-simplebar >
                    <div className="t-pl-15 t-pr-15">
                      <div className="">
                        <table className="table table-bordered table-hover min-table-height mt-4">
                          <thead className="align-middle">
                            <tr>
                              <th
                                scope="col"
                                className="table-text text-capitalize align-middle text-center border-1 border"
                              >
                                {_t(t("S/L"))}
                              </th>

                              <th
                                scope="col"
                                className="table-text text-capitalize align-middle text-center border-1 border table_child_token"
                              >
                                {_t(t("Token"))}
                              </th>
                              <th
                                scope="col"
                                className="table-text text-capitalize align-middle text-center border-1 border table_child_token"
                              >
                                {_t(t("Invoice No"))}
                              </th>

                              <th
                                scope="col"
                                className="table-text text-capitalize align-middle text-center border-1 border"
                              >
                                {_t(t("Customer"))}
                              </th>

                              <th
                              scope="col"
                              className="table-text text-capitalize align-middle text-center border-1 border"
                              >
                                {_t(t("Customer Phone"))}
                              </th>

                              <th
                              scope="col"
                              className="table-text text-capitalize align-middle text-center border-1 border"
                              >
                                {_t(t("Branch"))}
                              </th>

                              <th
                                scope="col"
                                className="table-text text-capitalize align-middle text-center border-1 border"
                              >
                                {_t(t("Total bill"))}
                              </th>

                            
                              <th
                                scope="col"
                                className="table-text text-capitalize align-middle text-center border-1 border"
                              >
                                {_t(t("Status"))}
                              </th>

                              {authUserInfo.permissions !== null &&
                                checkPermission(authUserInfo.permissions, "Order History Print") ? 
                                <th
                                scope="col"
                                className="table-text text-capitalize align-middle text-center border-1 border"
                              >
                                {_t(t("print"))}
                              </th> : null}
                              
                              <th
                                scope="col"
                                className="table-text text-capitalize align-middle text-center border-1 border table_child_token"
                              >
                                {_t(t("Time"))}
                              </th>

                              <th
                                scope="col"
                                className="table-text text-capitalize align-middle text-center border-1 border"
                              >
                                {_t(t("Date"))}
                              </th>

                              <th
                                scope="col"
                                className="table-text text-capitalize align-middle text-center border-1 border"
                              >
                                {_t(t("User Name"))}
                              </th>

                              <th
                              scope="col"
                              className="table-text text-capitalize align-middle text-center border-1 border"
                              >
                                {_t(t("Ref No"))}
                              </th>
                              <th
                                scope="col"
                                className="table-text text-capitalize align-middle text-center border-1 border"
                              >
                                {_t(t("Action"))}
                              </th>
                            
                            </tr>
                          </thead>
                          <tbody className="align-middle">
                            {/* loop here, logic === !search && haveData && haveDataLegnth > 0*/}
                            {!filtered
                              ? [
                                  allOrders && [
                                    allOrders.data.length === 0 ? (
                                      <tr className="align-middle">
                                        <td
                                          scope="row"
                                          colSpan="10"
                                          className="xsm-text align-middle text-center"
                                        >
                                          {_t(t("No data available"))}
                                        </td>
                                      </tr>
                                    ) : (
                                      allOrders.data.map((item, index) => {
                                        return (
                                          <tr
                                            className="align-middle"
                                            key={index}
                                          >
                                            <th
                                              scope="row"
                                              className="table-text text-capitalize align-middle text-center"
                                            >
                                              {index +
                                                1 +
                                                (allOrders.meta.current_page -
                                                  1) *
                                                  allOrders.meta.per_page}
                                            </th>

                                            <td className="table-text text-capitalize align-middle text-center text-secondary table_child_token">
                                              #{item.token?.id}
                                            </td>

                                            <td className="table-text text-capitalize align-middle text-center text-secondary table_child_token">
                                             {item?.order_no ? "#"+ item.order_no : "-"}
                                            </td>

                                            <td className="table-text align-middle text-center table_child_ordertype">
                                              {item.customer_name}
                                            </td>

                                            <td className="table-text align-middle text-center table_child_ordertype">
                                              {item.delivery_phn_no ? item.delivery_phn_no : "-"}
                                            </td>
                                            <td className="table-text align-middle text-center table_child_branch">
                                              {item.branch_name || "-"}
                                            </td>

                                            <td className="table-text align-middle text-center">
                                              {currencySymbolLeft()}
                                              {formatPrice(item.total_payable)}
                                              {currencySymbolRight()}
                                            </td>

                                            <td className="table-text text-capitalize align-middle text-center">
                                              {parseInt(item.is_cancelled) ===
                                              0 ? (
                                                [
                                                  parseInt(item.is_ready) ===
                                                  0 ? (
                                                    <span
                                                      className="btn btn-transparent btn-secondary xsm-text text-capitalize rounded"
                                                      onClick={() => {
                                                        setCheckOrderDetails({
                                                          ...checkOrderDetails,
                                                          item: item,
                                                          settle: false,
                                                        });
                                                      }}
                                                      data-toggle="modal"
                                                      data-target="#orderDetails"
                                                    >
                                                      {_t(t("processing"))}
                                                    </span>
                                                  ) : (
                                                    <span
                                                      className="btn btn-transparent btn-success xsm-text text-capitalize px-4"
                                                      onClick={() => {
                                                        setCheckOrderDetails({
                                                          ...checkOrderDetails,
                                                          item: item,
                                                          settle: false,
                                                        });
                                                      }}
                                                      data-toggle="modal"
                                                      data-target="#orderDetails"
                                                    >
                                                      {_t(t("Ready"))}
                                                    </span>
                                                  ),
                                                ]
                                              ) : (
                                                <span
                                                  className="btn btn-transparent btn-primary xsm-text text-capitalize px-3"
                                                  onClick={() => {
                                                    setCheckOrderDetails({
                                                      ...checkOrderDetails,
                                                      item: item,
                                                      settle: false,
                                                    });
                                                  }}
                                                  data-toggle="modal"
                                                  data-target="#orderDetails"
                                                >
                                                  {_t(t("Cancelled"))}
                                                </span>
                                              )}
                                            </td>

                                            {authUserInfo.permissions !== null &&
                                              checkPermission(authUserInfo.permissions, "Order History Print") ?
                                              <td className="table-text align-middle text-center">
                                              <button
                                                className="btn btn-secondary btn-sm rounded"
                                                onClick={() => {
                                                  setCheckOrderDetails({
                                                    ...checkOrderDetails,
                                                    item: item,
                                                    settle: false,
                                                  });
                                                  isSettle.current = item.is_paid
                                                  setTimeout(() => {
                                                    handleOnlyPrint();
                                                  }, 500);
                                                }}
                                              >
                                                <i className="fa fa-print"></i>
                                              </button>
                                            </td>:null}
                                            <td className="table-text text-capitalize align-middle text-center table_child_token">
                                              <Moment format="LT">
                                                {item.token?.time}
                                              </Moment>
                                            </td>

                                            <td className="table-text text-capitalize align-middle text-center">
                                              <Moment format="LL">
                                                {item.order_date}
                                              </Moment>
                                            </td>

                                            <td className="table-text align-middle text-center">
                                              {item.user_name}
                                            </td>
                                            <td
                                            scope="col"
                                            className="table-text align-middle text-center"
                                            >
                                             {item.ref_no ? item.ref_no : "-"}
                                            </td>
                                            <td className="table-text align-middle text-center">
                                              <div className="dropdown text-capitalize">
                                                <button
                                                  className="btn t-bg-clear t-text-dark--light-40"
                                                  type="button"
                                                  data-toggle="dropdown"
                                                >
                                                  <i className="fa fa-ellipsis-h"></i>
                                                </button>
                                                {/* <div className="dropdown-menu">
                                                  <button
                                                    className="dropdown-item sm-text text-capitalize"
                                                    onClick={() => {
                                                      handleDeleteOrderConfirmation(
                                                        item
                                                      );
                                                    }}
                                                  >
                                                    <span className="t-mr-8">
                                                      <i className="fa fa-trash"></i>
                                                    </span>
                                                    {_t(t("Delete Order"))}
                                                  </button>
                                                </div> */}
                                              </div>
                                            </td>
                                            
                                          </tr>
                                        );
                                      })
                                    ),
                                  ],
                                ]
                              : [
                                  /* searched data, logic === haveData*/
                                  allOrdersForSearch && [
                                    allOrdersForSearch.data.length === 0 ? (
                                      <tr className="align-middle">
                                        <td
                                          scope="row"
                                          colSpan="10"
                                          className="table-text align-middle text-center"
                                        >
                                          {_t(t("No data available"))}
                                        </td>
                                      </tr>
                                    ) : (
                                      allOrdersForSearch.data.map(
                                        (item, index) => {
                                          return (
                                            <tr
                                              className="align-middle"
                                              key={index}
                                            >
                                              <th
                                                scope="row"
                                                className="table-text text-capitalize align-middle text-center"
                                              >
                                                {index +
                                                  1 +
                                                  (allOrdersForSearch.meta
                                                    .current_page -
                                                    1) *
                                                    allOrdersForSearch.meta
                                                      .per_page}
                                              </th>

                                              <td className="table-text text-capitalize align-middle text-center text-secondary table_child_token">
                                                 #{item.token?.id}
                                              </td>

                                              <td className="table-text text-capitalize align-middle text-center text-secondary table_child_token">
                                                {item?.order_no ? "#"+ item.order_no : "-"}
                                              </td>

                                              <td className="table-text align-middle text-center table_child_ordertype">
                                                {item.customer_name}
                                              </td>

                                              <td className="table-text align-middle text-center table_child_ordertype">
                                                {item.delivery_phn_no ? item.delivery_phn_no : "-"}
                                              </td>
                                              <td className="table-text align-middle text-center table_child_branch">
                                                {item.branch_name || "-"}
                                              </td>

                                              <td className="table-text align-middle text-center">
                                                {currencySymbolLeft()}
                                                {formatPrice(
                                                  item.total_payable
                                                )}
                                                {currencySymbolRight()}
                                              </td>

                                              <td className="table-text text-capitalize align-middle text-center">
                                                {parseInt(item.is_cancelled) ===
                                                0 ? (
                                                  [
                                                    parseInt(item.is_ready) ===
                                                    0 ? (
                                                      <span
                                                        className="btn btn-transparent btn-secondary xsm-text text-capitalize"
                                                        onClick={() => {
                                                          setCheckOrderDetails({
                                                            ...checkOrderDetails,
                                                            item: item,
                                                            settle: false,
                                                          });
                                                        }}
                                                        data-toggle="modal"
                                                        data-target="#orderDetails"
                                                      >
                                                        {_t(t("processing"))}
                                                      </span>
                                                    ) : (
                                                      <span
                                                        className="btn btn-transparent btn-success xsm-text text-capitalize px-4"
                                                        onClick={() => {
                                                          setCheckOrderDetails({
                                                            ...checkOrderDetails,
                                                            item: item,
                                                            settle: false,
                                                          });
                                                        }}
                                                        data-toggle="modal"
                                                        data-target="#orderDetails"
                                                      >
                                                        {_t(t("Ready"))}
                                                      </span>
                                                    ),
                                                  ]
                                                ) : (
                                                  <span
                                                    className="btn btn-transparent btn-primary xsm-text text-capitalize px-3"
                                                    onClick={() => {
                                                      setCheckOrderDetails({
                                                        ...checkOrderDetails,
                                                        item: item,
                                                        settle: false,
                                                      });
                                                    }}
                                                    data-toggle="modal"
                                                    data-target="#orderDetails"
                                                  >
                                                    {_t(t("Cancelled"))}
                                                  </span>
                                                )}
                                              </td>

                                              <td className="table-text align-middle text-center">
                                                <button
                                                  className="btn btn-secondary btn-sm"
                                                  onClick={() => {
                                                    setCheckOrderDetails({
                                                      ...checkOrderDetails,
                                                      item: item,
                                                      settle: false,
                                                    });
                                                    isSettle.current = item.is_paid
                                                    setTimeout(() => {
                                                      handleOnlyPrint();
                                                    }, 500);
                                                  }}
                                                >
                                                  <i className="fa fa-print"></i>
                                                </button>
                                              </td>

                                              <td className="table-text text-capitalize align-middle text-center table_child_token">
                                                <Moment format="LT">
                                                  {item.token?.time}
                                                </Moment>
                                              </td>

                                              <td className="table-text text-capitalize align-middle text-center">
                                                <Moment format="LL">
                                                  {item.order_date}
                                                </Moment>
                                              </td>

                                              <td className="table-text align-middle text-center">
                                                {item.user_name}
                                              </td>

                                              <td
                                              scope="col"
                                              className="table-text align-middle text-center"
                                              >
                                                {item.ref_no ? item.ref_no : "-"}
                                              </td>
                                              <td className="table-text align-middle text-center">
                                                <div className="dropdown text-capitalize">
                                                  <button
                                                    className="btn t-bg-clear t-text-dark--light-40"
                                                    type="button"
                                                    data-toggle="dropdown"
                                                  >
                                                    <i className="fa fa-ellipsis-h"></i>
                                                  </button>
                                                  {/* <div className="dropdown-menu">
                                                  <button
                                                    className="dropdown-item sm-text text-capitalize"
                                                    onClick={() => {
                                                      handleDeleteOrderConfirmation(
                                                        item
                                                      );
                                                    }}
                                                  >
                                                    <span className="t-mr-8">
                                                      <i className="fa fa-trash"></i>
                                                    </span>
                                                    {_t(t("Delete Order"))}
                                                  </button>
                                                </div> */}
                                                </div>
                                              </td>
                                             
                                            </tr>
                                          );
                                        }
                                      )
                                    ),
                                  ],
                                ]}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              {/* )} */}
              {/* pagination loading effect */}
              {loading === true
                ? paginationLoading()
                : [
                    // logic === !searched
                    !filtered ? (
                      <div key="fragment4">
                        <div className="t-bg-white mt-1 t-pt-5 t-pb-5">
                          <div className="row align-items-center t-pl-15 t-pr-15">
                            <div className="col-md-7 t-mb-15 mb-md-0">
                              {/* pagination function */}
                              {paginationOrderHistory(
                                allOrders,
                                setPaginatedAllOrders
                              )}
                            </div>
                            <div className="col-md-5">
                              <ul className="t-list d-flex justify-content-md-end align-items-center">
                                <li className="t-list__item">
                                  <span className="d-inline-block sm-text">
                                    {showingDataOrderHistory(allOrders)}
                                  </span>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      // if searched
                      <div className="t-bg-white mt-1 t-pt-5 t-pb-5">
                        <div className="row align-items-center t-pl-15 t-pr-15">
                          <div className="col-md-2 t-mb-15 mb-md-0">
                            <ul className="t-list d-flex">
                              <li className="t-list__item no-pagination-style">
                                <button
                                  className="btn btn-primary btn-sm"
                                  onClick={() => {
                                    // setSearchedOrders({
                                    //   ...searchedOrders,
                                    //   searched: false,
                                    //   branch: null,
                                    // });
                                    setSearched(false);
                                    setFiltered(false);
                                    setStartDate(null);
                                    setEndDate(null);
                                    setFilterBranch(null);
                                  }}
                                >
                                  {_t(t("Clear Search"))}
                                </button>
                              </li>
                            </ul>
                          </div>
                          <div className="col-md-7 t-mb-15 mb-md-0">
                            {/* pagination function */}
                            {paginationOrderHistory(
                              allOrdersForSearch,
                              (page) => {
                                // if (searched)
                                //   setPaginatedSearchedOrders(
                                //     createSearchObj(page)
                                //   );
                                // else
                                 if (filtered)
                                 setPaginatedSearchOrders(
                                    createFilterObj(page)
                                  );
                              }
                            )}
                          </div>
                          <div className="col-md-3">
                            <ul className="t-list d-flex justify-content-md-end align-items-center">
                              <li className="t-list__item">
                                <span className="d-inline-block sm-text">
                                  {showingDataOrderHistory(allOrdersForSearch)}
                                </span>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    ),
                  ]}
            </div>
          </div>
        </div>
      </main>

      {/* order print */}
      <OrderPrintView 
        componentRef={componentRef}
        checkOrderDetails={checkOrderDetails}
        taxType = {taxType}
        taxPercent = {checkOrderDetails.item && taxPercent(checkOrderDetails.item?.order_bill,checkOrderDetails.item?.vat,checkOrderDetails.item?.discount_amount)}
        isSettle = {isSettle}
        fbrInvoiceNum = {checkOrderDetails.item && checkOrderDetails.item.fbr_pos_invoice_no}
        srbInvoiceNum = {checkOrderDetails.item && checkOrderDetails.item.srb_pos_invoice_no}
        paidMoney = {checkOrderDetails.item && checkOrderDetails.item.paid_amount}
      />
      {/* order view*/}
      <OrderDetailsView
       checkOrderDetails = {checkOrderDetails}
       setCheckOrderDetails = {setCheckOrderDetails}
       returnMoneyUsd = {checkOrderDetails.item && (checkOrderDetails.item.paid_amount) - parseFloat(checkOrderDetails.item.total_payable)}
       paidMoney = {checkOrderDetails.item && checkOrderDetails.item.paid_amount}
       handleSettleOrder = {null}
      />
    </>
  );
};

export default OrderHistories;
