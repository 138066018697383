import React, { useState, useContext, useEffect } from "react";
import { NavLink } from "react-router-dom";
import Switch from "react-switch";

//pages & includes
import ManageSidebar from "../ManageSidebar";

//functions
import {
  _t,
  getCookie,
  modalLoading,
  pagination,
  paginationLoading,
  showingData,
  searchedShowingData,
  customStyle
} from "../../../../../functions/Functions";
import { useTranslation } from "react-i18next";

//axios and base url
import axios from "axios";
import { BASE_URL } from "../../../../../BaseUrl";

//3rd party packages
import { Helmet } from "react-helmet";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Select from "react-select";
import "react-datepicker/dist/react-datepicker.css";
import DatetimeRangePicker from "react-datetime-range-picker";
import Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';


//context consumer
import { RestaurantContext } from "../../../../../contexts/Restaurant";
import { FoodContext } from "../../../../../contexts/Food";
import moment from "moment";


const SchemesCrud = () => {
  const { t } = useTranslation();
  //getting context values here

  let {
    //common
    loading,
    setLoading,
    //dept-tag
    deptTagList,
    setDeptTagList,
    deptTagForSearch,

    //Scheme
    schemeList,
    setSchemeList,
    setPaginatedScheme,
    schemeForSearch,
    setSchemeForSearch,

    branchForSearch,
    orderTypeForSearch,
    // card
    cardForSearch,
  } = useContext(RestaurantContext);

  const {
    //food
    foodForPOS,
    //food group
    foodGroupForSearch,
    availableFoodGroups,
  } = useContext(FoodContext);
  
  // States hook here
  //new dept-tag
  let [newScheme, setNewScheme] = useState({
    id: null,
    name: "",
    fp_offer_name: "",
    how_much_discount: "0",
    party_sharing_discount: "0",
    menu_id: null,
    start_date: new Date(),
    end_date: new Date(),
    open_discount: 0,
    cap_amount: 0,
    status: true,
    schedule: false,
    time: 0,
    card_required: false,
    edit: false,
    uploading: false,
    item_type: "Item",
    discount_type: "",
    scheme_type: "manual_apply",
    groups: null,
    items: null,
    fromTime : null,
    toTime : null
  });
  //search result
  let [searchedScheme, setSearchedScheme] = useState({
    list: null,
    searched: false,
  });

  let [branch, setBranch] = useState(null);
  let [orderType, setOrderType] = useState(null);
  let [ibansList, setIbansList] = useState(null);
  let [weekDays, setWeekDays] = useState([]);
  let [item, setItem] = useState(null);
  let [group, setGroup] = useState(null);
  // let [defaultMenu, setDefaultMenu] = useState(null);
  let [menu, setMenu] = useState(null);
  let [foodGroup, setFoodGroup] = useState([]);
  let [foodItem, setfoodItem] = useState([]);
  let [branches, setBranches] = useState([])
  let [orderTypes, setOrderTypes] = useState([])

  //useEffect == componentDidMount

  useEffect(() => {
   
    setfoodItem(foodForPOS)
    setFoodGroup(foodGroupForSearch)
    setBranches(branchForSearch)
    setOrderTypes(orderTypeForSearch)

  }, [foodForPOS,foodGroupForSearch,branchForSearch,orderTypeForSearch]);


  //set name hook
  const handleSetScheme = (e) => {
    setNewScheme({ ...newScheme, [e.target.name]: e.target.value });
  };

  const handleSchemeType = (e) => {
    setNewScheme({
      ...newScheme,
      scheme_type: e.target.value,
      cap_amount : 0,
    });
  };

  // on change send req
  const handleChangeStatus = (newChecked) => {
    setNewScheme({
      ...newScheme, 
      status: !newScheme.status
    })
  };
  const handleChangeRequiredCard = (newChecked) => {
    setNewScheme({
      ...newScheme, 
      card_required: !newScheme.card_required,
      discount_type: (!newScheme.card_required ? "percentage" : newScheme.discount_type),
      how_much_discount:"0",
      scheme_type: "manual_apply",
    })
  };
  const handleSetItems = (items) => {
    setNewScheme({ ...newScheme, items });
    setItem(items);
  };

  const handleSetGroups = (groups) => {
    setNewScheme({ ...newScheme, groups });
    setGroup(groups);
  };

  const handleSetBranch = (branch) => {
    setNewScheme({ ...newScheme, branch });
    setBranch(branch);
  };

  const handleSetMenu = (menu) => {
    setMenu(menu);
    setNewScheme({ ...newScheme, menu_id : menu });
    
    setItem(null)
    setGroup(null)
    setBranch(null);
    setOrderType(null);
    let foodItems = foodForPOS.filter((food) => JSON.parse(food.menu_ids).includes(menu.id))
    let foodGroups = availableFoodGroups[menu.id];
    let Branches = branchForSearch.filter((branch) => JSON.parse(menu.branch_id).includes(branch.id));
    let OrderType = orderTypeForSearch.filter((ordertype) => JSON.parse(menu.associate_with).includes(ordertype.id))
  
    setBranches(Branches)
    setOrderTypes(OrderType)
    setfoodItem(foodItems)
    setFoodGroup(foodGroups)
    
  };

  const handleSetOrderType = (orderType) => {
    setNewScheme({ ...newScheme, orderType });
    setOrderType(orderType);
  };

  const handleSetCards = (ibansList) => {
    setNewScheme({...newScheme, ibansList})
    setIbansList(ibansList)
  }

  const handleChangeRequiredschedule = (newChecked) => {
    setNewScheme({
      ...newScheme, 
      schedule: !newScheme.schedule,
      // discount_type: (!newScheme.schedule ? "percentage" : newScheme.discount_type),
      // how_much_discount:"0"
    })
  };

  const handleChangeRequiredTime = () =>{
    setNewScheme({
      ...newScheme, 
      time: newScheme.time == 1 ? 0 : 1,
    })
  }
 
  const handleCheckboxChange = (event) => {
    const day = event.target.value;
    if (event.target.checked) {
      setWeekDays([...weekDays, day]);
    } else {
      setWeekDays(weekDays.filter(selectedDay => selectedDay !== day));
    }
  };

  const handleTimeToChange  = (moment) =>{
    setNewScheme({
      ...newScheme,
      toTime: moment
    })
  }

  const handleTimeFromChange = (moment) => {
    setNewScheme({
      ...newScheme,
      fromTime: moment
    })
  }

  //Save New paymentType
  const handleSaveNewScheme = (e) => {
    e.preventDefault();
    if(newScheme.discount_type === null || newScheme.discount_type === ""){
      toast.error(
        `${_t(t("Please select discount type"))}`,
        {
          position: "bottom-center",
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          className: "text-center toast-notification",
        }
      );
      return false
    }


    if(branch === null || (branch && branch?.length === 0)){
      toast.error(
        `${_t(t("Please select Branch"))}`,
        {
          position: "bottom-center",
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          className: "text-center toast-notification",
        }
      );
      return false
    }

    if(newScheme.time && (newScheme.fromTime === null || newScheme.toTime === null)){
      toast.error(
        `${_t(t("Please select from/to time"))}`,
        {
          position: "bottom-center",
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          className: "text-center toast-notification",
        }
      );
      return false
    }

    if(newScheme.time && (newScheme.fromTime === newScheme.toTime)){
      toast.error(
        `${_t(t("Please form to time can't be equal"))}`,
        {
          position: "bottom-center",
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          className: "text-center toast-notification",
        }
      );
      return false
    }

    if(orderType === null || (orderType && orderType?.length === 0)){
      toast.error(
        `${_t(t("Please select order type"))}`,
        {
          position: "bottom-center",
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          className: "text-center toast-notification",
        }
      );
      return false
    }

    setLoading(true)
    console.log("Inert ", newScheme, group, item, orderType, branch);
    let tempbranch = [];
    let tempOrderType = [];
    let tempIbansList = [];
    let tempGroups = [];
    let tempItems = [];
   

    branch.map((Item) => {
      tempbranch.push(Item.id);
    });

    orderType.map((Item) => {
      tempOrderType.push(Item.id);
    });

    if (newScheme.card_required) {
      ibansList.forEach(iban => {
        tempIbansList.push(iban.id)
      })
    }

    if (newScheme.item_type === "Group") {
      group.map((Item) => {
        tempGroups.push(Item.id);
      });
    } else {
      item.map((Item) => {
        tempItems.push(Item.id);
      });
    }

    const foodGroupUrl = BASE_URL + `/settings/new-schemes`;
    let formData = new FormData();
    formData.append("name", newScheme.name);
    formData.append("discount_type", newScheme.discount_type);
    formData.append("fp_offer_name", newScheme.fp_offer_name);
    formData.append("how_much_discount", newScheme.how_much_discount);
    formData.append("party_sharing_discount", newScheme.party_sharing_discount);
    formData.append("start_date", newScheme.start_date);
    formData.append("end_date", newScheme.end_date);
    formData.append("item_type", newScheme.item_type);
    formData.append("scheme_type", newScheme.scheme_type);
    //formData.append("menu_id", newScheme.menu_id);
    //formData.append("menu_name", newScheme.menu_name);
    
    let fromTime = null;
    let toTime = null;
    if(newScheme.time){
      fromTime = newScheme.fromTime.format('HH:mm');
      toTime = newScheme.toTime.format('HH:mm');
    }
    formData.append("time_schedule",newScheme.time);
    formData.append("from_time",fromTime);
    formData.append("to_time",toTime);
    formData.append("groups_id", tempGroups);
    formData.append("items_id", tempItems);
    formData.append("branch_id", tempbranch);
    formData.append("menu_id", newScheme.menu_id ? newScheme.menu_id.id : 0);
    formData.append("order_type_id", tempOrderType);
    formData.append("cap_amount", newScheme.cap_amount);
    if (newScheme.card_required) {
      formData.append("card_info_ids", tempIbansList);
    }
    formData.append("status", newScheme.status);
    formData.append("card_required", newScheme.card_required === true ? 1 : 0);
    formData.append("open_discount", newScheme.open_discount);
    let weekDay = JSON.stringify(weekDays);
    formData.append("schedule", weekDay);

    return axios
      .post(foodGroupUrl, formData, {
        headers: { Authorization: `Bearer ${getCookie()}` },
      })
      .then((res) => {
        setNewScheme({
          name: "",
          fp_offer_name: "",
          how_much_discount: "0",
          party_sharing_discount: "0",
          menu_id: null,
          open_discount:0,
          cap_amount: 0,
          start_date: new Date(),
          end_date: new Date(),
          status: true,
          schedule: false,
          time: 0,
          card_required: false,
          edit: false,
          uploading: false,
          discount_type: false,
          fromTime : null,
          toTime : null,
          item_type: "Item",
          scheme_type: "manual_apply",
        });
        //setAssociateWith([]);
        //setMenu(null);
        setSchemeList(res.data[0]);
        setWeekDays([]);
        setSchemeForSearch(res.data[1]);
        setSearchedScheme({
          ...searchedScheme,
          list: res.data[1],
        });
        setBranch([]);
        setGroup([]);
        setItem([]);
        setOrderType([]);
        setIbansList([]);
        setLoading(false);

        toast.success(`${_t(t("Scheme has been added"))}`, {
          position: "bottom-center",
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          className: "text-center toast-notification",
        });
        document.getElementById('close-btn').click();
      })
      .catch((error) => {
        setLoading(false);
        setNewScheme({
          ...newScheme,
          uploading: false,
        });
        if (error.response.data.errors) {
          if (error.response.data.errors.name) {
            error.response.data.errors.name.forEach((item) => {
              if (item === "A menu already exists with this name") {
                toast.error(
                  `${_t(t("A menu already exists with this name"))}`,
                  {
                    position: "bottom-center",
                    autoClose: 10000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    className: "text-center toast-notification",
                  }
                );
              }
            });
          } else {
            toast.error(`${_t(t("Please try again"))}`, {
              position: "bottom-center",
              autoClose: 10000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              className: "text-center toast-notification",
            });
          }
        }
      });
  };

  const handleDiscountType = (e) => {
    setNewScheme({
      ...newScheme,
      discount_type: e.target.value,
      cap_amount: e.target.value === "bogo" ? 0 : newScheme.cap_amount,
      card_required: e.target.value === "bogo" ? false : newScheme.card_required,
      scheme_type : "manual_apply",
      fp_offer_name: "",
      how_much_discount:"0",
      party_sharing_discount:"0",
    });
  };
  
  const handleOpenDiscount=(e)=>{
    setNewScheme({
      ...newScheme,
      open_discount: parseInt(newScheme.open_discount) === 1 ? 0 : 1,
      discount_type: parseInt(newScheme.open_discount) === 1 ? "" : "fixed",
      cap_amount: 0,
      scheme_type : "manual_apply",
      fp_offer_name: "",
      how_much_discount:"0",
      party_sharing_discount:"0",
    })
  }

  //set edit true & values
  const handleSetEdit = (id) => {
    let schemes = schemeForSearch.filter((item) => {
      return item.id === id;
    });
    console.log("schemes[0]", schemeForSearch);

    let selectOptionsBranch = [];
    const arrBranch = JSON.parse(schemes[0].branch_id);
    if (arrBranch.length !== 0) {
      for (var property of arrBranch) {
        for (var i = 0; i < branchForSearch.length; i++) {
          if (branchForSearch[i].id == property) {
            selectOptionsBranch.push(branchForSearch[i]);
          }
        }
        setBranch(selectOptionsBranch);
      }
    } else {
      setBranch([]);
    }

    let selectOptionsOrderType = [];
    const arrOrderType = JSON.parse(schemes[0].order_type_id);
    if (arrOrderType.length !== 0) {
      for (var property of arrOrderType) {
        for (var i = 0; i < orderTypeForSearch.length; i++) {
          if (orderTypeForSearch[i].id == property) {
            selectOptionsOrderType.push(orderTypeForSearch[i]);
          }
        }
        setOrderType(selectOptionsOrderType);
      }
    } else {
      setOrderType([]);
    }

    // let selectOptionsMenu = [];
    // const arrMenu = JSON.parse(schemes[0].menu_id);
    // if (arrMenu.length !== 0) {
    //   for (var menu of arrMenu) {
    //     for (var i = 0; i < deptTagForSearch.length; i++) {
    //       if (deptTagForSearch[i].id == menu) {
    //         selectOptionsMenu.push(deptTagForSearch[i]);
    //       }
    //     }
    //     setMenu(selectOptionsMenu);
    //   }
    // } else {
    //   setMenu([]);
    // }

    const selectOptionsIbans = [];
    const ibansArray = JSON.parse(schemes[0].card_info_ids)
    if (ibansArray && ibansArray.length) {
      for (const cardInfo of cardForSearch) {
        if (ibansArray.includes(cardInfo.id)) selectOptionsIbans.push(cardInfo)
      }
    }
    setIbansList(selectOptionsIbans)

    if (schemes[0].item_type === "Group") {
      let selectOptionsGroup = [];
      const arrGroups = JSON.parse(schemes[0].groups_id);
      if (arrGroups.length !== 0) {
        for (var property of arrGroups) {
          for (var i = 0; i < foodGroupForSearch.length; i++) {
            if (foodGroupForSearch[i].id == property) {
              selectOptionsGroup.push(foodGroupForSearch[i]);
            }
          }
          setGroup(selectOptionsGroup);
        }
      } else {
        setGroup([]);
      }
    } else {
      let selectOptionsItems = [];
      const arrItems = JSON.parse(schemes[0].items_id);
      if (arrItems.length !== 0) {
        for (var property of arrItems) {
          for (var i = 0; i < foodForPOS.length; i++) {
            if (foodForPOS[i].id == property) {
              selectOptionsItems.push(foodForPOS[i]);
            }
          }
          setItem(selectOptionsItems);
        }
      } else {
        setItem([]);
      }
    }
    let selectedDept;
    if (schemes[0].menu_id) {
      const menu_id = parseInt(schemes[0].menu_id);
       selectedDept = deptTagForSearch.find((item) => {
        return item.id === menu_id
      });
      setMenu(selectedDept);
    }
    setWeekDays(JSON.parse(schemes[0].schedule));
    // Combine the time with a date (e.g., today's date) and parse it into a moment object

    // Specify the time format when parsing
    let fromTime = null;
    let toTime = null;
    if(schemes[0].time_schedule){
      fromTime = moment(schemes[0].from_time, 'HH:mm');
      toTime = moment(schemes[0].to_time, 'HH:mm');     
    }
    setNewScheme({
      ...newScheme,
      id: schemes[0].id,
      name: schemes[0].name,
      discount_type: schemes[0].discount_type,
      item_type: schemes[0].item_type,
      how_much_discount: schemes[0].how_much_discount,
      fp_offer_name: schemes[0].fp_offer_name,
      open_discount: parseInt(schemes[0].open_discount),
      party_sharing_discount: schemes[0].party_sharing_discount,
      menu_id: selectedDept,
      start_date: schemes[0].start_date,
      end_date: schemes[0].end_date,
      time : parseInt(schemes[0].time_schedule),
      status: schemes[0].status == '1' ? true : false,
      schedule: schemes[0].schedule && JSON.parse(schemes[0].schedule)?.length > 0 ? true : false,
      card_required: schemes[0].card_required === 1 ? true : false,
      cap_amount: parseFloat(schemes[0].cap_amount),
      scheme_type: schemes[0].scheme_type,
      edit: true,
      fromTime : fromTime,
      toTime : toTime
    });

    console.log("schemessssss", schemes);
  };

  const handleUpdateStatus = (scheme) =>{
    setLoading(true)
    let formData ={
      id: scheme.id,
      status: !parseInt(scheme.status) == true ? 1 : 0,
    }
    const foodGroupUrl = BASE_URL + `/settings/update-schemes-status`;
    return axios
    .post(foodGroupUrl, formData, {
      headers: { Authorization: `Bearer ${getCookie()}` },
    })
    .then((res) => {
      setDeptTagList(res.data[0]);
      setSchemeForSearch(res.data[1]);
      setSearchedScheme({
        ...searchedScheme,
        list: res.data[1],
      });
      setLoading(false);
      toast.success(`${_t(t("Scheme has been updated"))}`, {
        position: "bottom-center",
        autoClose: 10000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        className: "text-center toast-notification",
      });
    })
    .catch((error) => {
      setLoading(false);
      toast.error(`${_t(t("Please try again"))}`, {
        position: "bottom-center",
        autoClose: 10000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        className: "text-center toast-notification",
      });
    });

  }

  //update food group
  const handleUpdateScheme = (e) => {
    e.preventDefault();

    if(newScheme.discount_type === null || newScheme.discount_type === ""){
      toast.error(
        `${_t(t("Please select discount type"))}`,
        {
          position: "bottom-center",
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          className: "text-center toast-notification",
        }
      );
      return false
    }


    if(branch === null || (branch && branch?.length === 0)){
      toast.error(
        `${_t(t("Please select Branch"))}`,
        {
          position: "bottom-center",
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          className: "text-center toast-notification",
        }
      );
      return false
    }

    if(newScheme.time && (newScheme.fromTime === null || newScheme.toTime === null)){
      toast.error(
        `${_t(t("Please select from/to time"))}`,
        {
          position: "bottom-center",
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          className: "text-center toast-notification",
        }
      );
      return false
    }

    if(newScheme.time && (newScheme.fromTime === newScheme.toTime)){
      toast.error(
        `${_t(t("Please form to time can't be equal"))}`,
        {
          position: "bottom-center",
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          className: "text-center toast-notification",
        }
      );
      return false
    }

    if(orderType === null || (orderType && orderType?.length === 0)){
      toast.error(
        `${_t(t("Please select order type"))}`,
        {
          position: "bottom-center",
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          className: "text-center toast-notification",
        }
      );
      return false
    }

    setNewScheme({
      ...newScheme,
      uploading: true,
    });
   setLoading(true);
    console.log("update ", newScheme, group, item, orderType, branch);
    let tempbranch = [];
    let tempOrderType = [];
    let tempIbansList = [];
    let tempGroups = [];
    let tempItems = [];

    branch.map((Item) => {
      tempbranch.push(Item.id);
    });

    orderType.map((Item) => {
      tempOrderType.push(Item.id);
    });

    if (newScheme.card_required) {
      ibansList.forEach(iban => {
        tempIbansList.push(iban.id)
      })
    }

    if (newScheme.item_type === "Group") {
      group.map((Item) => {
        tempGroups.push(Item.id);
      });
    } else {
      item.map((Item) => {
        tempItems.push(Item.id);
      });
    }

    const foodGroupUrl = BASE_URL + `/settings/update-schemes`;
    let formData = new FormData();

    formData.append("id", newScheme.id);
    formData.append("name", newScheme.name);
    formData.append("discount_type", newScheme.discount_type);
    formData.append("fp_offer_name", newScheme.fp_offer_name);
    formData.append("how_much_discount", newScheme.how_much_discount);
    formData.append("party_sharing_discount", newScheme.party_sharing_discount);
    formData.append("start_date", newScheme.start_date);
    formData.append("end_date", newScheme.end_date);
    formData.append("item_type", newScheme.item_type);
    formData.append("scheme_type", newScheme.scheme_type);
    formData.append("menu_id", newScheme.menu_id ? newScheme.menu_id.id : 0);
    //formData.append("menu_name", newScheme.menu_name);
    formData.append("groups_id", tempGroups);
    formData.append("items_id", tempItems);
    formData.append("branch_id", tempbranch);
    formData.append("order_type_id", tempOrderType);
    let fromTime = null;
    let toTime = null;
    if(newScheme.time){
      fromTime = newScheme.fromTime.format('HH:mm');
      toTime = newScheme.toTime.format('HH:mm');
    }
    formData.append("time_schedule",newScheme.time)
    formData.append("from_time",fromTime);
    formData.append("to_time",toTime);
    formData.append("cap_amount", newScheme.cap_amount);
    if (newScheme.card_required) {
      formData.append("card_info_ids", tempIbansList);
    }
    formData.append("status", newScheme.status);
    formData.append("card_required", newScheme.card_required === true ? 1 : 0);
    formData.append("open_discount", newScheme.open_discount);
    let weekDay = JSON.stringify(weekDays);
    formData.append("schedule", weekDay);

    return axios
      .post(foodGroupUrl, formData, {
        headers: { Authorization: `Bearer ${getCookie()}` },
      })
      .then((res) => {
        setNewScheme({
          name: "",
          how_much_discount: "0",
          fp_offer_name: "",
          party_sharing_discount: "0",
          menu_id: null,
          open_discount:0,
          cap_amount: 0,
          start_date: new Date(),
          end_date: new Date(),
          time: 0,
          schedule: false,
          card_required: false,
          scheme_type: "manual_apply",
          item_type: "Item",
          status: true,
          edit: false,
          uploading: false,
          fromTime : null,
          toTime : null
        });
        
        // setAssociateWith([]);
        setMenu(null);
        setDeptTagList(res.data[0]);
        setWeekDays([]);
        setSchemeForSearch(res.data[1]);
        setSearchedScheme({
          ...searchedScheme,
          list: res.data[1],
        });
        setBranch([]);
        setGroup([]);
        setItem([]);
        setOrderType([]);
        setIbansList([]);
        setLoading(false);
        toast.success(`${_t(t("Scheme has been updated"))}`, {
          position: "bottom-center",
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          className: "text-center toast-notification",
        });
        document.getElementById('close-btn').click();
      })
      .catch((error) => {
        setLoading(false);
        setNewScheme({
          ...newScheme,
          uploading: false,
        });
        if (error.response.data.errors) {
          if (error.response.data.errors.name) {
            error.response.data.errors.name.forEach((item) => {
              if (item === "A Menu already exists with this name") {
                toast.error(
                  `${_t(t("A Menu already exists with this name"))}`,
                  {
                    position: "bottom-center",
                    autoClose: 10000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    className: "text-center toast-notification",
                  }
                );
              }
            });
          } else {
            toast.error(`${_t(t("Please try again"))}`, {
              position: "bottom-center",
              autoClose: 10000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              className: "text-center toast-notification",
            });
          }
        }
      });
  };

  //search food group here
  const handleSearch = (e) => {
    let searchInput = e.target.value.toLowerCase();
    if (searchInput.length === 0) {
      setSearchedScheme({ ...searchedScheme, searched: false });
    } else {
      let searchedList = schemeForSearch.filter((item) => {
        let lowerCaseItemName = item.name.toLowerCase();
        //let lowerCaseItemGroup = item.food_group.toLowerCase();
        return lowerCaseItemName.includes(searchInput);
        //||
        //lowerCaseItemGroup.includes(searchInput)
      });
      setSearchedScheme({
        ...searchedScheme,
        list: searchedList,
        searched: true,
      });
    }
  };

  //delete confirmation modal of paymentType
  const handleDeleteConfirmation = (slug) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="card card-body">
            <h1>{_t(t("Are you sure?"))}</h1>
            <p className="text-center">{_t(t("You want to delete this?"))}</p>
            <div className="d-flex justify-content-center">
              <button
                className="btn btn-primary rounded"
                onClick={() => {
                  handleDeleteDeptTag(slug);
                  onClose();
                }}
              >
                {_t(t("Yes, delete it!"))}
              </button>
              <button className="btn btn-success ml-2 px-3 rounded" onClick={onClose}>
                {_t(t("No"))}
              </button>
            </div>
          </div>
        );
      },
    });
  };

  //delete paymentType here
  const handleDeleteDeptTag = (slug) => {
    setLoading(true);
    const foodGroupUrl = BASE_URL + `/settings/delete-schemes/${slug}`;
    return axios
      .get(foodGroupUrl, {
        headers: { Authorization: `Bearer ${getCookie()}` },
      })
      .then((res) => {
        setSchemeList(res.data[0]);
        setSchemeForSearch(res.data[1]);
        setSearchedScheme({
          ...searchedScheme,
          list: res.data[1],
        });
        setLoading(false);
        toast.success(`${_t(t("Scheme has been deleted successfully"))}`, {
          position: "bottom-center",
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          className: "text-center toast-notification",
        });
      })
      .catch(() => {
        setLoading(false);
        toast.error(`${_t(t("Please try again"))}`, {
          position: "bottom-center",
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          className: "text-center toast-notification",
        });
      });
  };

  // const handleSetAssociate = (accosiateOption) => {
  //   console.log("accosiateOption", accosiateOption);
  //   // setNewParty({ ...newParty, accosiateOption });
  //   setAssociateWith(accosiateOption);
  // };

  // const handleSetMenu = (menu) => {
  //   setNewScheme({
  //     ...newScheme,
  //     menu_id: menu.id,
  //     menu_name: menu.name,
  //   });
  //   setMenu(menu);
  // };

  const handleDefaultMenu = (menu_id) => {
    // setDefaultMenu(menu_id);

    const foodGroupUrl = BASE_URL + `/settings/update-default-menu`;

    let formData = new FormData();

    formData.append("id", menu_id);

    return axios
      .post(foodGroupUrl, formData, {
        headers: { Authorization: `Bearer ${getCookie()}` },
      })
      .then((res) => {
        setLoading(false);
        toast.success(
          `${_t(t("The menu has been set successfully on by default"))}`,
          {
            position: "bottom-center",
            autoClose: 10000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            className: "text-center toast-notification",
          }
        );
      });
  };

  return (
    <>
      <Helmet>
        <title>{_t(t("Offers"))}</title>
      </Helmet>

      {/* Add modal */}
      <div className="modal fade" id="addScheme" aria-hidden="true">
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header align-items-center">
              <div className="fk-sm-card__content">
                <h5 className="text-capitalize fk-sm-card__title">
                  {!newScheme.edit
                    ? _t(t("Add new offer"))
                    : _t(t("Update offer"))}
                </h5>
              </div>
              <button
                type="button"
                id="close-btn"
                className="btn-close"
                data-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              {/* show form or show saving loading */}
              {newScheme.uploading === false ? (
                <div key="fragment-dept-tag-1">
                  <form
                    onSubmit={
                      !newScheme.edit
                        ? handleSaveNewScheme
                        : handleUpdateScheme
                    }
                    autoComplete="off"
                  >
                    <div className="row">
                     <div className="col-8 mt-1">
                        <label htmlFor="name" className="form-label">
                          {_t(t("Offer Name"))}{" "}
                          <small className="text-danger">*</small>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="name"
                          name="name"
                          placeholder="e.g. Cricket Mania, Ramadan Deals etc"
                          value={newScheme.name || ""}
                          required
                          onChange={handleSetScheme}
                        />
                     </div>
                      <div className="mt-1 col-md-4">
                      <label
                        htmlFor="fp_offer_name"
                        className="form-label"
                        >
                        {_t(t("Foodpanda offer name"))}
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="fp_offer_name"
                          name="fp_offer_name"
                          placeholder=""
                          value={newScheme.fp_offer_name || ""}
                          onChange={handleSetScheme}
                          />
                       </div>
                    </div>

                    {/*customer comission option: before tax after tax */}
                    <div className="addons-list__item mt-3 mx-1 mb-1 fk-addons-table__body">
                      <div className="mb-2">
                        <label className="control-label">
                          {_t(t("Discount Type"))}
                        </label>
                      </div>

                      <div className="mx-checkbox d-inline-block mr-4">
                        <input
                          type="radio"
                          className="mx-checkbox__input mx-checkbox__input-solid mx-checkbox__input-solid--danger mx-checkbox__input-sm"
                          id="discount_type_fixed"
                          name="discount_type"
                          value="fixed"
                          onChange={(e) => {
                            handleDiscountType(e);
                          }}
                          checked={newScheme.discount_type === "fixed" ? 1 : 0}
                          disabled={newScheme.card_required}
                        />

                        <label htmlFor="discount_type_fixed" className="mx-checkbox__text text-capitalize t-text-heading t-ml-8 fk-addons-table__body-text sm-text">
                          {_t(t("Fixed Amount"))}
                        </label>
                      </div>

                      <div className="mx-checkbox d-inline-block mr-4">
                        <input
                          disabled={newScheme.open_discount}
                          type="radio"
                          className="mx-checkbox__input mx-checkbox__input-solid mx-checkbox__input-solid--danger mx-checkbox__input-sm"
                          id="discount_type_percentage"
                          name="discount_type"
                          value="percentage"
                          onChange={(e) => {
                            handleDiscountType(e);
                          }}
                          checked={
                            newScheme.discount_type === "percentage" ? 1 : 0
                          }
                        />

                        <label htmlFor="discount_type_percentage" className="mx-checkbox__text text-capitalize t-text-heading t-ml-8 fk-addons-table__body-text sm-text">
                          {_t(t("Percentage"))}
                        </label>
                      </div>
                      <div className="mx-checkbox d-inline-block mr-4">
                      <input
                       disabled={newScheme.open_discount}
                        type="radio"
                        className="mx-checkbox__input mx-checkbox__input-solid mx-checkbox__input-solid--danger mx-checkbox__input-sm"
                        id="discount_type_bogo"
                        name="discount_type"
                        value="bogo"
                        onChange={(e) => {
                          handleDiscountType(e);
                        }}
                        checked={
                          newScheme.discount_type === "bogo" ? 1 : 0
                        }
                      />
                      <label htmlFor="discount_type_bogo" className="mx-checkbox__text text-capitalize t-text-heading t-ml-8 fk-addons-table__body-text sm-text">
                        {_t(t("Bogo Scheme"))}
                      </label>
                    </div>

                      <div className="mx-checkbox d-flex align-items-center mr-4 my-2" >
                      <input
                        type="checkbox"
                        className="mx-checkbox__input mx-checkbox__input-solid mx-checkbox__input-solid--danger mx-checkbox__input-sm"
                        id="discount_type_open"
                        name="open_discount"
                        value="open_discount"
                       // style={{fontSize:"18px"}}
                        onChange={(e) => handleOpenDiscount(e)}
                        checked={parseInt(newScheme.open_discount) === 1}
                        disabled={newScheme.card_required}
                        />

                        <label htmlFor="discount_type_open" className="mx-checkbox__text text-capitalize t-text-heading t-ml-8 fk-addons-table__body-text sm-text">
                          {_t(t("Open Discount"))}
                        </label>
                      </div>
                    </div>

                    {/* <div className="mt-3">
                      <label htmlFor="menu" className="form-label">
                        {_t(t("Party"))}
                        <small className="text-primary">*</small>
                      </label>
                      <Select
                        value={menu}
                        options={partyForSearch}
                        getOptionLabel={(option) => option.name}
                        getOptionValue={(option) => option.name}
                        classNamePrefix="select"
                        maxMenuHeight="200px"
                        onChange={handleSetMenu}
                        placeholder={_t(t("Please select Party")) + ".."}
                      />
                    </div> */}
                    <div className="row">
                      <div className="mt-3 col-md-6">
                        <label
                          htmlFor="from_date"
                          className="form-label"
                        >
                          {_t(t("From Date"))}
                          <small className="text-danger">*</small>
                        </label>
                        {/* <DatePicker
                          selected={startDate}
                          onChange={(date) => setStartDate(date)}
                          peekNextMonth
                          showMonthDropdown
                          showYearDropdown
                          dropdownMode="select"
                          className="form-control sm-text py-2 t-mb-15 mb-md-0"
                          placeholderText={_t(t("From date"))}
                          shouldCloseOnSelect={false}
                        /> */}
                      </div>

                      <div className="mt-3 col-md-6">
                        <label
                          htmlFor="to_date"
                          className="form-label"
                        >
                          {_t(t("To date"))}
                          <small className="text-danger">*</small>
                        </label>
                        {/* <DatePicker
                            selected={endDate}
                            onChange={(date) => setEndDate(date)}
                            peekNextMonth
                            showMonthDropdown
                            showYearDropdown
                            dropdownMode="select"
                            className="form-control sm-text py-2"
                            placeholderText={_t(t("To date"))}
                            shouldCloseOnSelect={false}
                          /> */}
                      </div>
                    </div>
                    <DatetimeRangePicker
                      className="d-flex custom-2-field row"
                      closeOnSelect={true}
                      startDate={newScheme.start_date || new Date()}
                      endDate={newScheme.end_date || new Date()}
                      onChange={(e) => {
                        setNewScheme({
                          ...newScheme,
                          start_date: e.start,
                          end_date: e.end,
                        });
                      }}
                    />
                    <div className="row">
                      <div className="mt-3 col-md-6">
                        <label
                          htmlFor="how_much_discount"
                          className="form-label"
                        >
                          {_t(t("How much discount"))}
                          <small className="text-danger">*</small>
                        </label>
                        <input
                          type="number"
                          step="0.01"
                          className="form-control"
                          id="how_much_discount"
                          name="how_much_discount"
                          min="0"
                          placeholder=""
                          required
                          value={newScheme.how_much_discount || ""}
                          onChange={handleSetScheme}
                          disabled={newScheme.card_required || newScheme.discount_type === "bogo" || newScheme.open_discount}
                        />
                      </div>
                      <div className="mt-3 col-md-6">
                        <label
                          htmlFor="party_sharing_discount"
                          className="form-label"
                        >
                          {_t(t("Sharing with party on discount"))}
                          <small className="text-danger">*</small>
                        </label>
                        <input
                          disabled={newScheme.discount_type==="bogo" || newScheme.open_discount}
                          type="number"
                          step="0.01"
                          className="form-control"
                          id="party_sharing_discount"
                          name="party_sharing_discount"
                          min="0"
                          placeholder=""
                          value={newScheme.party_sharing_discount || ""}
                          required
                          onChange={handleSetScheme}
                        />
                      </div>
                    </div>
                    <div className="mt-3">
                    <label htmlFor="Groups" className="form-label">
                      {_t(t("Menu"))}
                    </label>
                    <Select
                      value={menu}
                      options={deptTagForSearch}
                      getOptionLabel={(option) => option.name}
                      getOptionValue={(option) => option.id}
                      classNamePrefix="select"
                      maxMenuHeight="200px"
                      closeMenuOnSelect={true}
                      styles={customStyle}
                      onChange={handleSetMenu}
                      placeholder={_t(t("Please select menu")) + ".."}
                    />
                   </div>
                    <div className="addons-list__item mt-3 mx-1 mb-1 fk-addons-table__body">
                      <label className="form-label d-block mb-0">
                        {_t(t("Select Groups / Items"))}{" "}
                        <small className="text-danger">*</small>
                      </label>
                      <label className="mx-checkbox d-inline-block mr-3">
                        <input
                          type="radio"
                          value="Group"
                          id="groups"
                          className="mx-checkbox__input mx-checkbox__input-solid mx-checkbox__input-solid--danger mx-checkbox__input-sm"
                          name="payment_type"
                          //checked={1}
                          onChange={() => {
                            setNewScheme({
                              ...newScheme,
                              item_type: "Group",
                            });
                          }}
                          checked={newScheme.item_type === "Group" ? 1 : 0}
                        />
                        <span className="mx-checkbox__text text-capitalize t-text-heading t-ml-8 fk-addons-table__body-text sm-text">
                          Groups / Category
                        </span>
                      </label>
                      <label className="mx-checkbox d-inline-block mr-3">
                        <input
                          type="radio"
                          value="Item"
                          className="mx-checkbox__input mx-checkbox__input-solid mx-checkbox__input-solid--danger mx-checkbox__input-sm"
                          name="payment_type"
                          onChange={() => {
                            setNewScheme({
                              ...newScheme,
                              item_type: "Item",
                            });
                          }}
                          checked={newScheme.item_type === "Item" ? 1 : 0}
                        />
                        <span className="mx-checkbox__text text-capitalize t-text-heading t-ml-8 fk-addons-table__body-text sm-text">
                          Items
                        </span>
                      </label>
                    </div>
                    {newScheme.item_type === "Group" ? (
                      <div className="mt-3">
                        <label htmlFor="Groups" className="form-label">
                          {_t(t("Groups"))}
                          <small className="text-danger">*</small>
                        </label>
                        <Select
                          value={group}
                          options={foodGroup && foodGroup}
                          getOptionLabel={(option) => option.name}
                          getOptionValue={(option) => option.id}
                          classNamePrefix="select"
                          maxMenuHeight="200px"
                          isMulti={true}
                          styles={customStyle}
                          closeMenuOnSelect={false}
                          onChange={handleSetGroups}
                          placeholder={_t(t("Please select Group")) + ".."}
                        />
                      </div>
                    ) : (
                      <div className="mt-3">
                        <label htmlFor="Items" className="form-label">
                          {_t(t("Items"))}
                          <small className="text-danger">*</small>
                        </label>
                        <Select
                          value={item}
                          options={foodItem && foodItem}
                          getOptionLabel={(option) => option.name}
                          getOptionValue={(option) => option.id}
                          classNamePrefix="select"
                          maxMenuHeight="200px"
                          isMulti={true}
                          styles={customStyle}
                          closeMenuOnSelect={false}
                          onChange={handleSetItems}
                          placeholder={_t(t("Please select Item")) + ".."}
                        />
                      </div>
                    )}
                    <div className="mt-3">
                      <label htmlFor="Groups" className="form-label">
                        {_t(t("Branch"))}
                        <small className="text-danger">*</small>
                      </label>
                      <Select
                        value={branch}
                        options={branches && branches}
                        getOptionLabel={(option) => option.name}
                        getOptionValue={(option) => option.id}
                        classNamePrefix="select"
                        maxMenuHeight="200px"
                        isMulti={true}
                        styles={customStyle}
                        closeMenuOnSelect={false}
                        onChange={handleSetBranch}
                        placeholder={_t(t("Please select Branch")) + ".."}
                      />
                    </div>
                  
                   <div className="mt-3 row">
                    <div className=" col-8">
                    <label htmlFor="Groups" className="form-label">
                        {_t(t("Order Types"))}
                        <small className="text-danger">*</small>
                      </label>
                      <Select
                        value={orderType}
                        options={orderTypes && orderTypes}
                        getOptionLabel={(option) => option.name}
                        getOptionValue={(option) => option.id}
                        classNamePrefix="select"
                        maxMenuHeight="200px"
                        isMulti={true}
                        styles={customStyle}
                        closeMenuOnSelect={false}
                        onChange={handleSetOrderType}
                        placeholder={_t(t("Please select Order Types")) + ".."}
                        />
                        </div>
                        <div className="col-4">
                        <label htmlFor="cap_amount" className="form-label">
                          {_t(t("Cap Amount"))}
                        </label>
                        <input
                          disabled = {newScheme.scheme_type === "enable_by_default" || newScheme.discount_type==="bogo" || newScheme.open_discount}
                          type="number"
                          step="0.1"
                          className="form-control"
                          min="0"
                          id="cap_amount"
                          name="cap_amount"
                          placeholder=""
                          value={newScheme.cap_amount}
                          onChange={handleSetScheme}
                        />
                      </div>
                     </div>
                    {/*tax type: before tax after tax */}
                    <div className="row mt-4">
                    <div className="form-radio col-6">
                      <div className="d-inline mr-4">
                        <input
                          disabled={newScheme.discount_type==="bogo" || newScheme.open_discount || newScheme.card_required}
                          type="radio"
                          className="form-check-input pointer-cursor"
                          id="scheme_type_enable"
                          name="scheme_type_setting"
                          value="enable_by_default"
                          onChange={(e) => {
                            handleSchemeType(e);
                          }}
                          checked={
                            newScheme.scheme_type === "enable_by_default"
                              ? 1
                              : 0
                          }
                        />
                        <label
                          className="form-radio-label pointer-cursor ml-2"
                          htmlFor="scheme_type_enable"
                        >
                          {_t(t("Enable By Default"))}
                        </label>
                      </div>

                      <div className="d-inline mr-4">
                        <input
                          type="radio"
                          className="form-check-input pointer-cursor"
                          id="scheme_type_manual"
                          name="scheme_type_setting"
                          value="manual_apply"
                          onChange={(e) => {
                            handleSchemeType(e);
                          }}
                          checked={
                            newScheme.scheme_type === "manual_apply" ? 1 : 0
                          }
                        />
                        <label
                          className="form-radio-label pointer-cursor ml-2"
                          htmlFor="scheme_type_manual"
                        >
                          {_t(t("Manual Apply"))}
                        </label>
                      </div>
                    </div>
                    <div className="col-6 row">
                    <div className="form-check pl-0 col-6">
                      <Switch
                        onChange={handleChangeStatus}
                        checked={newScheme.status}
                        className="react-switch"
                        name="status"
                        height={22}
                        width={44}
                        offColor="#ee5253"                        
                      />
                      {/* <input
                        type="checkbox"
                        className="form-check-input"
                        id="status"
                        checked={newScheme.status}
                        onChange={(e) => {
                          setNewScheme({
                            ...newScheme,
                            status: e.target.checked,
                          });
                        }}
                      /> */}
                      <label className="pl-2 align-top">Status</label>
                    </div>

                    <div className="form-check pl-0 col-6">
                      <Switch
                       disabled={newScheme.discount_type==="bogo" || newScheme.open_discount}
                        onChange={handleChangeRequiredCard}
                        checked={newScheme.card_required}
                        className="react-switch"
                        name="card_required"
                        height={22}
                        width={44}
                        offColor="#ee5253"
                      />
                    <label className="pl-2 align-top">Card required</label>
                    </div>
                    </div>
                    </div>
                    <div className="row mt-4">
                     <div className="col-6">
                      <div className="form-check pl-0 col-6 d-flex align-items-center">
                        <Switch
                          onChange={handleChangeRequiredschedule}
                          checked={newScheme.schedule}
                          className="react-switch"
                          name="schedule"
                          height={22}
                          width={44}
                          offColor="#ee5253"
                         />
                         <label className="pl-2 align-top">Schedule</label>
                        </div>
                       </div>
                       <div className="col-6">
                      <div className="form-check pl-0 col-6 d-flex align-items-center">
                        <Switch
                          onChange={handleChangeRequiredTime}
                          checked={newScheme.time}
                          className="react-switch"
                          name="time"
                          height={22}
                          width={44}
                          offColor="#ee5253"
                         />
                         <label className="pl-2 align-top">Time</label>
                        </div>
                       </div>
                    </div>  

                    <div className="row mt-3 mx-0">
                     <div className="col-6">
                     {newScheme.schedule ?
                        <span>
                        <div className="col-12 mb-1">
                        <div className="form-check">
                          <input
                            type="checkbox"
                            className="form-check-input pointer-cursor"
                            value="Mon"
                            name="mon"
                            id="mon"
                            onChange={handleCheckboxChange}
                            checked={weekDays.includes("Mon")}
                            />
                            <label
                              className="form-check-label  pointer-cursor"
                              htmlFor="mon"
                            >
                              {_t(t("Monday"))}
                            </label>
                         </div>
                        </div>
                        <div className="col-12 mb-1">
                          <div className="form-check">
                            <input
                              type="checkbox"
                              className="form-check-input pointer-cursor"
                              value="Tue"
                              name="tue"
                              id="tue"
                              onChange={handleCheckboxChange}
                              checked={weekDays.includes("Tue")}
                              />
                              <label
                                className="form-check-label  pointer-cursor"
                                htmlFor="tue"
                              >
                              {_t(t("Tuesday"))}
                            </label>
                          </div>
                        </div>
                        <div className="col-12 mb-1">
                          <div className="form-check">
                            <input
                              type="checkbox"
                              className="form-check-input pointer-cursor"
                              value="Wed"
                              name="wed"
                              id="wed"
                              onChange={handleCheckboxChange}
                              checked={weekDays.includes("Wed")}
                              />
                              <label
                                className="form-check-label  pointer-cursor"
                                htmlFor="wed"
                              >
                              {_t(t("Wednesday"))}
                            </label>
                          </div>
                        </div>
                        <div className="col-12 mb-1">
                        <div className="form-check">
                          <input
                            type="checkbox"
                            className="form-check-input pointer-cursor"
                            value="Thu"
                            name="thu"
                            id="thu"
                            onChange={handleCheckboxChange}
                            checked={weekDays.includes("Thu")}
                            />
                            <label
                              className="form-check-label  pointer-cursor"
                              htmlFor="thu"
                            >
                            {_t(t("Thursday"))}
                          </label>
                        </div>
                        </div>
                          <div className="col-12 mb-1">
                            <div className="form-check">
                              <input
                                type="checkbox"
                                className="form-check-input pointer-cursor"
                                value="Fri"
                                name="fri"
                                id="fri"
                                onChange={handleCheckboxChange}
                                checked={weekDays.includes("Fri")}
                              />
                              <label
                                className="form-check-label  pointer-cursor"
                                htmlFor="fri"
                                >
                                {_t(t("Friday"))}
                              </label>
                            </div>
                          </div>
                          <div className="col-12 mb-1">
                            <div className="form-check">
                            <input
                              type="checkbox"
                              className="form-check-input pointer-cursor"
                              value="Sat"
                              name="sat"
                              id="sat"
                              onChange={handleCheckboxChange}
                              checked={weekDays.includes("Sat")}
                              />
                              <label
                                className="form-check-label  pointer-cursor"
                                htmlFor="sat"
                              >
                              {_t(t("Saturday"))}
                            </label>
                            </div>
                        </div>
                        <div className="col-12 mb-1">
                        <div className="form-check">
                          <input
                            type="checkbox"
                            className="form-check-input pointer-cursor"
                            value="Sun"
                            name="sun"
                            id="sun"
                            onChange={handleCheckboxChange}
                            checked={weekDays.includes("Sun")}
                            />
                            <label
                              className="form-check-label  pointer-cursor"
                              htmlFor="sun"
                            >
                            {_t(t("Sunday"))}
                          </label>
                        </div>
                        </div>
                        </span>
                        : null
                      }
                      </div>
                       {
                        newScheme.time ?
                        <>
                          <div className="mt-3 col-md-3">
                          <label
                            htmlFor="from_date"
                            className="form-label"
                          >
                            {_t(t("From Time"))}
                            <small className="text-danger">*</small>
                          </label>
                          <Datetime
                          value={newScheme.fromTime}
                          onChange={handleTimeFromChange}
                          dateFormat={false} // Hide the date picker
                          className="col"
                          timeConstraints={{
                            minutes: { step: 5 }, // Customize time selection steps
                           }}
                           />
                         </div>
                         <div className="mt-3 col-md-3">
                          <label
                            htmlFor="from_date"
                            className="form-label"
                          >
                            {_t(t("To Time"))}
                            <small className="text-danger">*</small>
                          </label>
                          <Datetime
                          //input={false} // Hide the input field for time selection only
                          value={newScheme.toTime}
                          onChange={handleTimeToChange}
                          dateFormat={false} // Hide the date picker
                          className="col"
                          timeConstraints={{
                            minutes: { step: 5 }, // Customize time selection steps
                           }}
                           />
                         </div>
                        </>
                        : 
                        null
                       }
                      </div>

                    {newScheme.card_required ?
                    <div className="row mt-4">
                      <div className="col-6">
                        <label htmlFor="Groups" className="form-label">
                          {_t(t("IBAN Numbers"))}
                          <small className="text-danger">*</small>
                        </label>
                        <Select
                          value={ibansList}
                          options={cardForSearch}
                          getOptionLabel={(option) => option.iban_number}
                          getOptionValue={(option) => option.iban_number}
                          classNamePrefix="select"
                          maxMenuHeight="200px"
                          isMulti={true}
                          styles={customStyle}
                          required
                          closeMenuOnSelect={false}
                          onChange={handleSetCards}
                          placeholder={_t(t("Please select IBAN numbers")) + ".."}
                        />
                      </div>
                      {
                        /*
                      <div className="col-6">
                        <label htmlFor="Groups" className="form-label">
                          {_t(t("Cap Amount"))}
                          <small className="text-danger">*</small>
                        </label>
                        <input
                          type="number"
                          step="0.01"
                          className="form-control"
                          id="cap_amount"
                          name="cap_amount"
                          min="0"
                          placeholder=""
                          value={newScheme.cap_amount || ""}
                          required
                          onChange={handleSetScheme}
                        />
                      </div>
                      */}
                    </div>
                    : null
                    }

                    <div className="mt-4">
                      <div className="row d-flex justify-content-end">
                        <div className="col-3">
                          <button
                            disabled={loading}
                            type="submit"
                            className="btn btn-success w-100 xsm-text text-uppercase t-width-max rounded"
                          >
                            {!newScheme.edit ? _t(t("Save")) : _t(t("Update"))}
                          </button>
                        </div>
                        <div className="col-3">
                          <button
                            type="button"
                            className="btn btn-primary w-100 xsm-text text-uppercase t-width-max rounded"
                            data-dismiss="modal"
                          >
                            {_t(t("Close"))}
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              ) : (
                <div key="fragment2">
                  <div className="text-center text-primary font-weight-bold text-uppercase">
                    {_t(t("Please wait"))}
                  </div>
                  {modalLoading(3)}
                  <div className="mt-4">
                    <div className="row d-flex justify-content-end">
                      <div className="col-3">
                        <button
                          type="button"
                          className="btn btn-success w-100 xsm-text text-uppercase t-width-max rounded"
                          onClick={(e) => {
                            e.preventDefault();
                          }}
                        >
                          {!newScheme.edit ? _t(t("Save")) : _t(t("Update"))}
                        </button>
                      </div>
                      <div className="col-3">
                        <button
                          type="button"
                          className="btn btn-primary w-100 xsm-text text-uppercase t-width-max rounded"
                          data-dismiss="modal"
                        >
                          {_t(t("Close"))}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      {/* Add modal Ends*/}

      {/* main body */}
      <main id="main" data-simplebar>
        <div className="container-fluid">
          <div className="row t-mt-10 gx-2">
            {/* left Sidebar */}
            <div className="col-lg-2 col-xxl-2 t-mb-30 mb-lg-0">
              <ManageSidebar />
            </div>
            {/* left Sidebar ends */}

            {/* Rightbar contents */}
            <div className="col-lg-10 col-xxl-10 t-mb-30 mb-lg-0">
              <div className="t-bg-white">
                <div className="fk-scroll--pos-table-menu" data-simplebar>
                  <div className="t-pl-15 t-pr-15">
                    {/* Loading effect */}
                    {/* {newScheme.uploading === true || loading === true ? (
                      tableLoading()
                    ) : ( */}
                      <div key="fragment3">
                        {/* next page data spin loading */}
                        <div className={`${(loading) && "loadingBlur"}`}></div>
                          <div className={`${(loading) && "lds-roller"}`}><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                        {/* spin loading ends */}

                        <div className="row gx-2 align-items-center t-pt-15 t-pb-15">
                          <div className="col-md-6 col-lg-5 t-mb-15 mb-md-0">
                            <ul className="t-list fk-breadcrumb">
                              <li className="fk-breadcrumb__list">
                                <span className="t-link fk-breadcrumb__link text-capitalize">
                                  {!searchedScheme.searched
                                    ? _t(t("Offer List"))
                                    : _t(t("Search Result"))}
                                </span>
                              </li>
                            </ul>
                          </div>
                          <div className="col-md-6 col-lg-7">
                            <div className="row gx-3 align-items-center">
                              {/* Search group */}
                              <div className="col-md-9 t-mb-15 mb-md-0">
                                <div className="input-group">
                                  <div className="form-file">
                                    <input
                                      type="text"
                                      className="form-control border-0 form-control--light-1 rounded-left"
                                      placeholder={_t(t("Search")) + ".."}
                                      onChange={handleSearch}
                                    />
                                  </div>
                                  <button
                                    className="btn btn-primary rounded-right"
                                    type="button"
                                  >
                                    <i
                                      className="fa fa-search"
                                      aria-hidden="true"
                                    ></i>
                                  </button>
                                </div>
                              </div>

                              {/* Add group modal trigger button */}
                              <div className="col-md-3 text-md-right">
                                {/* <button
                                  type="button"
                                  className="btn btn-primary xsm-text text-uppercase btn-lg btn-block rounded"
                                  data-toggle="modal"
                                  data-target="#addScheme"
                                  onClick={() => {
                                    setBranch(null);
                                    setOrderType(null);
                                    setIbansList(null);
                                    setWeekDays([]);
                                    setItem(null);
                                    setGroup(null)
                                    setNewScheme({
                                      ...newScheme,
                                      name: "",
                                      how_much_discount: "0",
                                      fp_offer_name: "",
                                      party_sharing_discount: "0",
                                      menu_id: null,
                                      open_discount:0,
                                      cap_amount: 0,
                                      start_date: new Date(),
                                      end_date: new Date(),
                                      schedule: false,
                                      time: 0,
                                      status: true,
                                      card_required: false,
                                      scheme_type: "manual_apply",
                                      item_type: "Item",
                                      edit: false,
                                      uploading: false,
                                      fromTime : null,
                                      toTime : null
                                    });
                                  }}
                                >
                                  {_t(t("add new"))}
                                </button> */}
                                  <NavLink
                                    to="/dashboard/manage/food/add-schemes-add"
                                    className="btn btn-primary xsm-text text-uppercase btn-lg btn-block rounded"
                                  >
                                    {_t(t("add new"))}
                                  </NavLink>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* Table */}
                        <div className="table-responsive">
                          <table className="table table-bordered table-hover min-table-height">
                            <thead className="align-middle">
                              <tr>
                                <th
                                  scope="col"
                                  className="sm-text text-capitalize align-middle text-center border-1 border"
                                >
                                  {_t(t("S/L"))}
                                </th>

                                <th
                                  scope="col"
                                  className="sm-text text-capitalize align-middle text-center border-1 border"
                                >
                                  {_t(t("Scheme Name"))}
                                </th>

                                <th
                                  scope="col"
                                  className="sm-text text-capitalize align-middle text-center border-1 border"
                                >
                                  {_t(t("Menu"))}
                                </th>

                                <th
                                  scope="col"
                                  className="sm-text text-capitalize align-middle text-center border-1 border"
                                >
                                  {_t(t("Status"))}
                                </th>

                                <th
                                  scope="col"
                                  className="sm-text text-capitalize align-middle text-center border-1 border"
                                >
                                  {_t(t("Action"))}
                                </th>
                              </tr>
                            </thead>
                            <tbody className="align-middle">
                              {/* loop here, logic === !search && haveData && haveDataLegnth > 0*/}
                              {!searchedScheme.searched
                                ? [
                                    schemeList && [
                                      schemeList.data.length === 0 ? (
                                        <tr className="align-middle">
                                          <td
                                            scope="row"
                                            colSpan="6"
                                            className="xsm-text align-middle text-center"
                                          >
                                            {_t(t("No data available"))}
                                          </td>
                                        </tr>
                                      ) : (
                                        schemeList.data.map((item, index) => {
                                          return (
                                            <tr
                                              className="align-middle"
                                              key={index}
                                            >
                                              <th
                                                scope="row"
                                                className="xsm-text text-capitalize align-middle text-center"
                                              >
                                                {index +
                                                  1 +
                                                  (schemeList.current_page -
                                                    1) *
                                                    schemeList.per_page}
                                              </th>

                                              <td className="xsm-text text-capitalize align-middle text-center">
                                                {item.name}
                                              </td>

                                              <td className="xsm-text text-capitalize align-middle text-center">
                                                {item.menu?.name || "-"}
                                              </td>

                                              <td className="xsm-text text-capitalize align-middle text-center">
                                                <Switch
                                                  checked={item?.status === "1"}
                                                  name="status"
                                                  onChange={(e) => {
                                                    handleUpdateStatus(item)
                                                  
                                                  }}
                                                  height={22}
                                                  width={44}
                                                  offColor="#ee5253"
                                                  />
                                                {/*item.status === "1" ? (
                                                  <span
                                                    style={{
                                                      color: "Green",
                                                      fontWeight: "bold",
                                                    }}
                                                  >
                                                    ON
                                                  </span>
                                                ) : (
                                                  <span
                                                    style={{
                                                      color: "Red",
                                                      fontWeight: "bold",
                                                    }}
                                                  >
                                                    OFF
                                                  </span>
                                                  )*/}
                                              </td>

                                              <td className="xsm-text text-capitalize align-middle text-center">
                                                {/* <div className="dropdown">
                                                  <button
                                                    className="btn t-bg-clear t-text-dark--light-40"
                                                    type="button"
                                                    data-toggle="dropdown"
                                                  >
                                                    <i className="fa fa-ellipsis-h"></i>
                                                  </button>
                                                  <div className="dropdown-menu">
                                                    <button
                                                      className="dropdown-item sm-text text-capitalize"
                                                      onClick={() =>
                                                        // handleSetEdit(item.id)
                                                        history.push(`/dashboard/manage/food/add-schemes-edit/${item.id}`)
                                                      }
                                                      // data-toggle="modal"
                                                      // data-target="#addScheme"
                                                    >
                                                      <span className="t-mr-8">
                                                        <i className="fa fa-pencil"></i>
                                                      </span>
                                                      {_t(t("Edit"))}
                                                    </button>

                                                    
                                                  </div>
                                                </div> */}
                                                {/* <NavLink
                                                    className="btn btn-primary btn-sm ml-2 rounded-sm"
                                                    to={`/dashboard/manage/food/add-schemes-edit/${item.id}`}                                                       
                                                  >
                                                    <i className="fa fa-pencil" aria-hidden="true" style={{cursor: "pointer", fontSize: "1rem", color:"white"}}></i>                                                
                                                  </NavLink> */}
                                                  <NavLink
                                                  className="btn btn-primary ml-2 rounded-sm"
                                                  to={`/dashboard/manage/food/add-schemes-edit/${item.id}`} 
                                                  style={{height:"1.7rem",width:"1.7rem"}}                                                                                                                                            
                                                 >
                                                  <div className="d-flex align-items-center justify-content-center">
                                                    <i className="fa fa-pencil edit-icon" aria-hidden="true"></i>
                                                  </div>
                                                 </NavLink>
                                              </td>
                                            </tr>
                                          );
                                        })
                                      ),
                                    ],
                                  ]
                                : [
                                    /* searched data, logic === haveData*/
                                    searchedScheme && [
                                      searchedScheme.list.length === 0 ? (
                                        <tr className="align-middle">
                                          <td
                                            scope="row"
                                            colSpan="6"
                                            className="xsm-text align-middle text-center"
                                          >
                                            {_t(t("No data available"))}
                                          </td>
                                        </tr>
                                      ) : (
                                        searchedScheme.list.map(
                                          (item, index) => {
                                            return (
                                              <tr
                                                className="align-middle"
                                                key={index}
                                              >
                                                <th
                                                  scope="row"
                                                  className="xsm-text text-capitalize align-middle text-center"
                                                >
                                                  {index +
                                                    1 +
                                                    (schemeList.current_page -
                                                      1) *
                                                      schemeList.per_page}
                                                </th>

                                                <td className="xsm-text text-capitalize align-middle text-center">
                                                  {item.name}
                                                </td>

                                                <td className="xsm-text text-capitalize align-middle text-center">
                                                  {item.menu?.name || "-"}
                                                </td>

                                                <td className="xsm-text text-capitalize align-middle text-center">
                                                  <Switch
                                                  checked={item?.status === "1"}
                                                  name="status"
                                                  onChange={(e) => {
                                                    handleUpdateStatus(item)
                                                  
                                                  }}
                                                  height={22}
                                                  width={44}
                                                  offColor="#ee5253"
                                                  />
                                                  {/*item.status === "1" ? (
                                                    <span
                                                      style={{
                                                        color: "Green",
                                                        fontWeight: "bold",
                                                      }}
                                                    >
                                                      ON
                                                    </span>
                                                  ) : (
                                                    <span
                                                      style={{
                                                        color: "Red",
                                                        fontWeight: "bold",
                                                      }}
                                                    >
                                                      OFF
                                                    </span>
                                                    )*/}
                                                </td>

                                                <td className="xsm-text text-capitalize align-middle text-center">
                                                  {/* <div className="dropdown">
                                                    <button
                                                      className="btn t-bg-clear t-text-dark--light-40"
                                                      type="button"
                                                      data-toggle="dropdown"
                                                    >
                                                      <i className="fa fa-ellipsis-h"></i>
                                                    </button>
                                                    <div className="dropdown-menu">
                                                      <button
                                                        className="dropdown-item sm-text text-capitalize"
                                                        onClick={() =>
                                                          handleSetEdit(item.id)
                                                        }
                                                        data-toggle="modal"
                                                        data-target="#addScheme"
                                                      >
                                                        <span className="t-mr-8">
                                                          <i className="fa fa-pencil"></i>
                                                        </span>
                                                        {_t(t("Edit"))}
                                                      </button>

                                                      <button
                                                        className="dropdown-item sm-text text-capitalize"
                                                        onClick={() => {
                                                          handleDeleteConfirmation(
                                                            item.id
                                                          );
                                                        }}
                                                      >
                                                        <span className="t-mr-8">
                                                          <i className="fa fa-trash"></i>
                                                        </span>
                                                        {_t(t("Delete"))}
                                                      </button>
                                                    </div>
                                                  </div> */}
                                                    {/* <NavLink
                                                    className="btn btn-primary btn-sm ml-2 rounded-sm"
                                                    to={`/dashboard/manage/food/add-schemes-edit/${item.id}`}                                                       
                                                  >
                                                    <i className="fa fa-pencil" aria-hidden="true" style={{cursor: "pointer", fontSize: "1rem", color:"white"}}></i>                                                
                                                  </NavLink> */}
                                                  <NavLink
                                                  className="btn btn-primary ml-2 rounded-sm"
                                                  to={`/dashboard/manage/food/add-schemes-edit/${item.id}`} 
                                                  style={{height:"1.7rem",width:"1.7rem"}}                                                                                                                                            
                                                 >
                                                  <div className="d-flex align-items-center justify-content-center">
                                                    <i className="fa fa-pencil edit-icon" aria-hidden="true"></i>
                                                  </div>
                                                 </NavLink>
                                                </td>
                                              </tr>
                                            );
                                          }
                                        )
                                      ),
                                    ],
                                  ]}
                            </tbody>
                          </table>
                        </div>
                      </div>                    
                  </div>
                </div>
              </div>

              {/* pagination loading effect */}
              {newScheme.uploading === true || loading === true
                ? paginationLoading()
                : [
                    // logic === !searched
                    !searchedScheme.searched ? (
                      <div key="fragment4">
                        <div className="t-bg-white mt-1 t-pt-5 t-pb-5">
                          <div className="row align-items-center t-pl-15 t-pr-15">
                            <div className="col-md-7 t-mb-15 mb-md-0">
                              {/* pagination function */}
                              {pagination(schemeList, setPaginatedScheme)}
                            </div>
                            <div className="col-md-5">
                              <ul className="t-list d-flex justify-content-md-end align-items-center">
                                <li className="t-list__item">
                                  <span className="d-inline-block sm-text">
                                    {showingData(schemeList)}
                                  </span>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      // if searched
                      <div className="t-bg-white mt-1 t-pt-5 t-pb-5">
                        <div className="row align-items-center t-pl-15 t-pr-15">
                          <div className="col-md-7 t-mb-15 mb-md-0">
                            <ul className="t-list d-flex">
                              <li className="t-list__item no-pagination-style">
                                <button
                                  className="btn btn-primary btn-sm"
                                  onClick={() =>
                                    setSearchedScheme({
                                      ...searchedScheme,
                                      searched: false,
                                    })
                                  }
                                >
                                  {_t(t("Clear Search"))}
                                </button>
                              </li>
                            </ul>
                          </div>
                          <div className="col-md-5">
                            <ul className="t-list d-flex justify-content-md-end align-items-center">
                              <li className="t-list__item">
                                <span className="d-inline-block sm-text">
                                  {searchedShowingData(
                                    searchedScheme,
                                    deptTagForSearch
                                  )}
                                </span>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    ),
                  ]}
            </div>
            {/* Rightbar contents end*/}
          </div>
        </div>
      </main>
      {/* main body ends */}
    </>
  );
};

export default SchemesCrud;
